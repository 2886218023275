import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export function clearCheckoutSession (toShipping = false): void {
  const itemsToRemove = [
    'shippingDetails',
    'currentTimeSlot',
    'paymentDetails',
    'packagingDetails'
  ]

  if (!toShipping) itemsToRemove.push('personalDetails')

  for (const item of itemsToRemove) {
    sessionStorage.removeItem(item)
  }

  EventBus.$emit('shipping-has-dropped')
}

export function getCheckoutSessionItemByKey (key: string) {
  try {
    const item = sessionStorage.getItem(key)

    if (item) return JSON.parse(item)

    return null
  } catch (e) {
    return null
  }
}

export function setOrderInfoIntoSessionAfterPlaceOrder ({
  order,
  confirmation,
  summary,
  shipping,
  shippingTimeSlots,
  paymentMethods,
  paymentDetails,
  personalDetails,
  tips,
  toCheckout
}): void {
  sessionStorage.setItem('orderConfirmation', JSON.stringify(confirmation))
  sessionStorage.setItem('placedOrder', JSON.stringify(order))
  sessionStorage.setItem('orderSummary', JSON.stringify(summary))
  sessionStorage.setItem('toCheckout', JSON.stringify(toCheckout || {}))
  sessionStorage.setItem('tips', JSON.stringify(tips))
  sessionStorage.setItem('placedOrderShippingInformation', JSON.stringify(shipping))
  sessionStorage.setItem('placedOrderShippingTimeSlots', JSON.stringify(shippingTimeSlots))
  sessionStorage.setItem('placedOrderPaymentMethods', JSON.stringify(paymentMethods))
  sessionStorage.setItem('placedOrderPaymentDetails', JSON.stringify(paymentDetails))
  sessionStorage.setItem('placedOrderPersonalDetails', JSON.stringify(personalDetails))
}
