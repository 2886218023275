import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import { isServer } from '@vue-storefront/core/helpers'
import { SearchQuery } from 'storefront-query-builder'
import cloneDeep from 'lodash/cloneDeep'
import rootStore from '@vue-storefront/core/store'
import RootState from '@vue-storefront/core/types/RootState'
import ProductState from '../types/ProductState'
import { Logger } from '@vue-storefront/core/lib/logger';
import config from 'config'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { ProductService } from '../data-resolver/ProductService'
import {
  registerProductsMapping,
  doPlatformPricesSync,
  setCustomProductOptionsAsync,
  setBundleProductOptionsAsync,
  getProductGallery,
  setRequestCacheTags
} from '@vue-storefront/core/modules/catalog/helpers'
import { getProductConfigurationOptions } from '@vue-storefront/core/modules/catalog/helpers/productOptions'
import { checkParentRedirection } from '@vue-storefront/core/modules/catalog/events'
import modulesConfig from '$modules/config';
import {
  getProductStock,
  prepareCategoryProduct,
  prepareProductStepValues,
  buildFilterProductsQuery } from 'theme/helpers';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'

const actions: ActionTree<ProductState, RootState> = {
  doPlatformPricesSync (context, { products }) {
    return doPlatformPricesSync(products)
  },
  /**
   * This is fix for https://github.com/DivanteLtd/vue-storefront/issues/508
   * TODO: probably it would be better to have "parent_id" for simple products or to just ensure configurable variants are not visible in categories/search
   */
  checkConfigurableParent ({ commit, dispatch, getters }, { product }) {
    if (product.type_id === 'simple') {
      Logger.log('Checking configurable parent')()
      const parent = dispatch('findConfigurableParent', { product: { sku: getters.getCurrentProduct.sku } })
      if (parent) {
        commit(types.PRODUCT_SET_PARENT, parent)
      }
      return parent
    }
  },
  /**
   * Search ElasticSearch catalog of products using simple text query
   * Use bodybuilder to build the query, aggregations etc: http://bodybuilder.js.org/
   * @param {Object} query is the object of searchQuery class
   * @param {Int} start start index
   * @param {Int} size page size
   * @return {Promise}
   */
  async list (context, {
    query,
    start = 0,
    size = 50,
    sort = '',
    prefetchGroupProducts = !isServer,
    excludeFields = null,
    includeFields = null,
    configuration = null,
    populateRequestCacheTags = true,
    updateState = false,
    append = false
  } = {}) {
    Logger.warn('`product/list` deprecated, will be not used from 1.12, use "findProducts" instead')()
    const { items } = await context.dispatch('findProducts', {
      query,
      start,
      size,
      sort,
      excludeFields,
      includeFields,
      configuration,
      options: {
        populateRequestCacheTags,
        prefetchGroupProducts
      }
    })

    if (updateState) {
      Logger.warn('updateState and append are deprecated, will be not used from 1.12')()
      if (append) context.commit(types.PRODUCT_ADD_PAGED_PRODUCTS, { items })
      else context.commit(types.PRODUCT_SET_PAGED_PRODUCTS, { items })
    }

    EventBus.$emit('product-after-list', { query, start, size, sort, entityType: 'product_v2', result: { items } })

    return { items }
  },
  async findProducts (context, {
    query,
    start = 0,
    size = 50,
    sort = '',
    excludeFields = modulesConfig.smallProduct.excludeFields,
    includeFields = modulesConfig.smallProduct.includeFields,
    configuration = null,
    populateRequestCacheTags = false,
    onlyInStock = false,
    onlyMarkdown = false,
    loadDiscountedProducts = false,
    options: {
      populateRequestCacheTags: populateRequestCacheTagsNew = false,
      prefetchGroupProducts = !isServer,
      setProductErrors = false,
      fallbackToDefaultWhenNoAvailable = true,
      assignProductConfiguration = false,
      separateSelectedVariant = false,
      setConfigurableProductOptions = config.cart.setConfigurableProductOptions,
      filterUnavailableVariants = config.products.filterUnavailableVariants,
      skipLoadOptions = false
    } = {}
  } = {}) {
    const { items, ...restResponseData } = await ProductService.getProducts({
      query,
      start,
      size,
      sort,
      excludeFields,
      includeFields,
      configuration,
      onlyInStock,
      onlyMarkdown,
      loadDiscountedProducts,
      options: {
        prefetchGroupProducts,
        fallbackToDefaultWhenNoAvailable,
        setProductErrors,
        setConfigurableProductOptions,
        filterUnavailableVariants,
        assignProductConfiguration,
        separateSelectedVariant
      }
    })

    registerProductsMapping(context, items)

    if (populateRequestCacheTags) {
      Logger.warn('deprecated from 1.13, use "options.populateRequestCacheTags" instead')()
    }

    if (populateRequestCacheTags || populateRequestCacheTagsNew) {
      setRequestCacheTags({ products: items })
    }

    await context.dispatch('tax/calculateTaxes', { products: items }, { root: true })

    const productValues = prepareProductStepValues(items);

    if (!skipLoadOptions && productValues.length !== 0) {
      await context.dispatch('product/loadProductsOptionByValues', { productValues }, { root: true });
    }

    return { ...restResponseData, items }
  },
  async findConfigurableParent (context, { product, configuration }) {
    const searchQuery = new SearchQuery()
    const query = searchQuery.applyFilter({ key: 'configurable_children.sku', value: { 'eq': product.sku } })
    const products = await context.dispatch('findProducts', { query, configuration })
    return products.items && products.items.length > 0 ? products.items[0] : null
  },
  /**
   * Search products by specific field
   * @param {Object} options
   */
  async single (context, {
    options = {},
    setCurrentProduct = false,
    key = 'sku',
    skipCache = false
  } = {}) {
    if (setCurrentProduct) {
      Logger.warn('option `setCurrentProduct` is deprecated, will be not used from 1.13')()
    }
    if (!options[key]) {
      throw new Error('Please provide the search key ' + key + ' for product/single action!')
    }

    const product = await ProductService.getProductByKey({
      options,
      key,
      skipCache
    })

    await context.dispatch('tax/calculateTaxes', { products: [product] }, { root: true })

    if (setCurrentProduct) await context.dispatch('setCurrent', product)

    const pimBrandId = product[config?.attributesCodes?.pimBrandId];
    if (pimBrandId) {
      await context.dispatch('product/loadProductsOptionByValues', { productValues: [pimBrandId], isBrands: true }, { root: true });
    }

    EventBus.$emitFilter('product-after-single', { key, options, product })

    return product
  },
  /**
   * Assign the custom options object to the currentl product
   */
  setCustomOptions (context, { customOptions, product }) {
    if (customOptions) { // TODO: this causes some kind of recurrency error
      context.commit(types.PRODUCT_SET_CURRENT, Object.assign({}, product, { product_option: setCustomProductOptionsAsync(context, { customOptions: customOptions }) }))
    }
  },
  /**
   * Assign the bundle options object to the vurrent product
   */
  setBundleOptions (context, { bundleOptions, product }) {
    if (bundleOptions) { // TODO: this causes some kind of recurrency error
      context.commit(types.PRODUCT_SET_CURRENT, Object.assign({}, product, { product_option: setBundleProductOptionsAsync(context, { bundleOptions: bundleOptions }) }))
    }
  },
  /**
   * Set current product with given variant's properties
   * @param {Object} context
   * @param {Object} productVariant
   */
  setCurrent (context, product) {
    if (product && typeof product === 'object') {
      const { configuration, ...restProduct } = product
      const productUpdated = Object.assign({}, restProduct, prepareCategoryProduct(restProduct))
      if (!config.products.gallery.mergeConfigurableChildren) {
        context.dispatch('setProductGallery', { product: productUpdated })
      }
      const productOptions = getProductConfigurationOptions({ product, attribute: context.rootState.attribute })
      context.commit(types.PRODUCT_SET_CURRENT_OPTIONS, productOptions)
      context.commit(types.PRODUCT_SET_CURRENT_CONFIGURATION, configuration || {})
      context.commit(types.PRODUCT_SET_CURRENT, productUpdated)
      return productUpdated
    } else Logger.debug('Unable to update current product.', 'product')()
  },
  /**
   * Set related products
   */
  related (context, { key = 'related-products', items }) {
    context.commit(types.PRODUCT_SET_RELATED, { key, items })
  },
  /**
   * Load the product data and sets current product
   */
  async loadProduct ({ dispatch }, { slug, route = null, skipCache = false }) {
    Logger.info('Fetching product data asynchronously', 'product', { slug })()
    EventBus.$emit('product-before-load', { store: rootStore, route: route })

    const product = await dispatch('single', {
      options: {
        slug: slug,
        loadDiscountedProducts: true
      },
      key: 'slug',
      skipCache
    })

    product.stock = getProductStock(product)

    setRequestCacheTags({ products: [product] })

    await dispatch('setCurrent', product)

    if (product.status >= 2) {
      throw new Error(`Product query returned empty result product status = ${product.status}`)
    }

    if (product.visibility === 1) { // not visible individually (https://magento.stackexchange.com/questions/171584/magento-2-table-name-for-product-visibility)
      if (config.products.preventConfigurableChildrenDirectAccess) {
        const parentProduct = await dispatch('findConfigurableParent', { product })

        const check = checkParentRedirection(product, parentProduct)

        if (!check) {
          throw new Error(`Product query returned empty result product visibility = ${product.visibility}`)
        }
      } else {
        throw new Error(`Product query returned empty result product visibility = ${product.visibility}`)
      }
    }

    if (config.entities.attribute.loadByAttributeMetadata) {
      await dispatch('attribute/loadProductAttributes', { products: [product], merge: true }, { root: true })
    } else {
      await dispatch('loadProductAttributes', { product })
    }

    const syncPromises = []
    const gallerySetup = dispatch('setProductGallery', { product })
    if (isServer) {
      syncPromises.push(gallerySetup)
    }
    await Promise.all(syncPromises)
    await EventBus.$emitFilter('product-after-load', { store: rootStore, route: route })
    return product
  },
  /**
   * Add custom option validator for product custom options
   */
  addCustomOptionValidator (context, { validationRule, validatorFunction }) {
    context.commit(types.PRODUCT_SET_CUSTOM_OPTION_VALIDATOR, { validationRule, validatorFunction })
  },

  /**
   * Set product gallery depending on product type
   */

  setProductGallery (context, { product }) {
    const productGallery = getProductGallery(product)
    context.commit(types.PRODUCT_SET_GALLERY, productGallery)
  },
  async getProductVariant (context, { product, configuration } = {}) {
    let searchQuery = new SearchQuery()
    searchQuery = searchQuery.applyFilter({ key: 'sku', value: { 'eq': product.parentSku } })
    if (!product.parentSku) {
      throw new Error('Product doesn\'t have parentSku, please check if this is configurable product')
    }
    const { items: [newProductVariant] } = await context.dispatch('findProducts', {
      query: searchQuery,
      size: 1,
      configuration,
      options: {
        fallbackToDefaultWhenNoAvailable: false,
        setProductErrors: true,
        separateSelectedVariant: true
      }
    })
    const { selectedVariant = {}, options, product_option } = newProductVariant

    return { ...selectedVariant, options, product_option }
  },
  async categoryProductsInStock ({ rootGetters }, {
    query,
    sort = ''
  } = {}) {
    try {
      if (isServer) return true

      const isNewPost = rootGetters['shipping-module/isCurrentNewPost']

      const { items } = await ProductService.getProducts({
        query,
        start: 0,
        size: 1,
        sort,
        excludeFields: [],
        includeFields: ['forNewPost'],
        onlyInStock: true
      })

      const item = items?.[0] || null

      if (isNewPost) return !!(item?.forNewPost && item?.stock.is_in_stock)

      return !!(item?.stock.is_in_stock)
    } catch (e) {
      return false
    }
  },
  async loadPackages ({ dispatch }) {
    const query = new SearchQuery()
    query.applyFilter({ key: 'type_id', value: 'package' })

    return dispatch('findProducts', {
      query: query,
      size: config.entities.product.carouselSize
    })
  },
  async findRelatedProducts (context, { query, size, onlyInStock = false }) {
    const { items, ...restResponseData } = await ProductService.getProducts({
      query,
      size,
      onlyInStock,
      includeFields: modulesConfig.smallProductWithCats.includeFields,
      excludeFields: modulesConfig.smallProductWithCats.excludeFields
    });
    registerProductsMapping(context, items);
    await context.dispatch('tax/calculateTaxes', { products: items }, { root: true });

    return { ...restResponseData, items };
  },
  async getProductsBySku ({ dispatch }, {
    skus = [],
    prefetchGroupProducts = false,
    updateState = false,
    onlyInStock = false
  }) {
    if (!skus || !skus.length) return []

    const query = new SearchQuery().applyFilter({ key: 'sku', value: { 'in': skus } })

    const response = await dispatch('findProducts', {
      query,
      size: skus.length,
      prefetchGroupProducts,
      updateState,
      onlyInStock
    });

    return response?.items || []
  },
  async getSimpleProductsBySku (ctx, {
    skus = [],
    includeFields = modulesConfig.smallProductWithCats.includeFields,
    excludeFields = modulesConfig.smallProductWithCats.excludeFields,
    loadDiscountedProducts = false,
    size = 4000,
    onlyMarkdown = false
  }) {
    if (!skus || !skus.length) return []

    const query = new SearchQuery().applyFilter({ key: 'sku', value: { 'in': skus } })

    const { items } = await ProductService.getProducts({
      query,
      size,
      includeFields,
      excludeFields,
      loadDiscountedProducts,
      onlyMarkdown
    });

    return items || []
  },
  async getSimpleProductsByID (ctx, {
    ids = [],
    includeFields = modulesConfig.smallProductWithCats.includeFields,
    excludeFields = modulesConfig.smallProductWithCats.excludeFields
  }) {
    if (!ids || !ids.length) return []

    const query = new SearchQuery().applyFilter({ key: 'id', value: { 'in': ids } })

    const { items } = await ProductService.getProducts({
      query,
      size: ids.length,
      includeFields,
      excludeFields
    });

    return items || []
  },
  async getProductsByID ({ dispatch }, {
    ids = [],
    prefetchGroupProducts = false,
    updateState = false,
    onlyInStock = false
  }) {
    if (!ids || !ids.length) return []

    const query = new SearchQuery().applyFilter({ key: 'id', value: { 'in': ids } })

    const response = await dispatch('findProducts', {
      query,
      size: ids.length,
      prefetchGroupProducts,
      updateState,
      onlyInStock,
      includeFields: modulesConfig.smallProduct.includeFields,
      excludeFields: modulesConfig.smallProduct.excludeFields,
      loadDiscountedProducts: true
    });

    return response?.items || []
  },
  async loadDiscountProducts ({ dispatch, commit }, { ids }) {
    if (!ids || !ids.length) {
      commit(types.SET_DISCOUNT_PRODUCTS, [])
      return
    }
    const products = await dispatch('getProductsByID', { ids: ids, prefetchGroupProducts: false, updateState: true, onlyInStock: true })
    const filteredProducts = products.filter(product => product.stock.is_in_stock === true)
    commit(types.SET_DISCOUNT_PRODUCTS, filteredProducts)
  },
  async loadTopProductsByCategoryId ({ dispatch, commit }, { categoryId, size = 12, skipProducts = [] }) {
    const sort = { field: 'sqpp_score', options: { order: 'desc' } }
    const response = await dispatch('loadProductsByCategoryId', { categoryId, size, sort, skipProducts })
    commit(types.SET_TOP_PRODUCTS, response.items)
  },
  async loadNewProductsByCategoryId ({ dispatch, commit }, { categoryId, size = 12, skipProducts = [] }) {
    const sort = { field: 'created_at', options: { order: 'desc' } }
    const response = await dispatch('loadProductsByCategoryId', { categoryId, size, sort, skipProducts })
    commit(types.SET_NEW_PRODUCTS, response.items)
  },
  async loadRecommendedProductsByCategoryId ({ dispatch, commit }, { categoryId, size = 12, skipProducts = [] }) {
    const sort = { field: 'sqpp_score', options: { order: 'asc' } }
    const response = await dispatch('loadProductsByCategoryId', { categoryId, size, sort, skipProducts })
    commit(types.SET_RECOMMENDED_PRODUCTS, response.items)
  },
  async loadProductsByCategoryId ({ dispatch }, { categoryId, size = 12, sort, skipProducts = [] }) {
    const query = new SearchQuery()
    query.applySort(sort)
    query.applyFilter({ key: 'category_ids', value: { 'in': [categoryId] } })

    if (skipProducts.length) {
      query.applyFilter({ key: 'id', value: { 'nin': skipProducts } })
    }

    return dispatch('findProducts', { query, size, onlyInStock: true })
  },
  async loadCharacteristicAttributes ({ commit }, { product }) {
    const attributeCodes = Object.keys(product?.all_attributes_list || {})

    const topAttributeCodes = Object.keys(product?.top_attributes_list || {})

    if (!attributeCodes.length && !topAttributeCodes.length) {
      return
    }

    const query = new SearchQuery();
    query
      .applyFilter({ key: 'is_visible', value: { 'eq': true } })
      .applyFilter({
        key: 'attribute_code',
        value: { 'in': [...new Set([...attributeCodes, ...topAttributeCodes])] }
      })

    const { items } = await quickSearchByQuery({
      query,
      size: attributeCodes.length,
      entityType: 'attribute',
      includeFields: config.entities.product.includeFieldsForCharacteristicAttributes
    })

    commit(types.SET_CHARACTERISTIC_ATTRIBUTE_CODES, attributeCodes)
    commit(types.SET_TOP_CHARACTERISTIC_ATTRIBUTE_CODES, topAttributeCodes)
    commit(types.SET_CHARACTERISTIC_ATTRIBUTES, items)
  },
  async loadProductsOptionByValues ({ commit, state }, payload): Promise<void> {
    if (!payload.productValues || !payload.productValues?.length) {
      return;
    }

    const existedIds = Object.keys(state.productAttributeLabelMap).map((key) => Number(key));
    const toRequest = payload.productValues
      .filter((value) => !existedIds.includes(value))

    if (!toRequest.length) {
      return
    }

    const query = new SearchQuery();
    query.applyFilter({
      key: 'options.value',
      value: { 'in': toRequest }
    });

    const { items } = await quickSearchByQuery({
      query,
      size: toRequest.length,
      entityType: 'attribute',
      includeFields: ['options']
    });

    if (items.length === 0) {
      return;
    }

    let attributeOptions = []

    if (payload?.isBrands) {
      attributeOptions = items.map(item => {
        const option = item.options?.filter((elem) => {
          return elem.value === payload.productValues[0].toString()
        })
        return { value: option?.[0].value, label: option?.[0].label }
      });
    } else {
      items.forEach((item) => {
        const options = item.options.filter((elem) => toRequest.includes(Number(elem.value)));

        if (options.length) {
          attributeOptions.push(...options.map((option) => ({ value: option.value, label: option.label })));
        }
      });
    }

    commit(types.SET_PRODUCT_ATTRIBUTE_OPTIONS, attributeOptions);
  },

  async loadVarusPerfectProducts ({ dispatch, commit }): Promise<void> {
    const varusPerfectCategoryId = parseInt(await dispatch('config-varus/get', { path: 'categories_map_perfect_category_id' }, { root: true }));
    const filters = { id: varusPerfectCategoryId }
    const fullVarusPerfectCategoryInfo = await dispatch('category-next/loadCategory', { filters }, { root: true });

    const query = buildFilterProductsQuery(fullVarusPerfectCategoryInfo)

    const { items } = await dispatch('product/findProducts', {
      query,
      onlyInStock: true,
      options: {
        populateRequestCacheTags: true,
        prefetchGroupProducts: false,
        setProductErrors: false,
        fallbackToDefaultWhenNoAvailable: true,
        assignProductConfiguration: false,
        separateSelectedVariant: false
      }
    }, { root: true });

    commit(types.SET_VARUS_PERFECT_PRODUCTS, items);
  },
  async loadInStockProductVariants ({ dispatch, commit, getters }): Promise<void> {
    const uniqueIds = [...new Set(getters.getProductVariantsIds)]

    if (!uniqueIds.length) return

    const query = new SearchQuery()

    query
      .applyFilter({ key: 'id', value: { 'in': uniqueIds } })
      .applyFilter({ key: 'visibility', value: { 'in': [2, 4] } })
      .applyFilter({ key: 'status', value: { 'in': [1] } })

    const { items } = await dispatch('product/findProducts', {
      query,
      includeFields: ['sku', 'id', 'name'],
      onlyInStock: true
    }, { root: true });

    commit(types.SET_IN_STOCK_PRODUCT_VARIANTS, items || []);
  },
  /**
   * Check stock for current product
   *
   * @param commit
   * @param rootGetters
   */
  async loadStockForCurrentProduct ({ rootGetters, dispatch, commit }) {
    try {
      const [item] = await dispatch('product/getSimpleProductsByID', {
        ids: [rootGetters['product/getCurrentProduct'].id],
        includeFields: ['sqpp', 'stock']
      }, { root: true });

      if (!item?.sqpp || !item?.stock) return

      commit(types.UPDATE_CURRENT_PRODUCT_STOCK, { sqpp: item?.sqpp, stock: item?.stock });
    } catch (e) {
      Logger.debug(e, 'cart')()
    }
  },
  async loadProductBreadcrumbs ({ dispatch, rootGetters }, { product } = {}) {
    if (product && product.category_ids) {
      const currentCategory = rootGetters['category-next/getCurrentCategory']
      let breadcrumbCategory
      let productCategory
      const categoryFilters = Object.assign({ 'id': [...product.category_ids] }, cloneDeep(config.entities.category.breadcrumbFilterFields))
      const categories = await dispatch('category-next/loadCategories', { filters: categoryFilters, reloadAll: Object.keys(config.entities.category.breadcrumbFilterFields).length > 0 }, { root: true })
      if (
        (currentCategory && currentCategory.id) && // current category exist
        (config.entities.category.categoriesRootCategoryId !== currentCategory.id) && // is not highest category (All) - if we open product from different page then category page
        (categories.findIndex(category => category.id === currentCategory.id) >= 0) // can be found in fetched categories
      ) {
        breadcrumbCategory = currentCategory // use current category if set and included in the filtered list
      } else {
        productCategory = product.category.filter(elem => elem.is_product_category && elem.level > 2).sort((a, b) => (a.level > b.level) ? -1 : 1)
        if (productCategory.length > 0) {
          breadcrumbCategory = await dispatch(
            'category-next/loadCategory',
            { filters: { 'id': productCategory[0].category_id } },
            { root: true }
          )
        } else {
          breadcrumbCategory = categories.sort((a, b) => (a.level > b.level) ? -1 : 1)[0] // sort starting by deepest level
        }
      }
      await dispatch('category-extension/loadCategoryBreadcrumbs', { category: breadcrumbCategory, currentRouteName: product.name }, { root: true })
    }
  }
}

export default actions
