import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import SearchState from '../types/SearchResultState'
import * as types from './mutation-types'
import { prepareCategoryProduct } from 'theme/helpers'
import config from 'config'
import hash from 'object-hash';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import Fetch from '@vue-storefront/core/lib/isomorphic-fetch'
import { searchOptions } from 'theme/mixins/Multisearch';
import { jsonParseResponse } from '@vue-storefront/core/helpers/jsonParse';
import modulesConfig from '$modules/config'

const actions: ActionTree<SearchState, RootState> = {
  async setCategory ({ commit }, category) {
    commit(types.SET_CURRENT_CATEGORY, category)
  },

  async setSearchMetaTag ({ commit, dispatch }) {
    const metaTitleTag = await dispatch('config-varus/get', { path: 'search_meta_templates_search_meta_title' }, { root: true });
    const metaDescriptionTag = await dispatch('config-varus/get', { path: 'search_meta_templates_search_meta_description' }, { root: true });
    const metaTagsResult = await Promise.all([metaTitleTag, metaDescriptionTag]).then((res) => res);

    commit(types.SET_META_TAGS, metaTagsResult)
  },
  async multisearch ({
    rootState,
    rootGetters
  }, {
    searchText = '',
    autocomplete = false,
    limit = 40,
    offset = 0,
    categories = null,
    category = null,
    fields = null,
    sort = null,
    filters = null
  }) {
    const userId = rootState.user.current?.id
    const cartToken = rootGetters['cart/getCartToken']
    const currentShipping = rootGetters['shipping-module/getCurrent']

    const uid = userId || cartToken || 'guest'
    const lang = currentStoreView().storeCode === 'ua' ? 'uk' : 'ru'

    const location = currentShipping?.shop?.tms_id || rootGetters['shipping-module/getDefaultShop']?.tms_id

    const prepareQuery = [
      `id=${config.multisearch.siteId}`,
      `query=${encodeURIComponent(searchText)}`,
      `uid=${uid}`,
      `lang=${lang}`,
      `tags=${window.innerWidth <= 768 ? 2 : 1}`
    ]

    if (location) prepareQuery.push(`location=${location}`)
    if (limit) prepareQuery.push(`limit=${limit}`)
    if (offset) prepareQuery.push(`offset=${offset}`)
    if (categories || categories === 0) prepareQuery.push(`categories=${categories}`)
    if (category) prepareQuery.push(`t=${category}`)
    if (fields) prepareQuery.push(`fields=${fields}`)
    if (autocomplete) prepareQuery.push(`autocomplete=true`)
    if (sort) prepareQuery.push(`sort=${sort}`)
    if (filters) prepareQuery.push(`filters=${encodeURIComponent(JSON.stringify(filters))}`)

    const query = prepareQuery.join('&')

    const url = `${config.multisearch.api}?${query}`

    return Fetch(url)
      .then(r => jsonParseResponse(r))
  },
  async multisearchPrepareItems ({ dispatch }, { productIDs, categoriesIDs, onlyInStock }) {
    const promiseTryCatch = async (callback) => {
      try {
        return await callback()
      } catch (e) {
        return []
      }
    }

    const productPayload = {
      ids: productIDs
    }

    if (onlyInStock) {
      productPayload['onlyInStock'] = true
    }

    const toPromise = [
      promiseTryCatch(
        () =>
          dispatch('product/getProductsByID', productPayload, { root: true })
      ),
      promiseTryCatch(
        () =>
          dispatch('category-next/findCategories', {
            filters: { id: categoriesIDs },
            size: categoriesIDs.length,
            includeFields: modulesConfig.categorySearchSmall.includeFields,
            excludeFields: modulesConfig.categorySearchSmall.excludeFields
          }, { root: true })
      )
    ]

    const [products, categories] = await Promise.all(toPromise)

    const productsOrder = productIDs.map(id => products.find(p => +p.id === +id)).filter(p => !!p)
    const categoriesOrder = categoriesIDs.map(id => categories.find(c => +c.id === +id)).filter(c => !!c)

    return { products: productsOrder, categories: categoriesOrder }
  },
  async multisearchAutocompleteClear ({ commit }) {
    commit(types.SET_LOADING_AUTOCOMPLETE, false)

    commit(types.SET_LAST_AUTOCOMPLETE_HASH, '')

    commit(types.SET_AUTOCOMPLETE_RESULT, {
      query: '',
      total: 0,
      corrected: null,
      results: {
        suggest: [],
        categories: [],
        brands: [],
        items: []
      }
    })
  },
  async multisearchCorrectionClear ({ commit, state, rootGetters }) {
    const searchText = state.autocomplete?.corrected?.phrase

    if (!searchText) return

    const shopId = rootGetters['shipping/getShippingDetails'].shopId
    const queryHash = hash({ searchText, shopId })

    commit(types.SET_LAST_AUTOCOMPLETE_HASH, queryHash);

    commit(types.SET_AUTOCOMPLETE_RESULT, {
      ...state.autocomplete,
      corrected: null
    })
  },
  async multisearchAutocomplete ({ commit, dispatch, rootGetters, state }, {
    searchText
  } = {
    searchText: ''
  }) {
    try {
      if (searchText.length === 0) {
        return dispatch('multisearchAutocompleteClear')
      }

      const shopId = rootGetters['shipping/getShippingDetails'].shopId
      const queryHash = hash({ searchText, shopId })

      if (queryHash === state.autocompleteHash) return;
      commit(types.SET_LAST_AUTOCOMPLETE_HASH, queryHash);

      commit(types.SET_LOADING_AUTOCOMPLETE, true)

      const result = await dispatch('multisearch', {
        searchText,
        autocomplete: true
      })

      const productIDs = (result?.results?.items || []).map(i => i.id)
      const categoriesIDs = (result?.results?.categories || []).map(i => i.id)

      const {
        products,
        categories
      } = await dispatch('multisearchPrepareItems', {
        productIDs,
        categoriesIDs,
        onlyInStock: true
      })

      const items = products.map(prepareCategoryProduct)

      commit(types.SET_AUTOCOMPLETE_RESULT, {
        query: result?.query || '',
        total: result?.total || 0,
        corrected: result?.corrected || null,
        results: {
          suggest: result?.results?.suggest || [],
          categories,
          brands: result?.results?.brands || [],
          items
        }
      })
    } catch (e) {
      dispatch('multisearchAutocompleteClear')
    } finally {
      commit(types.SET_LOADING_AUTOCOMPLETE, false)
    }
  },

  prepareFilters ({ commit }, payload) {
    try {
      const availableFilters = (payload || []).reduce((a, c) => {
        if (c.name === 'price') {
          a.price = {
            'type': 'price',
            'from': c.range.from,
            'to': c.range.to,
            'min': c.range.min,
            'max': c.range.max
          }

          return a
        }

        a[c.name] = Object.keys(c.values).sort()?.map(i => ({
          id: i,
          label: i,
          type: c.name
          // count: c.values[i]
        }))

        return a
      }, {})

      const filterLabels = payload.reduce((a, c) => {
        a[c.name] = c.title || c.name

        return a
      }, {})

      commit(types.SET_AVAILABLE_FILTERS, availableFilters)
      commit(types.SET_FILTER_LABELS, filterLabels)
    } catch (e) {
      commit(types.SET_AVAILABLE_FILTERS, {})
      commit(types.SET_FILTER_LABELS, {})
    }
  },

  setSearchFilter ({ state, commit }, {
    filter,
    active
  }) {
    if (!filter || !filter?.type) return

    const filterToSave = state.activeFilters

    filterToSave[filter?.type] = (filterToSave[filter?.type] || [])
      .filter(i => i.id !== filter?.id)

    if (!active) {
      if (!filterToSave[filter?.type]) {
        filterToSave[filter?.type] = []
      }

      filterToSave[filter?.type].push(filter)
    }

    if (!filterToSave[filter?.type].length) {
      delete filterToSave[filter?.type]
    }

    commit(types.SET_ACTIVE_FILTER, filterToSave)
  },

  resetFilterSearch ({ commit }) {
    commit(types.SET_ACTIVE_FILTER, {})
  },

  async loadSearch ({ commit, rootGetters, dispatch, state }, payload) {
    let keepLoader = true

    try {
      const {
        sort = searchOptions[0].id,
        search,
        page = 1,
        size = 40,
        isLoadMore = false,
        category = null,
        filters = {}
      } = payload

      const offset = ((page || 1) - 1) * size
      const shopId = rootGetters['shipping/getShippingDetails'].shopId

      const filtersToMultisearch = Object.keys(filters).reduce((a, c) => {
        if (c === 'price') {
          a[c] = {
            from: filters[c]?.[0]?.from,
            to: filters[c]?.[0]?.to
          }

          return a
        }

        a[c] = filters[c].map(i => i.id)

        return a
      }, {})

      const queryHash = hash({ offset, size, isLoadMore, category, search, shopId, sort, fm: filtersToMultisearch })

      if (queryHash === state.lastSearchQueryHash) return;
      keepLoader = false

      commit(types.SET_LAST_SEARCH_QUERY_HASH, queryHash);

      commit(types.SET_LOADING_RESULT_PAGE, true)

      const toMultisearch = {
        searchText: search,
        offset,
        limit: size,
        categories: 0,
        fields: 'id',
        filters: filtersToMultisearch
      }

      if (category) {
        toMultisearch['category'] = category
      }

      if (sort) {
        toMultisearch['sort'] = sort
      }

      const result = await dispatch('multisearch', toMultisearch)
      const productIDs = result.results.items.map(i => i.id)
      const categoriesIDs = result.results.categories.map(i => i.id)

      const {
        products,
        categories
      } = await dispatch('multisearchPrepareItems', {
        productIDs,
        categoriesIDs,
        onlyInStock: false
      })

      const total = result.results.count || result.total

      commit(types.SEARCH_SET_RESULT_STATS, {
        totalPages: Math.ceil(total / size),
        totalProducts: total
      });

      const categoriesWithCount = categories.map(i => {
        const category = result.results.categories.find(c => +c.id === +i.id)

        return {
          ...i,
          countProduct: category.count
        }
      })

      dispatch('prepareFilters', result?.results?.filters || [])

      commit(types.SET_SEARCH_TOTAL_PRODUCTS, result.total)

      if (isLoadMore) {
        commit(types.SEARCH_ADD_RESULT_PRODUCTS, { products });
      } else {
        commit(types.SEARCH_SET_RESULT_PRODUCTS, { products });
      }

      commit(types.SEARCH_SET_RESULT_CATEGORIES, categoriesWithCount)
    } finally {
      !keepLoader && commit(types.SET_LOADING_RESULT_PAGE, false)
      !keepLoader && commit(types.SET_LOADED_RESULT_PAGE, true)

      dispatch('ui/setPagingLoading', false, { root: true })
    }
  }
}

export default actions
