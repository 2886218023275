<template>
  <div class="account-page-wrap">
    <div id="my-account">
      <lazy-hydrate when-idle>
        <SfOContentPages
          v-if="isLoggedIn"
          :active="activePage"
          :title="title"
          class="my-account"
          @click:change="changeActivePage"
          :key="updateContentPagesKey"
        >
          <template #menu-item="{ updatePage, page, active }">
            <div
              data-transaction-name="Account - Change Page"
              @click="changeActivePage(page.slug)"
            >
              <MAccountMenuItem
                :page="page"
                :user="userPage"
                :active="active"
                @logout="logout"
              />
            </div>
          </template>
          <SfOContentPage
            v-for="(page, index) in pages"
            :key="index"
            :title="page.title"
            :slug="page.slug"
            :icon="page.icon"
            :quantity="getQuantity(page)"
            :type="page.type"
          >
            <div v-if="isTablet" class="tablet-menu">
              <div :class="isTabletMenuOpen ? 'tablet-menu__overlay' : 'tablet-menu__closed'">
                <div
                  class="tablet-menu__header"
                  data-transaction-name="Account - Menu Close"
                  @click="isTabletMenuOpen=!isTabletMenuOpen"
                >
                  <div>{{ page.title }}</div>
                  <div>
                    <SfIcon :icon="isTabletMenuOpen ? 'chevron_up' : 'chevron_down'" size="xs" />
                  </div>
                </div>
                <div v-if="isTabletMenuOpen" class="tablet-menu__dropdown">
                  <div class="tablet-menu__dropdown-wrap">
                    <div
                      v-for="item in pages"
                      :key="item.title"
                      data-transaction-name="Account - Change Page"
                      @click="changeActivePage(item.slug)"
                    >
                      <MAccountMenuItem
                        :page="item"
                        :user="userPage"
                        :active="activePage"
                        class="tablet-menu__item"
                        @logout="logout"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <component v-if="page.component" :is="page.component" />
          </SfOContentPage>
        </SfOContentPages>
      </lazy-hydrate>
    </div>
  </div>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import MyAccount from 'theme/mixins/MyAccount';
import MAccountMenuItem from 'theme/components/molecules/m-account-menu-item';
import OMyAccountProfile from 'theme/components/organisms/o-my-account-profile';
import OMyAccountPaymentCard from 'theme/components/organisms/o-my-account-payment-card';
import OMyAccountOrders from 'theme/components/organisms/o-my-account-orders';
import OMyAccountReviews from 'theme/components/organisms/o-my-account-reviews';
import OMyAccountWishlist from 'theme/components/organisms/o-my-account-wishlist';
import OMyAccountViewed from 'theme/components/organisms/o-my-account-viewed';
import OMyAccountBonusCard from 'theme/components/organisms/o-my-account-bonus-card';
import { SfIcon } from '@storefront-ui/vue';
import SfOContentPages from 'src/themes/varus/components/storefront-override/SfOContentPages';
import { eSputnikEvent } from 'theme/helpers/es';
import { getFormattedPhone } from 'theme/helpers/text';
import { mapActions, mapGetters, mapState } from 'vuex';
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore';
import LazyHydrate from 'vue-lazy-hydration';
import { metaPages } from '../meta/pages';

export default {
  components: {
    SfOContentPages,
    MAccountMenuItem,
    SfIcon,
    LazyHydrate
  },
  mixins: [DeviceType, MyAccount],
  data () {
    return {
      isTabletMenuOpen: false,
      activePage: 'profile',
      pages: [
        {
          name: '',
          title: this.$t('Profile'),
          slug: 'profile',
          icon: '/assets/account/avatar.svg',
          phone: '',
          type: 'user',
          component: OMyAccountProfile
        },
        {
          title: this.$t('My purchases'),
          slug: 'orders',
          icon: '/assets/account/cart.svg',
          type: 'item',
          component: OMyAccountOrders,
          quantity: 0
        },
        {
          title: this.$t('My lists'),
          icon: '/assets/account/heart.svg',
          slug: 'wishlist',
          type: 'item',
          component: OMyAccountWishlist,
          quantity: 0
        },
        {
          title: this.$t('Recently viewed products'),
          slug: 'viewed',
          icon: '/assets/account/view.svg',
          type: 'item',
          component: OMyAccountViewed,
          quantity: 0
        },
        {
          title: this.$t('Bonus card'),
          slug: 'bonus-card',
          type: 'bonus-card',
          component: OMyAccountBonusCard
        },
        {
          title: this.$t('Testimonials'),
          slug: 'reviews',
          icon: '/assets/account/testimonial.svg',
          type: 'item',
          component: OMyAccountReviews,
          quantity: 0
        },
        {
          title: this.$t('Payment cards'),
          slug: 'payment-cards',
          icon: '/assets/account/card.svg',
          type: 'item',
          component: OMyAccountPaymentCard
        },
        {
          title: this.$t('Logout cabinet'),
          slug: 'logout',
          type: 'logout'
        }
      ],
      userPage: {},
      updateContentPagesKey: 0
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    }),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      getUserAvatar: 'userInfo/getUserAvatar',
      wishlistItemsCount: 'wishlist/getWishlistItemsCount',
      getOrdersHistoryTotalOnline: 'user/getOrdersHistoryTotalOnline',
      viewedTotal: 'recently-viewed/viewedTotal',
      getReviewsProductByCustomer: 'product-review/getReviewsProductByCustomer'
    }),
    formattedPhone () {
      const phone = this.currentUser?.phone || ''
      return getFormattedPhone(phone)
    },
    avatarSrc () {
      return this.getUserAvatar ? this.getUserAvatar : '/assets/account/avatar.svg'
    },
    isRouteWrong () {
      const pageSlugList = this.pages.map(e => e.slug) || []
      return !pageSlugList.includes(this.$route.params.slug)
    },
    title () {
      return this.pages.find(e => e.slug === this.activePage)?.title || ''
    }
  },
  watch: {
    currentUser: {
      deep: true,
      handler () {
        this.setPagesValues()
      }
    },
    avatarSrc () {
      this.setPagesValues()
    },
    $route: {
      deep: true,
      handler () {
        this.setActivePage()
      }
    },
    isLoggedIn () {
      this.updateContentPages()
    },
    viewedTotal () {
      this.updateContentPages()
    },
    getOrdersHistoryTotalOnline () {
      this.updateContentPages()
    },
    wishlistItemsCount () {
      this.updateContentPages()
    }
  },
  async asyncData ({ store }) {
    await Promise.all([
      store.dispatch('ui/loadCatalogMenu'),
      store.dispatch('config-varus/get', { path: ['header_logo_src'] }),
      store.dispatch('promoted/updatePreHeaderBanners')
    ])
  },
  async mounted () {
    await this.startSession()

    if (!this.isLoggedIn) {
      localStorage.setItem('redirect', this.$route.path)
      await this.setNeedToOpenAuth(true)
      await this.$router.push(localizedRoute('/', currentStoreView().storeCode))
      return
    }

    this.setPagesValues()
    this.setActivePage()
    void this.loadInformation()
    eSputnikEvent('CustomerData', this.currentUser);
    this.$store.dispatch('product-review/loadReviewByCustomer', this.currentUser.id)

    if (this.activePage) {
      this.$store.dispatch('ui/isPersonalAccountMenuOpen', true)
    }
  },
  methods: {
    ...mapActions({
      startSession: 'user/startSession',
      setNeedToOpenAuth: 'ui/setNeedToOpenAuth',
      getAvatar: 'userInfo/getAvatar',
      loadBonusCard: 'bonusCard/loadBonusCard'
    }),
    loadInformation () {
      return Promise.allSettled([
        this.getAvatar(),
        this.loadBonusCard()
      ])
    },
    getQuantity (page) {
      let quantity

      switch (page.slug) {
        case 'orders':
          quantity = this.getOrdersHistoryTotalOnline || 0
          break
        default: quantity = 0
      }

      return quantity
    },
    changeActivePage (slug = '') {
      if (slug === 'logout') {
        this.logout();
        return;
      }
      this.$store.dispatch('ui/isPersonalAccountMenuOpen', true)
      if (slug === '') {
        this.$store.dispatch('ui/isPersonalAccountMenuOpen', false)
      }
      const urlSlug = slug ? '/' + slug : ''
      this.$router.push(this.localizedRoute('/my-account' + urlSlug));
      this.isTabletMenuOpen = false;
    },
    async logout () {
      await this.$store.dispatch('user/logout', {});
      this.$router.push(this.localizedRoute('/'));
    },
    setActivePage () {
      if (!this.$route.params.slug) {
        this.setDefaultPage()
        return
      }

      if (this.isRouteWrong) {
        this.$router.push(this.localizedRoute('/error'))
        return;
      }

      this.activePage = this.$route.params.slug
    },
    setPagesValues () {
      const profilePage = this.pages.find(e => e.slug === 'profile') || this.pages[0]
      profilePage.name = this.currentUser?.firstname || ''
      profilePage.phone = this.formattedPhone || ''
      profilePage.icon = this.avatarSrc || ''
      this.userPage = profilePage
    },
    setDefaultPage () {
      if (this.isMobile) {
        // show menu
        this.activePage = ''
        this.$store.dispatch('ui/isPersonalAccountMenuOpen', false)
      } else {
        // redirect to default page
        this.$router.push(this.localizedRoute('/my-account/profile'))
      }
    },
    updateContentPages () {
      if (this.updateContentPagesKey > 10) this.updateContentPagesKey = 0
      this.updateContentPagesKey++
    }
  },
  destroyed () {
    this.$store.dispatch('ui/isPersonalAccountMenuOpen', false)
  },
  metaInfo: metaPages
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";

.account-page-wrap {
  @media (min-width: $tablet-min) {
    background-color: var(--gray-light);
  }
}

#my-account {
  box-sizing: border-box;

  @include for-desktop {
    max-width: 1210px;
    padding: var(--spacer-60) 0 var(--spacer-60) var(--spacer-16);
    margin: 0 auto;
  }

  @media (max-width: $mobile-max) {
    margin-bottom: var(--spacer-55);
  }

  @include for-tablet {
    max-width: 1366px;
    padding-bottom: var(--spacer-60);
    margin: 0 auto;
  }
}

.my-account {
  ::v-deep {
    .sf-bar {
      @media (max-width: $mobile-max) {
        background-color: var(--white);
        border-bottom: 2px solid var(--light-gray);
        justify-content: start;
        padding: 0 var(--spacer-15);
      }

      @include for-tablet {
        background-color: var(--white);
      }

      &__title {
        font-size: var(--font-size-20);
        font-weight: var(--font-medium);
        text-align: left;
      }

      .sf-button {
        @media (max-width: $mobile-max) {
          margin-right: var(--spacer-15)
        }
      }
    }
  }

  ::v-deep {
    .sf-content-pages__content,
    .sf-content-pages__sidebar {
      height: min-content;
    }

    .sf-content-pages__sidebar {
      background: var(--white);

      @media (max-width: $mobile-max) {
        padding: 0;
      }

      @include for-desktop {
        padding: 0 var(--spacer-15);
      }
    }

    .sf-content-pages__content{
      padding: 0;

      @include for-tablet {
        padding: 0 var(--spacer-17);
      }

      @include for-desktop {
        margin: 0 var(--spacer-20);
      }

      .account-head {
        .sf-heading__title {
          font-family: var(--font-family-inter);
          font-weight: var(--font-medium);
          font-size: var(--font-size-24);
          color: var(--black);
        }

        @include for-mobile {
          padding-bottom: 0;
          display: none;
        }
      }
    }

    .sf-content-pages__list-item {
      @media (max-width: $mobile-max) {
        --list-item-border-width: 0 0 2px 0;
      }
    }

    .sf-content-pages__list-item:last-of-type {
      @media (max-width: $mobile-max) {
        --list-item-border-width: 0;
      }
    }
  }
}

.tablet-menu {
  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: grey;
    z-index: 1;
  }

  &__header {
    background-color: var(--white);
    padding: var(--spacer-15) var(--spacer-30);
    margin: var(--spacer-30) 0 0;
    border-bottom: 2px solid var(--light-gray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: var(--font-family-inter);
    font-size: var(--font-size-24);
    font-weight: var(--font-medium);

    .sf-icon {
      color: var(--black);
      width: var(--spacer-16);
      height: var(--spacer-16);
    }
  }

  &__closed {
    margin-bottom: 10px;
  }

  &__dropdown {
    z-index: 1;
    position: absolute;
    left: var(--spacer-17);
    right: var(--spacer-17);
    padding: 0 var(--spacer-45) var(--spacer-20);
    background-color: var(--white);

    &-wrap {
      width: 230px;
    }
  }

  &__item {
    margin-top: var(--spacer-xs);
    margin-bottom: var(--spacer-xs);
  }
}
.tablet-menu {
  &__overlay {
    @include for-tablet {
      margin-left: calc(-1 * var(--spacer-17));
      margin-right: calc(-1 * var(--spacer-17));
    }

    .tablet-menu__header {
      @include for-tablet {
        margin-left: var(--spacer-17);
        margin-right: var(--spacer-17);
      }
    }
  }
}
</style>
