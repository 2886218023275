<template>
  <div
    class="o-order-step o-personal-details"
    :class="{'o-order-step--filled': isFilled && !edited}"
  >
    <SfHeading
      :title="$t('Personal Information')"
      :level="2"
      class="o-order-step__title o-order-step__title--personal-details"
    >
      <template v-if="currentUser && isFilled && !edited" #subtitle>
        <div
            class="o-order-step-content__row o-order-step-content__row--align-left o-order-step-content__row--edit"
        >
          <SfButton
            data-transaction-name="PD - Edit Personal Details"
            @click="edit"
            class="sf-button--pure o-order-step__btn--edit"
          >
            {{ $t('Edit order') }}
          </SfButton>
        </div>
      </template>
    </SfHeading>
    <ASmoothReflow class="o-order-step__content">
      <MAuthorization
        v-if="!currentUser"
        :client-state-save="true"
        @check-code-open="checkCodeOpen"
        @check-code-close="checkCodeClose"
      />
      <template v-if="currentUser">
        <div v-if="!edited" class="o-order-step-content__row">
          {{currentUser.firstname}}, {{ phone }}
        </div>
        <div v-else class="user-info user-info--edited">
          <div class="form">
            <div class="o-order-step-content__row short-description">
              <div class="short-description__label">
                {{ $t('Number for communication and notifications') }}
              </div>
            </div>
            <div class="user-info__input--radio" v-if="options.length === 1">
              {{ currentUser.phone }}
            </div>
            <template v-else>
              <SfRadio
                v-for="(option, i) in options"
                :key="i"
                :value="option.value"
                :label="option.label"
                :selected="phone"
                data-transaction-name="PD - Change Phone"
                @input="changePhoneNumber"
                class="user-info__input--radio"
              />
            </template>
            <div v-if="showAddAnotherPhone" class="o-order-step-content__row short-description">
              <MAddAdditionalPhone :simple="true" @cancel="toggleAdditionalPhone" />
            </div>

            <template>
              <div class="o-order-step-content__row short-description">
                <div class="short-description__label">
                  {{ $t('Add another phone number in case, if we are unable to contact you on the main number') }}
                </div>
              </div>
              <div
                class="o-order-step-content__row o-order-step-content__row--input-additional-number o-order-step-content__row--align-left">
                <AAddNewLink
                    :icon="false"
                    :text="'Add another phone'"
                    :data-transaction-name="'Add phone'"
                    @click="toggleAdditionalPhone"
                />
              </div>
            </template>

            <div class="o-order-step-content__row o-order-step-content__end-m-16">
              <SfInput
                :label="$t('Your name')"
                :required="true"
                class="sf-input--filled o-order-step__input--width-100"
                v-model.trim="$v.firstname.$model"
                @blur="$v.firstname.$touch()"
                :valid="!$v.firstname.$error"
                :error-message="!$v.firstname.required
                  ? $t('Field is required')
                  : $t('Please use only letters or symbols')
                "
              />
            </div>

            <div class="user-info__footer">
              <SfButton
                class="sf-button--primary sf-button--full-width-mobile form__submit"
                data-transaction-name="PD - Save"
                @click="save"
              >
                {{ $t(isNewUser ? "Continue" : "Save changes") }}
              </SfButton>
            </div>
          </div>
        </div>
      </template>
    </ASmoothReflow>
  </div>
</template>
<script>
import {
  SfHeading,
  SfButton,
  SfRadio
} from '@storefront-ui/vue';
import {
  mapActions,
  mapGetters,
  mapState
} from 'vuex'
import MAuthorization from 'theme/components/molecules/m-authorization'
import MAddAdditionalPhone from 'theme/components/molecules/m-add-additional-phone';
import { Logger } from '@vue-storefront/core/lib/logger'
import { mask } from 'vue-the-mask'
import {
  required
} from 'vuelidate/lib/validators'
import { isNameValid } from 'theme/helpers/validation'
import SfInput from 'theme/components/storefront-override/SfInput'
import { getCheckoutSessionItemByKey } from 'theme/store/checkout/helpers/session'
import AAddNewLink from 'theme/components/atoms/a-add-new-link.vue';
import GoogleTagManager from 'theme/mixins/gtm'
import { eSputnikEvent } from 'theme/helpers/es';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import { ModalList } from 'theme/store/ui/modals';

export default {
  name: 'OPersonalDetails',
  data () {
    return {
      phone: '',
      firstname: '',
      email: '',
      options: [],
      checkoutLoaded: false,
      showAddAnotherPhone: false,
      isFilled: false
    }
  },
  components: {
    ASmoothReflow,
    AAddNewLink,
    SfHeading,
    SfButton,
    SfInput,
    SfRadio,
    MAuthorization,
    MAddAdditionalPhone
  },
  directives: {
    mask
  },
  mixins: [GoogleTagManager],
  validations () {
    return {
      firstname: {
        required,
        isNameValid
      }
    }
  },
  watch: {
    getPhonesList: {
      handler: function (val) {
        if (!val) return

        this.updatePhoneNumbers()
      }
    },
    isLoggedIn: {
      handler: function (val, oldVal) {
        if (!val || val === oldVal) return

        this.onLoggedIn(true)
        this.setDefaultValuesForEdit()
      }
    }
  },
  beforeMount () {
    this.$bus.$on('checkout-after-load', this.onCheckoutLoad)
    // this.$bus.$on('user-after-loggedin', this.onLoggedIn)
    this.$bus.$on('checkout-after-adding-number', this.onAddingNumber)
  },
  beforeDestroy () {
    this.$bus.$off('checkout-after-load', this.onCheckoutLoad)
    // this.$bus.$off('user-after-loggedin', this.onLoggedIn)
    this.$bus.$off('checkout-after-adding-number', this.onAddingNumber)
  },
  props: {
    getActiveStep: {
      required: true,
      type: String,
      default: () => ''
    },
    isLoggedIn: {
      required: true,
      type: Boolean,
      default: () => (false)
    }
  },
  computed: {
    ...mapGetters({
      cartItems: 'cart/getCartItems',
      getCartTotal: 'cart/getCartTotal',
      isNewUser: 'userInfo/getIsNewUserFlag'
    }),
    ...mapState({
      currentUser: (state) => state.user.current
    }),
    edited () {
      return this.getActiveStep === 'personalDetails'
    },
    isSavingFormDisabled () {
      const { $v } = this;

      return $v.firstname.$invalid;
    },
    getPhonesList () {
      if (!this.currentUser?.phone) return []

      return [this.currentUser?.phone, ...(this.currentUser?.['additional_phones'] || [])]
    }
  },
  methods: {
    ...mapActions({
      userUpdate: 'userInfo/update',
      spawnNotification: 'notification/spawnNotification',
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal',
      refreshUserData: 'user/me'
    }),
    checkCodeOpen (data) {
      this.openModal({ name: ModalList.OModalCheckoutCheckCode, payload: data })
    },
    checkCodeClose () {
      this.closeModal({ name: ModalList.OModalCheckoutCheckCode });
    },
    initData (email = this.currentUser.email, firstname = this.currentUser.firstname) {
      this.email = email
      this.firstname = firstname
    },
    sendDataToCheckout (onCheckoutLoad = false) {
      this.isFilled = true
      this.$bus.$emit(
        'checkout-after-personalDetails',
        {
          data: {
            email: this.email,
            firstname: this.firstname,
            phone: this.phone
          },
          onCheckoutLoad
        },
        true
      )
    },
    async logout () {
      await this.$store.dispatch('user/logout', {});
      await this.$router.push(this.localizedRoute('/'));
    },
    edit () {
      this.setDefaultValuesForEdit()
      this.$bus.$emit('checkout-before-edit', 'personalDetails')
    },
    checkIsChanged () {
      return this.firstname !== this.currentUser?.firstname
    },
    async save () {
      if (this.isSavingFormDisabled) {
        this.$v.$touch();
        return
      }

      if (!this.$v.$anyDirty || !this.checkIsChanged()) {
        this.sendDataToCheckout();
        return;
      }

      await this.saveUserData();
      this.sendDataToCheckout();
      this.$v.$reset();
    },
    async saveUserData () {
      const data = {
        email: this.email,
        firstname: this.firstname
      }
      try {
        await this.userUpdate({ customer: data })
        await this.refreshUserData({ refresh: true, useCache: false });

        this.gtmProductsHandler(this.cartItems, 'personal_data', {}, {
          value: this.getCartTotal
        })
      } catch (err) {
        Logger.error(err, 'user/update')()
        this.handleFailure(this.$t('Unexpected error'));
      }
    },
    handleFailure (result) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(result),
        action1: { label: this.$t('OK') }
      })
    },
    getOptionsList () {
      const phonesList = this.getPhonesList
      return phonesList.map(item => {
        return {
          value: item,
          label: String(item)
        }
      })
    },
    updatePhoneNumbers () {
      this.options = this.getOptionsList()
    },
    setDefaultValuesForEdit () {
      this.updatePhoneNumbers()
      this.email = this.currentUser.email
      this.firstname = this.currentUser.firstname
    },
    toggleAdditionalPhone () {
      this.showAddAnotherPhone = !this.showAddAnotherPhone
    },
    onLoggedIn (force = false) {
      if (this.checkoutLoaded || force) {
        this.changePhoneNumber()
        this.initData()

        const valid = this.currentUser.firstname

        if (valid) this.sendDataToCheckout()
        else this.edit()

        this.gtmProductsHandler(this.cartItems, 'personal_data', {}, {
          value: this.getCartTotal
        })

        eSputnikEvent('CustomerData', this.currentUser);
      }
    },
    changePhoneNumber (value = this.currentUser.phone) {
      this.phone = value
    },
    onCheckoutLoad () {
      this.checkoutLoaded = true
      const sessionData = getCheckoutSessionItemByKey('personalDetails')
      if (sessionData || this.currentUser) {
        if (sessionData) {
          this.initFromSessionData(sessionData);
        } else {
          this.initFromUserData();
        }

        this.setDefaultValuesForEdit()

        const valid = this.currentUser.firstname

        if (valid) this.sendDataToCheckout(true)
      }
    },
    initFromSessionData ({ phone, email, firstname }) {
      this.changePhoneNumber(phone);
      this.initData(email, firstname);
    },
    initFromUserData () {
      this.changePhoneNumber();
      this.initData();
    },
    onAddingNumber (newPhoneNumber) {
      this.changePhoneNumber(newPhoneNumber)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/components/organisms/o-order-step";

.o-order-step-content__row {
  &--input-additional-number,
  &--input-email {
    margin: 0 0 var(--spacer-20);
  }
}

.form__element {
  margin: 0;
  --input-background: var(--light-gray);
  --input-padding: var(--spacer-27) var(--spacer-15) var(--spacer-10);
  --input-font-line-height: var(--spacer-18);
  --input-border: none;
  --input-label-font-size: var(--font-size-12);
  --input-label-font-line-height: var(--font-size-15);
}

.title {
  --heading-padding: var(--spacer-base) 0;
  @include for-desktop {
    --heading-title-font-size: var(--h3-font-size);
    --heading-padding: var(--spacer-2xl) 0 var(--spacer-base) 0;
  }
}

.log-in__info {
  margin: var(--spacer-lg) 0;
  color: var(--c-dark-variant);
  font: var(--font-light) var(--font-base) / 1.6 var(--font-family-primary);
  @include for-desktop {
    font-weight: var(--font-normal);
    font-size: var(--font-sm);
  }
}

.log-in__button {
  margin: var(--spacer-2xl) 0 var(--spacer-xl) 0;
}

.user-info {
  &--edited {
    .user-info__row:last-child {
      margin: 0 0 var(--spacer-20);
    }
  }
  &__row {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }
  &__label,
  &__value {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: var(--font-size-17);
    padding: 0 var(--spacer-10) 0 0;
    box-sizing: border-box;
  }
  &__label {
    color: var(--dark-gray);
    flex: 0 0 90px;
  }
  &__value {
    color: var(--black);
  }
  &__input--radio {
    --radio-background: transparent;
    --radio-container-padding: 0;
    --radio-content-margin: 0 0 0 var(--spacer-10);
    margin: 0 0 var(--spacer-10);
    font-weight: var(--font-medium);

    @media only screen and (min-width: $tablet-min) {
      padding: 0;
    }
  }
}

::v-deep {
  .add-phone-inner-form {
    width: 100%;
  }
  .sf-radio__content {
    display: flex;
    align-items: center;
  }
  .m-register {
    width: 100%;
  }
  .sf-radio__label {
    font-size: var(--font-size-14);
  }
}

.short-description {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;

  @media only screen and (min-width: $tablet-min) {
    padding: 0;
  }

  &__label {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: var(--font-size-17);
    color: var(--black);
    flex: auto;
    padding: 0 var(--spacer-10) 0 0;
    box-sizing: border-box;
  }
}

.o-personal-details {
  ::v-deep .sf-input__error-message {
    height: unset;
    max-height:  var(--spacer-30);
  }
}
</style>
