import { render, staticRenderFns } from "./SfOProductCard.vue?vue&type=template&id=704e8e31&scoped=true"
import script from "./SfOProductCard.vue?vue&type=script&lang=js"
export * from "./SfOProductCard.vue?vue&type=script&lang=js"
import style0 from "./SfOProductCard.vue?vue&type=style&index=0&id=704e8e31&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "704e8e31",
  null
  
)

export default component.exports