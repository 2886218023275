<template>
  <div
    class="o-header-wrapper o-header-wrapper--bottom"
    :class="[
      {'o-header-wrapper--catalog-active': isMobileCatalog},
      { 'o-header-wrapper--sticky': showPreHeaderBanner ? scrollPosition > 110 : scrollPosition > 70 },
      {'pre-header-exist': showPreHeaderBanner}
    ]"
  >
    <SfOverlay
      class="overlay"
      :visible="isOverlay"
      :transition="'none'"
      @mouseover="hideOverlay"
      @click="hideOverlay"
    />
    <div
      class="sf-header"
      :class="[className, isMicrocartOpen]"
    >
      <div class="sf-header-wrapper">
        <header
          ref="header"
          class="o-header"
        >
          <div class="o-header__row o-header__row--only-desktop">
            <div
              :class="{'o-header__catalog--active': isMobileCatalog}"
              class="o-header__col o-header__col--left o-header__catalog"
              @mouseover="openMegaMenu"
              @mouseleave="closeMegaMenu"
            >
              <ACatalogButton />
              <MCatalogMenu
                v-show="isMobileCatalog"
                :class="catalogClassName"
                :categories-ids="categories"
              />
            </div>
            <div class="o-header__col o-header__col--center">
              <ul
                class="o-header__submenu-nav sf-header__navigation"
                :class="subMenuClassName"
              >
                <li
                  v-for="(subMenuItem, i) in subMenu"
                  :key="i"
                  class="o-header__submenu-nav-item"
                  data-transaction-name="Header Menu"
                  @click="selectItem(subMenuItem.name)"
                >
                  <SfLink
                    :link="subMenuItem.path"
                  >
                    {{ subMenuItem.name }}
                  </SfLink>
                </li>
              </ul>
              <div class="search-container"
               :class="searchContainerClassName"
              >
                <OSearch :class="searchClassName" />
              </div>
            </div>
            <div class="o-header__col o-header__col--right sf-header__mc">
              <AMicrocartIcon />
            </div>
          </div>
        </header>
      </div>
    </div>
  </div>
</template>
<script>
import { SfOverlay, SfLink } from '@storefront-ui/vue';
import { getTopLevelCategories } from 'theme/helpers';
import AMicrocartIcon from 'theme/components/atoms/a-microcart-icon';
import { mapState, mapGetters } from 'vuex';
import MCatalogMenu from 'theme/components/molecules/m-catalog-menu';
import { isClient } from '@storefront-ui/vue/src/utilities/helpers';
import ACatalogButton from '../atoms/a-catalog-button';
import DeviceType from 'theme/mixins/DeviceType';
import OSearch from 'theme/components/organisms/o-search';

export default {
  name: 'OHeaderBottom',
  components: {
    ACatalogButton,
    SfLink,
    AMicrocartIcon,
    OSearch,
    MCatalogMenu,
    SfOverlay
  },
  mixins: [DeviceType],
  data () {
    return {
      isHoveredMenu: false,
      isSidebarOpen: false,
      height: 0,
      scrollPosition: null,
      subMenu: [
        // {
        //   name: this.$t('VARUS Perfect'),
        //   path: '/varus-perfect'
        // },
        {
          name: this.$t('Delivery for business'),
          path: this.localizedRoute('/optovi-zakupivli')
        },
        {
          name: this.$t('VARUS CAFE'),
          path: this.localizedRoute('/varuscafe')
        },
        {
          name: this.$t('Promotional newspaper'),
          path: this.localizedRoute('/gazeta-varus-0')
        },
        {
          name: this.$t('Stock'),
          path: this.localizedRoute('/promotion')
        }
      ],
      activeSubMenuItem: ''
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll);
  },
  computed: {
    ...mapState({
      isSearchPanelVisible: state => state.ui.searchpanel,
      isMobileMenu: state => state.ui.isMobileMenu,
      isMobileCatalog: state => state.ui.isMobileCatalog,
      microcart: state => state.ui.microcart,
      showPreHeaderBanner: state => state.ui.preHeaderBanner,
      getCategories: state => state.category.list
    }),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    }),
    className () {
      return {
        'sf-header--has-mobile-search': this.isSearchPanelVisible,
        'sf-header--is-sticky': this.isSearchPanelVisible
      };
    },
    isOverlay () {
      return this.isMobileCatalog
    },
    catalogClassName () {
      return {
        'm-catalog-menu--active': this.isMobileCatalog
      };
    },
    subMenuClassName () {
      return {
        'o-header__submenu-nav--none': this.isSearchPanelVisible
      }
    },
    searchContainerClassName () {
      return {
        'search-container--opened': this.isSearchPanelVisible
      }
    },
    searchClassName () {
      return {
        'desktop-only': !this.isSearchPanelVisible
      }
    },
    categories () {
      return getTopLevelCategories(this.getCategories);
    },
    headerHeightDeps () {
      return {
        isSearchPanelVisible: this.isSearchPanelVisible,
        screenSize: this.$mq
      }
    },
    authMenuItem () {
      return this.authMenu[0]
    },
    isMicrocartOpen () {
      return {
        'sf-header--is-microcart-open': this.microcart
      }
    }
  },
  methods: {
    selectItem (item) {
      this.activeSubMenuItem = item
    },
    updateScroll () {
      this.scrollPosition = window.scrollY
    },
    hideOverlay () {
      this.$store.commit('ui/setSearchpanel', false);
      this.$store.commit('ui/closeCatalog');
      this.$store.commit('ui/closeMenu');
    },
    menuClassName (link) {
      return {
        'o-header__menu-nav--has-child': link.hasOwnProperty('items'),
        'dropdown-chevron': link.hasOwnProperty('items')
      }
    },
    closeMenu () {
      this.$store.commit('ui/closeMenu')
      this.isSidebarOpen = false
    },
    sidebarToggle (value) {
      this.isSidebarOpen = value
    },
    goToCatalog () {
      this.$store.commit('ui/setSearchpanel', false)
      this.$store.commit('ui/openCatalog')
    },
    logout () {
      this.$store.dispatch('user/logout', {});
      this.$router.push(this.localizedRoute('/'));
    },
    openMegaMenu () {
      if (!this.isMobileCatalog) {
        this.$store.commit('ui/openCatalog');
        this.$store.commit('ui/setSearchpanel', false);
      }
    },
    closeMegaMenu () {
      this.hideOverlay()
    }
  },
  watch: {
    async isMobileMenu () {
      if (this.isMobileMenu) {
        document.body.classList.add('body-fixed')
      } else {
        document.body.classList.remove('body-fixed')
      }
    },
    async isMobileCatalog () {
      if (this.isMobileCatalog) {
        document.body.classList.add('body-fixed')
      } else {
        document.body.classList.remove('body-fixed')
      }
    },
    headerHeightDeps: {
      handler () {
        if (!isClient) return;
        this.$nextTick(() => {
          this.height = this.$refs.header?.offsetHeight || 0;
        });
      },
      immediate: true
    },
    async isSearchPanelVisible () {
      if (this.isSearchPanelVisible) {
        this.$store.commit('ui/closeCatalog');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";

.o-header-wrapper {
  &--catalog-active {
    overflow: initial;
  }

  &--bottom {
    @include for-desktop {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: var(--header-bottom-panel-index);
      background: var(--white);

      &.pre-header-exist {
        ::v-deep {
          .m-megamenu {
            &__main {
              max-height: calc(100vh - 8rem - var(--pre-header-desktop-height));
            }
          }
        }
      }
    }
  }
}

.o-header__submenu-nav-item {
  ::v-deep .sf-link {
    color: var(--black);
  }
}

.sf-header {
  position: static;

  @include for-desktop {
    border-bottom: 1px solid rgba(195, 198, 208, .5);
    height: 60px;
  }

  &__mc {
    height: 100%;
  }

  &--is-sticky {
    @include for-mobile {
      position: fixed;
      top: 0;
      width: 100%;
      overflow: hidden;
      background-color: var(--white);
      z-index: var(--header-sticky-index);
    }
    @include for-desktop {
      border-bottom: 1px solid transparent;
    }
  }

  &--is-microcart-open {
    @include only-mobile {
      display: none;
    }
  }

  .o-header__row {
    .o-header__catalog {
      @include for-mobile {
        width: 100%;
      }

      &--active:after {
        display: none;
      }

      .m-catalog-menu {
        &--active {
          background-color: var(--white);
          @include for-mobile {
            width: 100%;
          }
        }
      }
    }

    &:after {
      border-color: var(--line-gray);
    }
  }

  .search-container {
    position: relative;
  }

  .logout-link {
    cursor: pointer;
    border-top: 1px solid var(--light-gray);
    padding-top: var(--spacer-15);
    color: var(--orange);
  }
  &__navigation {
    ::v-deep {
      .router-link-active {
        color: var(--orange);
        transition: color 0.3s ease-in-out;
        &:hover {
          color: var(--orange-hover);
        }
      }
    }
  }
}
</style>
