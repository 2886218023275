import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { CatalogModule } from 'theme/store/catalog'
import { CategoryModule } from '$modules/category'
import { ProductModule } from '$modules/product';
import { CatalogNextModule } from '@vue-storefront/core/modules/catalog-next'
import { CheckoutModule } from 'theme/store/checkout'
import { BlogModule } from 'theme/store/blog'
import { NotificationModule } from '@vue-storefront/core/modules/notification'
import { UrlModule } from '@vue-storefront/core/modules/url'
import { BreadcrumbsModule } from '@vue-storefront/core/modules/breadcrumbs'
import { UserModule } from 'theme/store/user'
import { CmsModule } from '@vue-storefront/core/modules/cms'
import { NewsletterModule } from 'theme/store/newsletter'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { SearchResultModule } from '../store/search-result'
import { CategoryExtensionModule } from 'theme/store/category-extension'
import { RecentlyViewedModule } from 'theme/store/recently-viewed'
import { WishlistModule } from 'theme/store/wishlist'
import { BannerModule } from '$modules/banner'
import { UserCommunicationModule } from 'theme/store/user-communication'
import { PaymentModule } from 'theme/store/payment'
import { UserPaymentCardsModule } from 'theme/store/user-payment-cards'
import { CartModule } from 'theme/store/cart'
import { ConfigVarusModule } from '$modules/config-varus'
import { BonusCardModule } from 'theme/store/bonusCard'
import { PromotionModule } from '$modules/promotion'
import { TendersModule } from 'theme/store/tenders'
import { ProductReviewModule } from 'theme/store/product-review'
import { SpecialOffersModule } from '$modules/special-offers';
import { BrandsModule } from '$modules/brands';
import { BrandModule } from '$modules/brand';
// import { VarusPerfectModule } from '$modules/varus-perfect';
import { FAQModule } from '$modules/faq';
import { VarusCafeModule } from '$modules/varus-cafe';

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules () {
  registerModule(UrlModule)
  registerModule(CategoryModule)
  registerModule(CatalogModule)
  registerModule(CheckoutModule) // To Checkout
  registerModule(CartModule)
  registerModule(PaymentModule)
  registerModule(NotificationModule)
  registerModule(UserModule) // Trigger on user icon click
  registerModule(CatalogNextModule)
  registerModule(BreadcrumbsModule)
  registerModule(CmsModule)
  registerModule(NewsletterModule)
  registerModule(CategoryExtensionModule)
  registerModule(SearchResultModule);
  registerModule(RecentlyViewedModule)
  registerModule(WishlistModule)
  registerModule(BannerModule)
  registerModule(UserCommunicationModule)
  registerModule(UserPaymentCardsModule)
  registerModule(ConfigVarusModule)
  registerModule(BonusCardModule)
  registerModule(PromotionModule)
  registerModule(BlogModule)
  registerModule(BrandsModule)
  registerModule(BrandModule)
  registerModule(TendersModule)
  registerModule(ProductReviewModule)
  registerModule(SpecialOffersModule)
  registerModule(FAQModule)
  registerModule(ProductModule)
  // registerModule(VarusPerfectModule)
  registerModule(VarusCafeModule)
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  // Example
]
