<template>
  <div class="product">
    <div class="product__container">
      <div class="product__block">
        <m-breadcrumbs />
      </div>
    </div>
    <json-ld-breadcrumb />

    <div class="product__container">
      <div class="product__block">
        <OProductDetails
          :is-loading="productLoader"
          :product="getCurrentProduct"
          :product-gallery="getProductGallery"
          :product-configuration="getCurrentProductConfiguration"
          :product-custom-options="getCurrentCustomOptions"
          :product-attributes="getCustomAttributes"
          :product-stock="productStock"
          :reviews="reviews"
          :is-new-post="isNewPost"
          :product-promotion-banners="productPromotionBanners"
          :discount-products="discountProducts"
          :should-show-price="shouldShowPrice"
        />
      </div>
    </div>

    <div
      :id="buyWithOrInsteadProductWidgetId"
      :key="buyWithOrInsteadProductWidgetId + getCurrentProduct.id"
    />
    <OSection
      v-show="buyWithOrInsteadBlockIsVisible"
      :title-heading="buyWithOrInsteadProductTitle"
      key="buy-with-product"
      is-centered
      is-not-mobile-padding
      class="product-list-section buy-with-product"
    >
      <lazy-hydrate when-idle>
        <MProductsRelated
          :alt-counter-offset="2"
          :alt-name="getCurrentProduct.name"
          :prop-skus="esputnikBuyWithOrInsteadProductSku"
          arrow-color="white"
          type="buyWithOrInsteadProduct"
          only-in-stock
          @relatedProductsQty="insteadProductsQty"
          @visibilityUpdate="getBuyWithOrInsteadBlockIsVisible"
          :es-url-param="esUrlParamBuyWithOrInsteadProduct"
          :show-qty-permanently="true"
        />
      </lazy-hydrate>
    </OSection>
    <MProductAdditionalInfo
      class="product__additional-info"
      :is-loading="productLoader"
      :product="getCurrentProduct"
      :product-custom-options="getCurrentCustomOptions"
      :product-stock="productStock"
      :reviews="reviews"
      :key="getCurrentProduct.id"
    />
    <OSection
      v-if="newProducts.length"
      :title-heading="$t('New arrivals')"
      key="new-products"
      is-centered
      is-not-mobile-padding
      class="product-list-section buy-with-product"
    >
      <MProductCarousel
        :products="newProducts"
        :carousel-key="newProducts.length"
        list-id="product_new"
        list-name="Product Page New products"
      />
    </OSection>
    <OSection
      v-if="recommendedProducts.length"
      :title-heading="$t('We recommend watching')"
      key="recommended-products"
      is-centered
      is-not-mobile-padding
      class="product-list-section buy-with-product"
    >
      <MProductCarousel
        v-if="recommendedProducts.length"
        list-id="product_recommended"
        list-name="Product Page Recommended products"
        :products="recommendedProducts"
        :carousel-key="recommendedProducts.length"
      />
    </OSection>
    <OSection
      v-if="topProducts.length"
      :title-heading="$t('More')"
      :orange-title-part="getCategoryName"
      :orange-title-link="getCategoryUrl"
      key="similar-products"
      is-centered
      is-image
      is-not-mobile-padding
      class="product-list-section buy-instead-product"
      :class="topProducts.length > 6 ? '' : 'buy-instead-product__without-arrows'"
    >
      <template #link>
        <SfLink :link="getCategoryUrl" class="sf-link--primary">
          {{ $t('All products of the category') }}
        </SfLink>
      </template>
      <MProductCarousel
        v-if="topProducts.length"
        list-id="product_top"
        list-name="Product Page Top products"
        :products="topProducts"
        :carousel-key="topProducts.length"
      />
    </OSection>
    <OSection
      v-if="getProductTM.length"
      :title-heading="$t('Other categories TM')"
      :orange-title-part="brandName"
      :orange-title-link="brandLink"
      key="brands-offers"
      is-not-mobile-padding
      is-centered
      class="tm-section"
    >
      <MTmCarousel
        v-if="getCurrentProduct.brand_data.brand_id"
        class="flex"
        :pim-brand-id="getCurrentProduct.brand_data.brand_id"
        :subcategories="getProductTM"
      />
    </OSection>
    <OSection
      :title-heading="$t('Articles')"
      key="article"
      v-if="posts.length"
      is-centered
      is-not-mobile-padding
      class="o-section--article-product"
    >
      <template #link>
        <SfLink
          :link="localizedRoute('/blog')"
          class="sf-link--primary all-products-link"
        >
          {{ $t('All articles') }}
        </SfLink>
      </template>
      <lazy-hydrate when-idle>
        <mArticleCarouselProductPage :posts="posts" />
      </lazy-hydrate>
    </OSection>
    <JsonLdProduct :product="this.getCurrentProduct" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import { ReviewModule } from '@vue-storefront/core/modules/review';
import { registerModule } from '@vue-storefront/core/lib/modules';
import { onlineHelper, isServer } from '@vue-storefront/core/helpers';
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks';
import MProductsRelated from '../components/molecules/m-products-related';
import OProductDetails from '../components/organisms/o-product-details';
import { SfLink } from '@storefront-ui/vue';
import MBreadcrumbs from '../components/molecules/m-breadcrumbs';
import { getEsputnikProductIds, getEsputnikUrl } from 'theme/helpers';
import OSection from 'theme/components/organisms/o-section';
import MTmCarousel from '../components/molecules/m-tm-carousel';
import get from 'lodash/get'
import MProductAdditionalInfo from 'theme/components/molecules/m-product-additional-info';
import config from 'config';
import JsonLdProduct from 'theme/components/json-ld/json-ld-product';
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import GoogleTagManager from 'theme/mixins/gtm';
import { metaProduct } from '../meta/product';
import mArticleCarouselProductPage from 'theme/components/molecules/m-article-carousel-product-page';
import modulesConfig from '$modules/config';
import { Logger } from '@vue-storefront/core/lib/logger';
import { getCurrentShopId } from 'theme/store/checkout/helpers';
import MProductCarousel from 'theme/components/molecules/m-product-carousel';

export default {
  name: 'Product',
  components: {
    JsonLdBreadcrumb,
    JsonLdProduct,
    LazyHydrate,
    MProductsRelated,
    OProductDetails,
    OSection,
    MBreadcrumbs,
    MTmCarousel,
    MProductAdditionalInfo,
    mArticleCarouselProductPage,
    SfLink,
    MProductCarousel
  },
  mixins: [
    GoogleTagManager
  ],
  provide () {
    return {
      isProductPage: true
    };
  },
  data () {
    return {
      esputnikBuyWithOrInsteadProductSku: [],
      esputnikSimilarProductsSku: [],
      buyWithOrInsteadBlockIsVisible: false,
      relatedProductLength: 0,
      insteadProductsLength: 0,
      esUrlParamBuyWithOrInsteadProduct: '',
      shouldShowPrice: false
    };
  },
  computed: {
    ...mapState({
      productLoader: state => state.ui.productLoader,
      getCurrentProduct: state => state.product.current,
      discountProducts: state => state.product.discountProducts,
      topProducts: state => state.product.topProducts,
      newProducts: state => state.product.newProducts,
      recommendedProducts: state => state.product.recommendedProducts,
      shippingDataLoaded: state => state['shipping-module'].loaded.shipping
    }),
    ...mapGetters({
      getCurrentCategory: 'category-next/getCurrentCategory',
      getProductGallery: 'product/getProductGallery',
      getCurrentProductConfiguration: 'product/getCurrentProductConfiguration',
      getOriginalProduct: 'product/getOriginalProduct',
      attributesByCode: 'attribute/attributeListByCode',
      getCurrentCustomOptions: 'product/getCurrentCustomOptions',
      getBreadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes',
      shippingDetails: 'shipping/getShippingDetails',
      getProductPromotionBanners: 'promoted/getProductPromotionBanners',
      productAttributeLabel: 'product/getProductAttributeLabel',
      posts: 'blog/getPosts',
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    productPromotionBanners () {
      const hasPromotionBannerIds = this.getCurrentProduct?.promotion_banner_ids && this.getCurrentProduct.promotion_banner_ids.length;

      if (!hasPromotionBannerIds) return [];

      return this.getProductPromotionBanners.filter(banner => this.getCurrentProduct.promotion_banner_ids.includes(String(banner.id)));
    },
    productStock () {
      const max = this.getCurrentProduct?.stock?.manage_stock
        ? Number(this.getCurrentProduct.stock.qty)
        : null

      const isInStock = this.isNewPost
        ? this.getCurrentProduct?.forNewPost && this.getCurrentProduct.stock.is_in_stock
        : this.getCurrentProduct.stock.is_in_stock

      return {
        manageQuantity: this.getCurrentProduct.stock.manage_stock,
        isNewPost: this.isNewPost,
        isInStockSQPP: this.getCurrentProduct.stock.is_in_stock,
        isInStock,
        max
      }
    },
    getProductTM () {
      return this.getCurrentProduct?.brand_data?.subcategories || []
    },
    brandName () {
      return this.getCurrentProduct?.brand_data?.name || ''
    },
    brandLink () {
      return this.localizedRoute(this.getCurrentProduct?.brand_data?.url_key || '')
    },
    getCategoryName () {
      const categories = this.getCurrentProduct?.category || []
      const productCategories = categories.filter(e => e.is_product_category && e.level > 2) || []
      return productCategories.length ? productCategories[productCategories.length - 1].name : ''
    },
    getCategoryUrl () {
      return this.getBreadcrumbsRoutes[this.getBreadcrumbsRoutes.length - 1]?.route_link
    },
    reviews () {
      const baseReviews = get(this.$store.state.review, 'items.items', [])
      return baseReviews.map((review) => ({
        author: review.nickname,
        date: review.created_at,
        message: `${review.title}: ${review.detail}`,
        rating: 1 // TODO: remove hardcode
      }))
    },
    isOnline () {
      return onlineHelper.isOnline;
    },
    getCustomAttributes () {
      const attributes = Object.values(this.attributesByCode)
        .filter(a => (
          a.is_visible &&
          a.is_user_defined &&
          (parseInt(a.is_visible_on_front) || a.is_visible_on_front === true) &&
          this.getCurrentProduct[a.attribute_code]
        ))

      return attributes.length ? attributes.sort((a, b) => a.attribute_id > b.attribute_id) : []
    },
    buyWithOrInsteadProductTitle () {
      return this.getCurrentProduct.stock?.is_in_stock ? this.$t('Buy with this product') : this.$t('Buy instead of this product')
    },
    buyWithOrInsteadProductWidgetId () {
      return this.getCurrentProduct.stock?.is_in_stock ? config.esputnik?.widgetIds?.buyWithProduct : config.esputnik?.widgetIds?.insteadBuyProduct
    }
  },
  watch: {
    'shippingDetails.shopId': {
      immediate: true,
      async handler (newValue, oldValue) {
        if ((!newValue && !this.isNewPost) || newValue === oldValue) return
        await this.reloadProductData()
        this.shouldShowPrice = true
      }
    },
    'isNewPost': {
      async handler (newValue) {
        if (!newValue) return
        await this.reloadProductData()
      }
    },
    'shippingDataLoaded': {
      immediate: true,
      async handler (newValue, oldValue) {
        const currentShopId = this.shippingDetails.shopId

        if (newValue && !oldValue && currentShopId === undefined) {
          this.shouldShowPrice = true
        }
      }
    },
    getCurrentProduct () {
      let currentProduct = this.getCurrentProduct;
      currentProduct.store = currentStoreView().storeCode;
      this.addItemToRecentlyViewedItems(currentProduct);
    }
  },
  async mounted () {
    window.addEventListener('buyWithProductLoaded', this.getBuyWithOrInsteadProductSku)
    window.addEventListener('insteadBuyProductPageLoaded', this.getBuyWithOrInsteadProductSku)

    // if (isAlreadyLoaded) return

    // const slug = this.$route.params.slug;
    // const product = await this.$store.dispatch('product/loadProduct', {
    //   slug: slug,
    //   includeFields: modulesConfig.singleProduct.includeFields,
    //   excludeFields: modulesConfig.singleProduct.excludeFields,
    //   skipCache: true
    // });
    // console.log('mounted - product', this.shippingDetails.shopId, product)
    // const categoryId = product.category[product.category.length - 1].category_id;
    // product.store = currentStoreView().storeCode;

    // const shopId = await getCurrentShopId()
    // const discountProductIds = product[`markdown_items_${shopId}`] || []
    // const toPromise = [
    //   this.$store.dispatch('ui/loadCatalogMenu'),
    //   this.$store.dispatch(
    //     'product/loadProductBreadcrumbs',
    //     { product }
    //   ),
    //   this.$store.dispatch('product/loadCharacteristicAttributes', { product }),
    //   this.$store.dispatch('promoted/updateProductPromotionBanners', { promotionBannerIds: product?.promotion_banner_ids }),
    //   this.$store.dispatch('product-review/loadReview', product.id),
    //   this.$store.dispatch('product/loadTopProductsByCategoryId', {
    //     categoryId: categoryId
    //   }),
    //   this.$store.dispatch('product/loadNewProductsByCategoryId', {
    //     categoryId: categoryId
    //   }),
    //   this.$store.dispatch('product/loadRecommendedProductsByCategoryId', {
    //     categoryId: categoryId
    //   }),
    //   this.$store.dispatch('review/list', {
    //     productId: this.getOriginalProduct.id
    //   }),
    //   this.$store.dispatch('blog/getRelatedArticlesByproductId', { productId: product.id })
    // ]

    // if (discountProductIds.length) {
    //   toPromise.push(this.$store.dispatch('product/loadDiscountProducts', { ids: discountProductIds }))
    // } else {
    //   toPromise.push(this.$store.dispatch('product/loadDiscountProducts', { ids: [] }))
    // }

    // await Promise.allSettled(toPromise)

    // this.$nextTick(() => this.addItemToRecentlyViewedItems(product))

    // catalogHooksExecutors.productPageVisited(product);
  },
  async asyncData ({ store, route }) {
    if (isServer) {
      const slug = route.params.slug;
      const product = await store.dispatch('product/loadProduct', {
        slug: slug,
        includeFields: modulesConfig.singleProduct.includeFields,
        excludeFields: modulesConfig.singleProduct.excludeFields,
        skipCache: true
      });

      const categoryId = product.category[product.category.length - 1].category_id;

      const shopId = await getCurrentShopId()
      const discountProductIds = product[`markdown_items_${shopId}`] || []
      const toPromise = [
        store.dispatch('ui/loadCatalogMenu'),
        store.dispatch('config-varus/get', { path: ['header_logo_src'] }),
        store.dispatch(
          'product/loadProductBreadcrumbs',
          { product }
        ),
        store.dispatch('product/loadCharacteristicAttributes', { product }),
        store.dispatch('promoted/updateProductPromotionBanners', { promotionBannerIds: product?.promotion_banner_ids }),
        store.dispatch('product-review/loadReview', product.id),
        store.dispatch('product/loadTopProductsByCategoryId', {
          categoryId: categoryId,
          skipProducts: [product.id]
        }),
        store.dispatch('product/loadNewProductsByCategoryId', {
          categoryId: categoryId,
          skipProducts: [product.id]
        }),
        store.dispatch('product/loadRecommendedProductsByCategoryId', {
          categoryId: categoryId,
          skipProducts: [product.id]
        }),
        store.dispatch('promoted/updatePreHeaderBanners')
      ]

      if (discountProductIds.length) {
        toPromise.push(store.dispatch('product/loadDiscountProducts', { ids: discountProductIds }))
      } else {
        toPromise.push(store.dispatch('product/loadDiscountProducts', { ids: [] }))
      }

      await Promise.allSettled(toPromise)
      await store.dispatch('product/loadInStockProductVariants')

      catalogHooksExecutors.productPageVisited(product);
    }

    if (isServer) return
    await store.dispatch('ui/productLoader', true)

    const slug = route.params.slug;
    const product = await store.dispatch('product/loadProduct', {
      slug: slug,
      includeFields: modulesConfig.singleProduct.includeFields,
      excludeFields: modulesConfig.singleProduct.excludeFields,
      skipCache: true
    });

    const categoryId = product.category[product.category.length - 1].category_id;

    const shopId = await getCurrentShopId()
    const discountProductIds = product[`markdown_items_${shopId}`] || []

    const toPromise = [
      store.dispatch('ui/loadCatalogMenu'),
      store.dispatch('config-varus/get', { path: ['header_logo_src'] }),
      store.dispatch(
        'product/loadProductBreadcrumbs',
        { product }
      ),
      store.dispatch('product/loadCharacteristicAttributes', { product }),
      store.dispatch('promoted/updateProductPromotionBanners', { promotionBannerIds: product?.promotion_banner_ids }),
      store.dispatch('product-review/loadReview', product.id),
      store.dispatch('product/loadTopProductsByCategoryId', {
        categoryId: categoryId,
        skipProducts: [product.id]
      }),
      store.dispatch('product/loadNewProductsByCategoryId', {
        categoryId: categoryId,
        skipProducts: [product.id]
      }),
      store.dispatch('product/loadRecommendedProductsByCategoryId', {
        categoryId: categoryId,
        skipProducts: [product.id]
      })
    ]

    if (discountProductIds.length) {
      toPromise.push(store.dispatch('product/loadDiscountProducts', { ids: discountProductIds }))
    } else {
      toPromise.push(store.dispatch('product/loadDiscountProducts', { ids: [] }))
    }

    await Promise.allSettled(toPromise)
    await store.dispatch('product/loadInStockProductVariants')

    catalogHooksExecutors.productPageVisited(product);

    await store.dispatch('ui/productLoader', false)
  },
  beforeCreate () {
    registerModule(ReviewModule);
  },
  beforeMount () {
    this.$bus.$on('shipping-details-changed', this.updateCurrentProductHandler)
  },
  async beforeRouteEnter (to, from, next) {
    if (isServer) {
      next();
    } else {
      next(async vm => {
        const { getCurrentProduct } = vm;
        const VIEW_ITEM = 'view_item';
        const VIEW_ITEM_ADS = 'view_item_ads';

        try {
          await vm.loadInStockProductVariants();
        } catch (e) {
          Logger.error('Error loading in stock variants: ', e);
        }

        try {
          vm.gtmProductsHandler(getCurrentProduct, VIEW_ITEM, {}, {
            value: true,
            calculate: true
          });
        } catch (e) {
          Logger.error('Error handling GTM products: ', e);
        }

        try {
          vm.gtmProductsHandlerAds(getCurrentProduct, VIEW_ITEM_ADS);
        } catch (e) {
          Logger.error('Error handling GTM products ads: ', e);
        }

        try {
          vm.admitadRetagProductPage(getCurrentProduct);
        } catch (e) {
          Logger.error('Error retagging product page: ', e);
        }
      });
    }
  },
  beforeDestroy () {
    window.removeEventListener('buyWithProductLoaded', this.getBuyWithOrInsteadProductSku)
    window.removeEventListener('insteadBuyProductPageLoaded', this.getBuyWithOrInsteadProductSku)
    this.$bus.$off('shipping-details-changed', this.updateCurrentProductHandler)
  },
  methods: {
    ...mapActions({
      addItemToRecentlyViewedItems: 'recently-viewed/addItem',
      loadInStockProductVariants: 'product/loadInStockProductVariants',
      loadStockForCurrentProduct: 'product/loadStockForCurrentProduct'
    }),
    relatedProductsQty (value) {
      this.relatedProductLength = value
    },
    insteadProductsQty (value) {
      this.insteadProductsLength = value
    },
    getBuyWithOrInsteadProductSku () {
      this.esputnikBuyWithOrInsteadProductSku = getEsputnikProductIds('#' + this.buyWithOrInsteadProductWidgetId + ' .recommend-product-id')
      this.esUrlParamBuyWithOrInsteadProduct = getEsputnikUrl('#' + this.buyWithOrInsteadProductWidgetId + ' .recommend-product-id')
    },
    getBuyWithOrInsteadBlockIsVisible (isVisible) {
      this.buyWithOrInsteadBlockIsVisible = isVisible
    },
    async updateCurrentProductHandler () {
      try {
        return await this.loadStockForCurrentProduct()
      } catch (error) {
        Logger.error('Error updating current product:', error)
        // Обробка помилки
      }
    },
    async reloadProductData () {
      try {
        const shopId = this.shippingDetails.shopId;
        const slug = this.$route?.params?.slug || null;
        const product = await this.$store.dispatch('product/loadProduct', {
          slug,
          includeFields: modulesConfig.singleProduct.includeFields,
          excludeFields: modulesConfig.singleProduct.excludeFields,
          skipCache: true
        });

        const categoryId = product.category[product.category.length - 1].category_id;

        const discountProductIds = product[`markdown_items_${shopId}`] || []

        if (discountProductIds.length) {
          this.$store.dispatch('product/loadDiscountProducts', { ids: discountProductIds })
        } else {
          this.$store.dispatch('product/loadDiscountProducts', { ids: [] })
        }

        await Promise.allSettled([
          this.$store.dispatch('product/loadCharacteristicAttributes', { product }),
          this.$store.dispatch('product/loadTopProductsByCategoryId', { categoryId }),
          this.$store.dispatch('product/loadNewProductsByCategoryId', { categoryId }),
          this.$store.dispatch('product/loadRecommendedProductsByCategoryId', { categoryId })
        ])

        this.loadInStockProductVariants()
      } catch (error) {
        Logger.error('Error reloading product data:', error)
      }
    }
  },
  metaInfo: metaProduct
};
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.product__container {
  width: 100%;

  &--green {
    background-color: var(--green-light-grayish);
  }
}

.product__block {
  max-width: var(--max-width);
  box-sizing: border-box;
  margin: 0 auto;
}

.product-list-section {

  @include for-desktop {
    padding: var(--section-padding, var(--spacer-2xl) 0 0 0);
  }

  ::v-deep {
    .o-section--center {
      @media (min-width: 1024px) and (max-width: 1215px) {
        padding: 0 var(--spacer-lg);
      }
    }
  }
}

.sf-carousel__controls {
  --button-background: #fff !important;
}

.buy-with-product {
  background: var(--light-gray);
}

.buy-instead-product {
  background: var(--green-light-grayish);
  @include for-mobile {
    margin-top: 0;
  }
  ::v-deep .sf-heading__title--h3 {
    --heading-title-font-size: var(
        --section-heading-title-font-size,
        var(--font-size-20)
    );
    @media (min-width: $tablet-min) {
      --heading-title-font-size: var(
          --section-heading-title-font-size,
          var(--font-size-32)
      );
    }
  }
}

.buy-with-product,
.buy-instead-product {
  position: relative;

  @include for-mobile {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: var(--spacer-35);
  }

  @include for-desktop {
    padding-bottom: var(--spacer-10);
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  ::v-deep {
    .glide__slide .sf-product-card {
      padding-bottom: var(--spacer-20);
    }
  }

  ::v-deep .o-section {
    &--center {
      @include for-mobile {
        padding: 0;
      }

      @include for-desktop {
        padding: 0 var(--spacer-10);
      }

      .sf-link--primary {
        @include for-mobile {
          display: none;
        }

        @media (min-width: $tablet-min) {
          display: block;
        }
      }
    }

    &__heading {
      @include for-mobile {
        padding-left: var(--spacer-10);
        padding-right: var(--spacer-10);
      }
    }

    &--article-product {
      margin-bottom: 0 var(--spacer-100);

      @include only-mobile {
        margin-bottom: 0 var(--spacer-60);
      }
    }
  }

  ::v-deep .glide__slides {
    .sf-carousel-item {
      .sf-product-card {
        --product-card-height: 100%;
      }
    }
  }
}

.breadcrumbs {
  padding: var(--spacer-base) var(--spacer-base) var(--spacer-base)
    var(--spacer-sm);
}

.tm-section {
  --section-center-padding: 0;

  @media (min-width: $tablet-min) {
    --section-center-padding: 0 var(--spacer-sm);
  }

  ::v-deep {
    .o-section--center {
      .glide__slides {
        @include only-mobile {
          margin-left: var(--spacer-10);
        }
      }
    }

    .o-section__heading {
      padding-inline: var(--spacer-10);

      @media (min-width: $tablet-min) {
        padding-inline: 0;
      }
    }
  }
}

.banner {
  margin: var(--spacer-xl) 0;
  @include for-desktop {
    margin: var(--spacer-2xl) 0;
  }
}

::v-deep {
  .product__colors button {
    border: 1px solid var(--c-light);
  }

  .sf-heading {
    position: relative;
  }
}
</style>
