var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shp-area-map"},[_c('div',{staticClass:"shp-area-map__wrapper"},[(!_vm.loadingShippingModule)?_c('l-map',{ref:"map",attrs:{"options":_vm.mapOptions,"zoom":_vm.mapZoom,"center":_vm.mapCenter},on:{"ready":_vm.onMapReady,"update:center":_vm.setCenter,"update:zoom":_vm.setZoom}},[_c('l-tile-layer',{attrs:{"url":_vm.url,"attribution":_vm.attribution}}),_vm._v(" "),(_vm.draft.method === 'PICKUP')?[(_vm.shopSelected)?_c('l-marker',{ref:`shop-native-current`,attrs:{"lat-lng":{ lon: _vm.shopSelected.long, lat: _vm.shopSelected.lat },"icon":_vm.icon.shopActiveIcon}},[_c('l-popup',[_c('div',{staticClass:"shp-area-map__popup-title"},[_vm._v("\n              "+_vm._s(_vm.$t(_vm.draft.method))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"shp-area-map__popup-description"},[_vm._v("\n              "+_vm._s(_vm.shopSelected.address)+"\n            ")])])],1):_vm._e(),_vm._v(" "),_vm._l((_vm.pickupGroups),function(group){return [_vm._l((group.list),function(item){return (_vm.draft.type === group.type)?[(!_vm.shopSelected || (_vm.shopSelected.id !== item.id))?_c('l-marker',{key:`shop-${group.type}-${item.id}`,attrs:{"lat-lng":{ lon: item.long, lat: item.lat },"icon":_vm.icon.shopIcon},on:{"click":function($event){return _vm.popupOpen({
                shopId: item.id,
                type: group.type
              })}}}):_vm._e()]:_vm._e()})]})]:_vm._e(),_vm._v(" "),(_vm.isNovaCityReady && !_vm.isNovaDelivery)?[(_vm.npShopSelected)?_c('l-marker',{key:`shop-nova-current`,ref:`shop-nova-current`,attrs:{"lat-lng":{ lon: _vm.npShopSelected.longitude, lat: _vm.npShopSelected.latitude },"icon":_vm.icon.npActiveIcon}},[_c('l-popup',[_c('div',{staticClass:"shp-area-map__popup-description"},[_vm._v("\n              "+_vm._s(_vm.npShopSelected.short_address)+"\n            ")])])],1):_vm._e(),_vm._v(" "),_c('v-marker-cluster',_vm._l((_vm.npShopList),function(item){return (!_vm.npShopSelected || (_vm.npShopSelected.id !== item.id))?_c('l-marker',{key:`shop-nova-${item.id}`,attrs:{"lat-lng":{ lon: item.longitude, lat: item.latitude },"icon":_vm.icon.npIcon},on:{"click":function($event){return _vm.popupOpen({
                shopId: item.id,
            })}}}):_vm._e()}),1)]:_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.draft.method === _vm.config.codes.delivery || _vm.isNovaDelivery)?_c('div',{staticClass:"shp-area-map__delivery"},[_c('div',{staticClass:"shp-area-map__block"},[_c('div',{staticClass:"shp-area-map__block-popup",class:{'shp-area-map__block-popup--show': _vm.loadingDelivery || _vm.activeDelivery}},[_c('ASmoothReflow',[(_vm.loadingDelivery)?_c('ShippingLoading',{staticClass:"shp-area-map__block-popup-padding"}):_c('div',{staticClass:"shp-area-map__block-popup-padding"},[(_vm.draft.address)?[_vm._v("\n                "+_vm._s(_vm.draft.address.label)+"\n              ")]:_vm._e()],2)],1)],1),_vm._v(" "),_vm._m(0)])]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"shp-area-map__zoom"},[_c('button',{staticClass:"shp-area-map__zoom-in",attrs:{"disabled":_vm.zoom === _vm.mapOptions.maxZoom},on:{"click":_vm.zoomIn}}),_vm._v(" "),_c('button',{staticClass:"shp-area-map__zoom-out",attrs:{"disabled":_vm.zoom === _vm.mapOptions.minZoom},on:{"click":_vm.zoomOut}})]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.locateError)?_c('button',{staticClass:"shp-area-map__locate-error",on:{"click":_vm.clearDeliveryError}},[_vm._v("\n      "+_vm._s(_vm.$t('Location error'))+"\n    ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"shp-area-map__locate"},[(!_vm.loadingUserLocate)?_c('button',{staticClass:"shp-area-map__locate-button",on:{"click":_vm.requestGeolocation}}):_vm._e(),_vm._v(" "),(_vm.loadingUserLocate)?_c('ShippingLoading',{staticClass:"shp-area-map__block-popup-padding",attrs:{"absolute":true}}):_vm._e()],1),_vm._v(" "),(!_vm.loadingShippingModule)?[(_vm.isNova && !_vm.isNovaCityReady || _vm.loadingNewPost)?_c('div',{staticClass:"shp-area-map__overlay"},[(_vm.loadingNewPost)?_c('ShippingLoading',{attrs:{"absolute":true}}):[_vm._v("\n        "+_vm._s(_vm.$t('Enter locality city ' + _vm.draft.npType))+"\n      ")]],2):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shp-area-map__pointer"},[_c('img',{staticClass:"shp-area-map__pointer-image",attrs:{"src":"/assets/map/pointer-delivery-active.svg"}})])
}]

export { render, staticRenderFns }