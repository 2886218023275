<template>
  <div class="m-home-banner">
    <div class="m-home-banner__col">
      <div class="m-home-banner__image-wrap">
        <svg
          class="m-home-banner__image-left"
          width="274" height="220" viewBox="0 0 274 220" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M253.59 -14.24C253.59 -14.24 334.3 186.59 170.89 245.17C11.64 302.26 -55 105.45 -55 105.45C-19.97 96.11 15.95 93.46 41.41 100.08C47.16 68.43 56.75 35.25 81.87 2.97C113.18 6.76 152.47 25.72 173.35 43.43C195.23 20.13 222.04 0.95 253.59 -14.25V-14.24Z" fill="#57BFE8"/>
        </svg>
        <img
          class="m-home-banner__image"
          src="/assets/banner/png/loyalty-banner-phone.png"
          alt="Loyalty page"
        >
        <svg
          class="m-home-banner__image-right"
          width="192" height="138" viewBox="0 0 192 138" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M45.9599 131.9C43.6799 144.33 41.2099 161.91 39.6899 177.84" stroke="#000100" stroke-width="10.4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M45.9599 131.9C43.6799 144.33 41.2099 161.91 39.6899 177.84" stroke="#EF811A" stroke-width="8.09" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M156.66 74.82C180.31 16.3 141.91 32.35 145.23 7.97001C148.54 -16.42 218.28 25.2 162.59 74.66C160.7 73.17 158.81 77.59 156.67 74.82H156.66Z" fill="#EF811A" stroke="#000100" stroke-width="1.19" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M46.1699 109.9C46.1699 109.9 65.2499 112.82 66.4399 96.84" stroke="#000100" stroke-width="1.19" stroke-miterlimit="10"/>
          <path d="M13.6799 57.74C15.9099 63 19.1599 69.73 23.2399 71.82C16.1899 71.62 8.72995 73.1 0.949951 75.86C5.19995 81.91 10.5299 86.66 17.0199 90.01C11.5799 92.03 6.57992 96.45 1.88992 102.52C8.78992 106.17 15.58 108.4 22.2 108.73C16.7 113.22 12.7899 119.33 10.4899 127.06C19.2799 128.36 27.22 128.51 33.45 126.4C29.37 131.46 27.0899 137.81 26.1799 145.15C36.0399 145.32 44.9399 144.32 51.9599 141.01C52.1899 146.96 53.4699 153.92 57.1699 159.74C62.6299 158.25 68.9999 154.4 70.9599 149.13C73.2399 156.71 81.7799 162.55 84.2499 162.78C91.4499 157.73 102.23 152.33 104.38 145.69C106.92 152.11 110.1 150.18 116.17 154.44C122.28 149.19 127.06 147.18 125.93 143.1C131.9 147.31 138.31 149.61 145.19 149.9C147.82 146.54 150.93 140.16 149.39 135.99C154.39 140.57 160 144.5 167.06 144.61C169.49 139.88 168.57 132.29 168.24 127.46C174.5 127.59 179.93 125.68 184.68 122.08C183.57 116.29 180.48 111.63 175.99 107.77C181.57 106.08 186.36 102.63 190.49 97.75C187.28 92.8 181.79 89.73 175.02 87.73C180.87 82.94 184.84 77.03 186.68 69.87C179.23 67.3 172.45 67.16 166.32 69.35C167.14 63.16 166.11 57.12 163.07 51.24C157.2 52.24 152.34 54.46 148.94 58.48C146.78 50.42 142.93 43.49 136.96 37.96C131.62 42.92 127.41 47.95 125.56 53.13C121.68 46.44 116.2 40.34 109.4 34.72C104.5 39.41 100.98 44.02 99.6199 48.49C95.3899 42.61 89.7699 38.36 82.3699 36.23C79.0699 40.78 76.9999 45.19 76.6299 49.4C72.9099 43.08 68.2 38.67 61.0099 36.52C55.7799 40 53.1299 46.8 51.7099 51.55C45.9399 48.55 38.7199 42.93 32.4699 43.08C31.1399 48.89 29.7899 58.09 33.5599 62.52C26.9599 60.61 20.1999 56.89 13.6499 57.73L13.6799 57.74Z" fill="#EF811A" stroke="#000100" stroke-width="1.19" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M55.1 87.04C54.6 92.25 49.98 96.07 44.77 95.57C39.56 95.07 35.74 90.45 36.24 85.24L55.1 87.04Z" fill="white" stroke="#000100" stroke-width="1.19" stroke-miterlimit="10"/>
          <path d="M50.38 86.59C50.13 89.19 47.82 91.1 45.21 90.86C42.61 90.61 40.7 88.3 40.94 85.69L50.37 86.59H50.38Z" fill="#000100"/>
          <path d="M83.28 89.73C82.78 94.94 78.16 98.76 72.95 98.26C67.74 97.76 63.92 93.14 64.42 87.93L83.28 89.73Z" fill="white" stroke="#000100" stroke-width="1.19" stroke-miterlimit="10"/>
          <path d="M78.56 89.28C78.31 91.88 76 93.79 73.39 93.55C70.79 93.3 68.88 90.99 69.12 88.38L78.55 89.28H78.56Z" fill="#000100"/>
          <path d="M61.29 106.19C61.29 106.19 65.97 105.04 69.83 105.99" stroke="#000100" stroke-width="1.19" stroke-miterlimit="10"/>
          <path d="M50.6099 71.17C47.3899 65.75 42.8599 61.63 36.6099 59.21C33.3999 62.9 31.2599 66.56 30.6199 70.19" stroke="#000100" stroke-width="1.19" stroke-miterlimit="10"/>
          <path d="M72.95 73.3C77.13 68.58 82.3699 65.4 88.9599 64.21C91.4099 68.44 92.8199 72.44 92.7699 76.12" stroke="#000100" stroke-width="1.19" stroke-miterlimit="10"/>
        </svg>
      </div>
    </div>
    <div class="m-home-banner__col m-home-banner__apps">
      <MHomeApps
        @click.native="onBannerClick"
        :title="title"
        :description="description"
      />
    </div>
  </div>
</template>

<script>
import MHomeApps from './m-home-apps'
import GoogleTagManager from 'theme/mixins/gtm'

export default {
  name: 'MLoyaltyAppBanner',
  components: { MHomeApps },
  mixins: [GoogleTagManager],
  data () {
    return {
      apps: ['apple', 'google']
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  methods: {
    createPayload (extraProps = {}) {
      return [{
        item_name: 'varus_in_pocket',
        item_id: 'varus_in_pocket',
        item_category: 'item_category',
        promotion_id: 'varus_in_pocket',
        promotion_name: 'varus_in_pocket',
        creative_name: 'creative_name',
        creative_slot: 'creative_slot',
        location_id: 'varus_in_pocket',
        index: 'index',
        quantity: 1,
        price: 'price',
        ...extraProps
      }];
    },
    onBannerClick () {
      this.gtmBannerHandler(this.createPayload(), 'select_promotion')
    },
    onBannerShow () {
      const payload = this.createPayload({
        item_brand: 'item_brand'
      })
      this.gtmBannerHandler(payload, 'view_promotion')
    }
  },
  mounted () {
    this.onBannerShow()
  }
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.m-home-banner {
  display: flex;
  padding: 0;
  background: var(--violet);
  max-width: var(--max-width);
  margin: var(--spacer-50) var(--spacer-10) 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: $tablet-min) {
    flex-direction: row;
    margin: var(--spacer-60) var(--spacer-sm);
    justify-content: space-between;
    min-height: 220px;
    max-width: calc(var(--max-width) - (2 * var(--spacer-sm)));
    padding-left: var(--spacer-lg);
  }

  @media (min-width: $desktop-min) {
    flex-direction: row;
    margin: var(--spacer-2xl) auto;
    padding: 0 var(--spacer-lg) 0 var(--spacer-100);
  }

  &__col {
    flex: 1 0 auto;
    z-index: 0;

    &:not(.m-home-banner__apps) {
      order: 1;

      @media (min-width: $tablet-min) {
        order: inherit;
        margin-top: auto;
      }
    }
  }

  &__image {
    display: flex;
    margin: 0 auto;
    position: relative;
    z-index: 0;

    @media (min-width: $tablet-min) {
      height: px2rem(210);
    }

    &-wrap {
      padding: 0;
      position: relative;
      display: flex;
      margin: 0 auto;
      z-index: 0;
    }

    &-left {
      position: absolute;
      left: 0;
      z-index: -1;
      bottom: 0;
    }

    &-right {
      right: 2%;
      position: absolute;
      bottom: 0;
      display: block;
    }
  }

  &__apps {
    padding: var(--spacer-20) 0 var(--spacer-40) 0;
    text-align: center;
    z-index: 0;
    margin-top: 0;

    @media (min-width: $tablet-min) {
      text-align: start;
      padding: 0 0 2.875rem var(--spacer-xs);
      margin-top: auto;
    }

    @media (min-width: $desktop-min) {
      text-align: start;
      padding: 0 0 2.313rem 0;
    }
  }
}
</style>
