<template>
  <div
    id="tender-page"
    class="tender-page"
  >
    <m-breadcrumbs />
    <json-ld-breadcrumb />
    <div
      v-show="isContentAvailable"
      id="one-page-tender"
      class="one-page-tender"
    >
      <div class="one-page-tender__overview">
        <header class="sf-heading title">
          <h1 class="sf-heading__title sf-heading__title--h3">
            {{ getName }}
          </h1>
        </header>
        <div class="one-page-tender__time">
          {{ $t('Deadline for applications') }}: {{ getDeadline }}
        </div>
      </div>
      <div class="one-page-tender__content">
        <div v-html="getDescription" />
        <div class="one-page-tender__content-contact">
          <p class="one-page-tender__content-contact--icons one-page-tender__content-contact--name">
            {{ $t('Responsible') }}: {{ getRespName }}
          </p>
          <a
            :href="'tel:'+ getPhone"
            class="one-page-tender__content-contact--icons one-page-tender__content-contact--phone"
          >
            {{ getPhone }}
          </a>
          <a :href="'mailto:'+ getEmail"
             class="one-page-tender__content-contact--icons one-page-tender__content-contact--email"
          >
            {{ getEmail }}
          </a>
        </div>
      </div>
      <SfLink
        :link="'/tenders'"
      >
        <div class="one-page-tender__banner">
          <p class="one-page-tender__banner-text">
            {{ $t('View other tenders') }}
          </p>
          <img src="/assets/tenders.png" alt="">
        </div>
      </SfLink>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SfLink } from '@storefront-ui/vue';
import MBreadcrumbs from 'theme/components/molecules/m-breadcrumbs';
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb';
import i18n from '@vue-storefront/i18n';
export default {
  name: 'TenderPage',
  components: {
    MBreadcrumbs,
    JsonLdBreadcrumb,
    SfLink
  },
  computed: {
    ...mapGetters({
      getTendersCurrent: 'tenders/getTendersCurrent'
    }),
    isContentAvailable () {
      return this.getTendersCurrent[0] !== undefined
    },
    getDescription () {
      return this.getTendersCurrent[0] !== undefined ? this.getTendersCurrent[0].description : '';
    },
    getName () {
      return this.getTendersCurrent[0] !== undefined ? this.getTendersCurrent[0].name : '';
    },
    getPhone () {
      return this.getTendersCurrent[0] !== undefined ? this.getTendersCurrent[0].phone : '';
    },
    getEmail () {
      return this.getTendersCurrent[0] !== undefined ? this.getTendersCurrent[0].email : '';
    },
    getRespName () {
      return this.getTendersCurrent[0] !== undefined ? this.getTendersCurrent[0].responsible_name : '';
    },
    getDeadline () {
      if (this.getTendersCurrent[0] !== undefined) {
        const dateParts = this.getTendersCurrent[0].tender_deadline.split('-')
        let day = dateParts[2].split(' ')[0]
        let month = dateParts[1]
        let year = dateParts[0]
        return `${day}.${month}.${year}`
      } else {
        return ''
      }
    }
  },
  async created () {
    await this.$store.dispatch('tenders/fetchTendersCurrent', { route: this.$route });
    this.setBreadcrumbs();
  },
  async asyncData ({ store }) {
    await Promise.all([
      store.dispatch('ui/loadCatalogMenu'),
      store.dispatch('config-varus/get', { path: ['header_logo_src'] }),
      store.dispatch('promoted/updatePreHeaderBanners')
    ])
  },
  methods: {
    setBreadcrumbs () {
      const routes = [
        {
          name: i18n.t('Tenders'),
          route_link: this.localizedRoute('/tenders')
        }
      ]

      this.$store.dispatch('breadcrumbs/set', {
        current: this.getTendersCurrent[0]?.name,
        routes: routes
      }, { root: true })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@storefront-ui/shared/styles/helpers/breakpoints';
  @import "~theme/css/breakpoints";
  @import "~theme/css/px2rem";
  @import '~theme/css/fonts';
  @import "~theme/css/mixins";

  .tender-page {
    max-width: var(--max-width);
    margin: 0 auto;

    ::v-deep {
      .content {
        padding: 0;

        & > .sf-heading {
          display: none;
        }
      }

      .one-page-tender {
        max-width: 990px;
        margin: 0 auto;
        padding: 0 var(--spacer-10);

        @include for-desktop {
          padding: 0;
        }

        .title {
          text-align: left;

          .sf-heading__title {
            @include header-title;
          }
        }

        &__overview {
          max-width: 790px;
          text-align: left;
        }

        &__time {
          font-weight: var(--font-normal);
          font-size: var(--font-size-14);
          line-height: var(--font-size-17);
          color: var(--dark-gray);
          position: relative;
          padding-left: var(--spacer-20);
          margin: var(--spacer-10) auto 0;

          @include for-desktop {
            margin: var(--spacer-20) auto 0;
          }

          @include font-icon(var(--icon-clock));

          &:before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            color: var(--black);
            font-size: var(--font-size-13);
          }
        }

        &__content {
          border: 1px solid var(--line-gray);
          padding-left: 1rem;
          display: flex;
          margin-top: var(--spacer-20);
          margin-bottom: var(--spacer-30);
          flex-direction: column;

          @include for-desktop {
            flex-direction: row;
            margin-top: var(--spacer-40);
            margin-bottom: var(--spacer-80);
          }

          > div {
            font-weight: var(--font-normal);
            font-size: var(--font-size-14);
          }

          .one-page-tender__content {
            margin-top: 0;
            margin-bottom: 0;
            border: none;

            @include for-desktop {
              margin-top: 0;
              margin-bottom: 0;
            }
          }

          &-text {
            position: relative;
            padding: var(--spacer-20);

            @include for-desktop {
              padding: var(--spacer-50) var(--spacer-85) var(--spacer-50) var(--spacer-50);
            }

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              height: px2rem(1);
              width: 100%;
              background: var(--line-gray);
              left: 0;

              @include for-desktop {
                height: 100%;
                width: px2rem(1);
                top: 0;
                right: 0;
                left: unset;
              }
            }

            &-item {

              &--title {
                color: var(--black);
                font-weight: var(--font-medium);
                font-size: var(--font-size-20);
                line-height: var(--font-size-20);
                margin-bottom: var(--spacer-15);
                margin-top: var(--spacer-40);
              }

              &--description {
                color: var(--black);
                font-weight: var(--font-normal);
                font-size: var(--font-size-14);
                line-height: var(--font-size-17);
              }
            }

            &--title {
              margin: 0 0 var(--spacer-15);
              font-weight: var(--font-medium);
              font-size: var(--font-size-20);
              line-height: var(--font-size-24);

              @include for-desktop {
                line-height: var(--font-size-20);
              }
            }

            &--subtitle {
              margin: 0;
              font-weight: var(--font-normal);
              font-size: var(--font-size-14);
              line-height: var(--font-size-17);
            }
          }

          &-contact {
            padding: var(--spacer-20);

            @include for-desktop {
              padding: var(--spacer-50) var(--spacer-50) 0 var(--spacer-40);
            }

            &--icons {
              color: var(--black);
              position: relative;
              padding-left: var(--spacer-22);
              margin-top: 0;
              margin-bottom: var(--spacer-20);

              @include for-desktop {
                white-space: nowrap;
              }

              &:before {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                color: var(--black);
              }
            }

            &--name {
              font-weight: var(--font-normal);
              font-size: var(--font-size-14);
              line-height: var(--font-size-17);

              @include font-icon(var(--icon-user));

              &:before {
                font-size: var(--font-size-16);
              }
            }

            &--phone {
              display: inline-block;
              font-weight: var(--font-medium);
              font-size: var(--font-size-15);
              line-height: var(--font-size-18);

              @include font-icon(var(--icon-phone));

              &:before {
                font-size: var(--font-size-12);
              }
            }

            &--email {
              font-weight: var(--font-normal);
              font-size: var(--font-size-14);
              line-height: var(--font-size-17);
              color: var(--orange);
              margin-bottom: 0;
              display: flex;
              align-items: center;

              &:hover {
                color: var(--orange-pressed);
              }

              @include font-icon(var(--icon-location));

              &:before {
                font-size: var(--font-size-13);
              }
            }
          }
        }

        &__banner {
          min-height: px2rem(141);
          background: linear-gradient(94.39deg, #FF8A2F 12.72%, #DE5B27 90.6%);
          margin-bottom: var(--spacer-50);
          position: relative;

          @include for-desktop {
            min-height: px2rem(170);
            margin-bottom: var(--spacer-100);
          }

          &-text {
            padding: 35px 29px;
            font-weight: var(--font-medium);
            color: var(--white);
            max-width: px2rem(140);
            font-size: var(--font-size-20);
            line-height: var(--font-size-24);

            @include for-desktop {
              padding-top: var(--spacer-50);
              padding-left: 77px;
              font-size: var(--font-size-32);
              line-height: var(--font-size-33);
              max-width: px2rem(290);
            }
          }

          img {
            position: absolute;
            top: 0;
            right: 0;
            filter: drop-shadow(0 0 70px var(--white));

            @include for-desktop {
              top: 0;
              right: 15%;
            }
          }
        }
      }
    }
  }
</style>
