<template>
  <div id="thank-you-page">
    <div
      v-if="dataIsLoaded"
      class="thank-you-page"
    >
      <div class="thank-you-page__order-info">
        <div class="thank-you-page__order-info-item">
          <OMainInfo
            :order-number="confirmation.orderNumber"
          />
        </div>
        <div class="thank-you-page__order-info-item">
          <OPersonalDetailsInfo
            :personal-details="personalDetails"
          />
        </div>
        <div class="thank-you-page__order-info-item">
          <OShippingInfo
            :order="order"
            :shipping="shipping"
          />
        </div>
        <div
          v-if="!isNewPost"
          class="thank-you-page__order-info-item"
        >
          <OTimeSlotsInfo
            :order="order"
            :shipping-time-slots="shippingTimeSlots"
          />
        </div>
        <div
          v-if="!isDarkstore && !isNewPost"
          class="thank-you-page__order-info-item"
        >
          <OPackageInfo
            v-if="!isDarkstore"
            :order="order"
            :shipping="shipping"
          />
        </div>
        <div class="thank-you-page__order-info-item">
          <OPaymentInfo
            :payment-methods="paymentMethods"
            :payment-details="paymentDetails"
          />
        </div>
      </div>
      <div class="thank-you-page__totals">
        <OTotals
          :is-large="true"
          :summary="summary"
          :tips="tips"
          :is-new-post="isNewPost"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OMainInfo from 'theme/components/organisms/thank-you-page/o-main-info'
import OPackageInfo from 'theme/components/organisms/thank-you-page/o-package-info'
import OPaymentInfo from 'theme/components/organisms/thank-you-page/o-payment-info'
import OPersonalDetailsInfo from 'theme/components/organisms/thank-you-page/o-personal-details-info'
import OShippingInfo from 'theme/components/organisms/thank-you-page/o-shipping-info'
import OTimeSlotsInfo from 'theme/components/organisms/thank-you-page/o-time-slots-info'
import OTotals from 'theme/components/organisms/thank-you-page/o-totals'
import { metaPages } from '../meta/pages'
import { mapActions, mapGetters } from 'vuex'
import { getCheckoutSessionItemByKey } from 'theme/store/checkout/helpers/session'
import { isServer } from '@vue-storefront/core/helpers'
import GoogleTagManager from 'theme/mixins/gtm'
import { eSputnikEvent } from 'theme/helpers/es';
import { newPostMethods } from '$modules/shipping/config';

export default {
  data () {
    return {
      order: null,
      confirmation: null,
      summary: null,
      tips: null,
      toCheckout: null,
      shipping: null,
      shippingTimeSlots: null,
      paymentMethods: null,
      paymentDetails: null,
      personalDetails: null,
      isOrderLoaded: false
    }
  },
  name: 'ThankYouPage',
  components: {
    OMainInfo,
    OPackageInfo,
    OPaymentInfo,
    OPersonalDetailsInfo,
    OShippingInfo,
    OTimeSlotsInfo,
    OTotals
  },
  mixins: [GoogleTagManager],
  async asyncData ({ store }) {
    await store.dispatch('ui/loadCatalogMenu')
    await store.dispatch('config-varus/get', { path: ['header_logo_src'] })
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) next();
    else if (from.name) {
      next(async vm => {
        vm.$nextTick(() => {
          vm.isThankYouPageGtm(vm.order, vm.summary, vm.confirmation, vm.toCheckout, vm.tips)
          vm.isThankYouPageGtmAds(vm.order, vm.summary, vm.tips)
          vm.admitadThankYouPage(vm.order, vm.summary, vm.confirmation)
        })
      });
    } else {
      next();
    }
  },
  mounted () {
    this.initPageData()
    this.eSputnikActions()
    this.afterPlaceOrderClear()
  },
  methods: {
    ...mapActions({
      afterPlaceOrderClear: 'checkout/afterPlaceOrderClear'
    }),
    initPageData () {
      this.order = this.getOrderInformationFromTheSession()
      this.confirmation = this.getConfirmationInfoFromTheSession()
      this.summary = this.getSummaryFromTheSession()
      this.tips = this.getTipsFromTheSession()
      this.toCheckout = this.getToCheckoutFromTheSession()
      this.shipping = this.getShippingFromTheSession()
      this.shippingTimeSlots = this.getShippingTimeSlotsFromTheSession()
      this.paymentMethods = this.getPaymentMethodsFromTheSession()
      this.paymentDetails = this.getPaymentDetailsFromTheSession()
      this.personalDetails = this.getPersonalDetailsFromTheSession()
    },
    getOrderInformationFromTheSession () {
      return getCheckoutSessionItemByKey('placedOrder')
    },
    getConfirmationInfoFromTheSession () {
      return getCheckoutSessionItemByKey('orderConfirmation')
    },
    getSummaryFromTheSession () {
      return getCheckoutSessionItemByKey('orderSummary')
    },
    getTipsFromTheSession () {
      return getCheckoutSessionItemByKey('tips')
    },
    getToCheckoutFromTheSession () {
      return getCheckoutSessionItemByKey('toCheckout')
    },
    getShippingFromTheSession () {
      return getCheckoutSessionItemByKey('placedOrderShippingInformation')
    },
    getShippingTimeSlotsFromTheSession () {
      return getCheckoutSessionItemByKey('placedOrderShippingTimeSlots')
    },
    getPaymentMethodsFromTheSession () {
      return getCheckoutSessionItemByKey('placedOrderPaymentMethods')
    },
    getPaymentDetailsFromTheSession () {
      return getCheckoutSessionItemByKey('placedOrderPaymentDetails')
    },
    getPersonalDetailsFromTheSession () {
      return getCheckoutSessionItemByKey('placedOrderPersonalDetails') // add to session after place order
    },
    eSputnikActions () {
      eSputnikEvent('PurchasedItems', {
        productsInCart: this.productsInCart,
        currentCartHash: this.currentCartHash,
        orderNumber: this.confirmation?.orderNumber
      })
    }
  },
  computed: {
    ...mapGetters({
      productsInCart: 'cart/getCartItems',
      currentCartHash: 'cart/getCurrentCartHash'
    }),
    dataIsLoaded () {
      return !!(this.order && this.confirmation && this.summary && this.shipping && this.shippingTimeSlots &&
        this.paymentMethods && this.paymentDetails && this.personalDetails);
    },
    isDarkstore () {
      return Boolean(+this.shipping?.shop?.is_darkstore)
    },
    isNewPost () {
      return newPostMethods.includes(this.order?.addressInformation?.shipping_method_code?.toUpperCase())
    }
  },
  metaInfo: metaPages
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#thank-you-page {
  box-sizing: border-box;

  @media only screen and (min-width: $desktop-min) {
    padding: 0 var(--spacer-10);
    max-width: 1210px;
    margin: 0 auto;
  }
}

.thank-you-page {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $desktop-min) {
    padding: 0 100px;
  }

  &__order-info {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $tablet-min) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &-item {
      box-sizing: border-box;

      @media only screen and (min-width: $tablet-min) {
        flex: 0 0 50%;
      }

      &:nth-child(odd) {
        @media only screen and (min-width: $tablet-min) {
          border-right: 1px solid var(--light-gray);
        }

        @media only screen and (min-width: $desktop-min) {
          padding: 0 15px 0 0;
        }
      }

      &:nth-child(even) {
        @media only screen and (min-width: $desktop-min) {
          padding: 0 0 0 15px;
        }
      }
    }
  }

  &__totals {
    display: flex;
    padding: 25px 0 45px;

    @media only screen and (min-width: $tablet-min) {
      justify-content: flex-end;
    }
  }
}
</style>
