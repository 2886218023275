<template>
  <div class="shp-area-map">
    <div class="shp-area-map__wrapper">
      <l-map
        v-if="!loadingShippingModule"
        ref="map"
        :options="mapOptions"
        :zoom="mapZoom"
        :center="mapCenter"
        @ready="onMapReady"
        @update:center="setCenter"
        @update:zoom="setZoom"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />

        <template v-if="draft.method === 'PICKUP'">
          <l-marker
            v-if="shopSelected"
            :ref="`shop-native-current`"
            :lat-lng="{ lon: shopSelected.long, lat: shopSelected.lat }"
            :icon="icon.shopActiveIcon"
          >
            <l-popup>
              <div class="shp-area-map__popup-title">
                {{ $t(draft.method) }}
              </div>

              <div class="shp-area-map__popup-description">
                {{ shopSelected.address }}
              </div>
            </l-popup>
          </l-marker>

          <template v-for="group of pickupGroups">
            <template
              v-for="item of group.list"
              v-if="draft.type === group.type"
            >
              <l-marker
                v-if="!shopSelected || (shopSelected.id !== item.id)"
                :key="`shop-${group.type}-${item.id}`"
                :lat-lng="{ lon: item.long, lat: item.lat }"
                :icon="icon.shopIcon"
                @click="popupOpen({
                  shopId: item.id,
                  type: group.type
                })"
              >
              </l-marker>
            </template>
          </template>
        </template>

        <template v-if="isNovaCityReady && !isNovaDelivery">
          <l-marker
            v-if="npShopSelected"
            :ref="`shop-nova-current`"
            :key="`shop-nova-current`"
            :lat-lng="{ lon: npShopSelected.longitude, lat: npShopSelected.latitude }"
            :icon="icon.npActiveIcon"
          >
            <l-popup>
              <div class="shp-area-map__popup-description">
                {{ npShopSelected.short_address }}
              </div>
            </l-popup>
          </l-marker>

          <v-marker-cluster>
            <l-marker
              v-for="item of npShopList"
              v-if="!npShopSelected || (npShopSelected.id !== item.id)"
              :key="`shop-nova-${item.id}`"
              :lat-lng="{ lon: item.longitude, lat: item.latitude }"
              :icon="icon.npIcon"
              @click="popupOpen({
                  shopId: item.id,
              })"
            ></l-marker>
          </v-marker-cluster>
        </template>
      </l-map>

      <div
        v-if="draft.method === config.codes.delivery || isNovaDelivery"
        class="shp-area-map__delivery"
      >
        <div class="shp-area-map__block">
          <div
            class="shp-area-map__block-popup"
            :class="{'shp-area-map__block-popup--show': loadingDelivery || activeDelivery}"
          >
            <ASmoothReflow>
              <ShippingLoading
                v-if="loadingDelivery"
                class="shp-area-map__block-popup-padding"
              />
              <div
                v-else
                class="shp-area-map__block-popup-padding"
              >
                <template v-if="draft.address">
                  {{ draft.address.label }}
                </template>
              </div>

            </ASmoothReflow>
          </div>

          <div class="shp-area-map__pointer">
            <img
              class="shp-area-map__pointer-image"
              src="/assets/map/pointer-delivery-active.svg"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="shp-area-map__zoom">
      <button
        class="shp-area-map__zoom-in"
        :disabled="zoom === mapOptions.maxZoom"
        @click="zoomIn"
      ></button>
      <button
        class="shp-area-map__zoom-out"
        :disabled="zoom === mapOptions.minZoom"
        @click="zoomOut"
      ></button>
    </div>

    <transition name="fade">
      <button v-if="locateError" class="shp-area-map__locate-error" @click="clearDeliveryError">
        {{ $t('Location error') }}
      </button>
    </transition>

    <div class="shp-area-map__locate">
      <button v-if="!loadingUserLocate" class="shp-area-map__locate-button" @click="requestGeolocation"></button>
      <ShippingLoading
        v-if="loadingUserLocate"
        class="shp-area-map__block-popup-padding"
        :absolute="true"
      />
    </div>

    <template
      v-if="!loadingShippingModule">
      <div
        v-if="isNova && !isNovaCityReady || loadingNewPost"
        class="shp-area-map__overlay"
      >
        <ShippingLoading
          v-if="loadingNewPost"
          :absolute="true"
        />
        <template v-else>
          {{ $t('Enter locality city ' + draft.npType) }}
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import ShippingMapMixin from '$modules/shipping/mixins/shipping-map-mixin';
import ShippingLoading from '$modules/shipping/components/shipping-loading.vue';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

export default {
  name: 'ShippingMap',
  components: {
    ShippingLoading,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    ASmoothReflow,
    'v-marker-cluster': Vue2LeafletMarkerCluster
  },
  mixins: [ShippingMapMixin],
  methods: {
    requestGeolocation () {
      this.$emit('request-geolocation')
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import "~theme/css/px2rem";
@import '~theme/css/fonts';
@import '../styles/leaflet.css';

.shp-area-map {
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;

  &__wrapper {
    position: absolute;
    height: 100vh;
    width: 100vw;

    > * {
      width: 100%;
      height: 100%;
    }

    @include for-desktop {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &__delivery {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__pointer {
    padding-bottom: 34px;
    position: relative;
    width: 29px;
    height: 34px;

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    &-popup {
      position: absolute;
      bottom: 75px;
      background: var(--white);
      border-radius: 6px;
      display: inline-flex;
      flex-direction: column;
      font-size: 12px;
      opacity: 0;
      max-width: 315px;
      margin-left: 20px;
      margin-right: 20px;
      text-align: center;
      transition: opacity 200ms;

      &--show {
        opacity: 1;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      &--error {
        color: var(--red-main);
      }

      &-padding {
        padding: 10px;
      }
    }
  }

  &__locate {
    position: absolute;
    right: 24px;
    bottom: 24px;
    background: var(--white);
    border-radius: 8px;
    z-index: 9990;
    height: 48px;
    width: 48px;
    overflow: hidden;

    button {
      background: var(--white);
    }
  }

  &__locate-error {
    position: absolute;
    z-index: 9990;
    background: var(--notification-error-background);
    color: var(--red-main);
    padding: var(--spacer-16);
    font-size: 14px;
    border-radius: 8px;
    margin-left: 24px;
    right: 96px;
    bottom: 24px;
    min-height: 48px;
    border: none;
    cursor: pointer;
  }

  &__locate-button {
    @include font-icon(var(--icon-location));
    cursor: pointer;

    &:before {
      color: var(--orange);
      font-size: 20px;
    }
  }

  ::v-deep {
    .leaflet-popup-tip-container,
    .leaflet-popup-close-button {
      display: none;
    }

    .leaflet-popup-content-wrapper {
      border-radius: 6px;
      border: none;
      box-shadow: none;
      width: 100%;
    }

    .leaflet-popup-content {
      margin: 6px 8px;
    }
  }

  &__popup {
    &-title {
      color: var(--dark-gray);
    }
  }

  .shp-area-map__zoom {
    border: none;
    border-radius: 8px;
    background: #fff;
    overflow: hidden;
    position: absolute;
    z-index: 9990;
    right: 24px;
    bottom: 100px;
    display: flex;
    flex-direction: column;
  }

  ::v-deep {
    .shp-area-map__zoom-in,
    .shp-area-map__zoom-out,
    .shp-area-map__locate-button {
      transition: opacity 200ms;
      width: 48px;
      height: 48px;
      border: none;
      position: relative;
      content: ' ';
      color: transparent;
      background: var(--white);

      &:disabled {
        opacity: .5;
        pointer-events: none;
        background: none;
      }

      &:before {
        transition: color 200ms;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover:before {
        color: var(--orange)
      }
    }

    .shp-area-map__zoom-in {
      @include font-icon(var(--icon-plus));

      &:before {
        color: var(--black);
        font-size: 15px;
      }
    }

    .shp-area-map__zoom-out {
      @include font-icon(var(--icon-minus));

      &:before {
        color: var(--black);
        font-size: 15px;
      }
    }
  }

  ::v-deep {
    .marker-cluster-small {
      background-color: rgba(181, 226, 140, 0.6);
    }
    .marker-cluster-small div {
      background-color: rgba(110, 204, 57, 0.6);
    }

    .marker-cluster-medium {
      background-color: rgba(241, 211, 87, 0.6);
    }
    .marker-cluster-medium div {
      background-color: rgba(240, 194, 12, 0.6);
    }

    .marker-cluster-large {
      background-color: rgba(253, 156, 115, 0.6);
    }
    .marker-cluster-large div {
      background-color: rgba(241, 128, 23, 0.6);
    }

    /* IE 6-8 fallback colors */
    .leaflet-oldie .marker-cluster-small {
      background-color: rgb(181, 226, 140);
    }
    .leaflet-oldie .marker-cluster-small div {
      background-color: rgb(110, 204, 57);
    }

    .leaflet-oldie .marker-cluster-medium {
      background-color: rgb(241, 211, 87);
    }
    .leaflet-oldie .marker-cluster-medium div {
      background-color: rgb(240, 194, 12);
    }

    .leaflet-oldie .marker-cluster-large {
      background-color: rgb(253, 156, 115);
    }
    .leaflet-oldie .marker-cluster-large div {
      background-color: rgb(241, 128, 23);
    }

    .marker-cluster {
      background-clip: padding-box;
      border-radius: 20px;
    }
    .marker-cluster div {
      width: 30px;
      height: 30px;
      margin-left: 5px;
      margin-top: 5px;

      text-align: center;
      border-radius: 15px;
      font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
    }
    .marker-cluster span {
      line-height: 30px;
    }

    .leaflet-cluster-anim .leaflet-marker-icon, .leaflet-cluster-anim .leaflet-marker-shadow {
      -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
      -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
      -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
      transition: transform 0.3s ease-out, opacity 0.3s ease-in;
    }

    .leaflet-cluster-spider-leg {
      /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
      -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
      -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
      -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
      transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
    }
  }

  &__overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,.8);
    padding: var(--spacer-20);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9991;
    text-align: center;
  }
}
</style>
