<template>
  <div class="shp-area">
    <div class="shp-area__sections">
      <h1>{{ $t('Shipping address') }}</h1>

      <div class="m-locate-switcher__row shp-area__methods">
        <div
          v-if="getShippingMethods"
          class="radio-switcher-square shp-area__methods-input"
        >
          <SfRadio
            v-for="method in getShippingMethods"
            :key="method.key"
            :selected="getDraft.method"
            :value="method.key"
            :disabled="!hasMethods[method.key]"
            name="shipping-method"
            class="radio-switcher-square__input"
            @input="setShippingMethod"
          >
            <template #label>
              <div class="sf-radio__label radio-switcher-square__label">
                <div>{{ $t(method.method_title) }}</div>
              </div>
            </template>
          </SfRadio>
        </div>

        <template v-if="isVarusShipping">
          <MInputAutocomplete
            v-if="getDraft.city"
            :highlight="true"
            :selected="selectedCity"
            :data-list="cityListByType"
            :label="$t(cityLabel)"
            :label-top="$t(cityLabel)"
            :message="$t('Enter the settlement from the list of results')"
            :property="getDraft.method === config.codes.pickup ? 'name' : 'deliveryName'"
            class="shp-area__city"
            @set-value="setShippingCity"
          />

          <ShippingAreaPickup
            v-if="getDraft.method === config.codes.pickup"
            :config="config"
            :draft="getDraft"
            :pickup-groups="pickupGroups"
            :initialized="initialized"
            @set-shop="setShopHandler"
            @set-type="setTypeHandler"
          />

          <ShippingAreaDelivery
            v-if="getDraft.method === config.codes.delivery"
            :draft="getDraft"
            :address-list="addressList"
            :loading-delivery="loading.delivery"
            :loading-delivery-input="loading.deliveryInput"
            :error="error"
            @set-address="setAddressHandler"
            @set-type="setTypeHandler"
            @autocomplete="loadAddressList"
          />
        </template>

        <ShippingAreaNova
          v-if="getDraft.method === config.codes.newPost"
          :config="config"
          :draft="getDraft"
          :address-list="addressList"
          :loading-delivery="loading.delivery"
          :loading-delivery-input="loading.deliveryInput"
          :loading-new-post="loading.newPost"
          :loading-new-post-input="loading.newPostInput"
          :is-new-post-overweight="isNewPostOverweight"
          :is-cart-has-alcohol="isCartHasAlcohol"
          :error="error"
          @set-address="setAddressHandler"
          @set-type="setNewPostType"
          @set-city="setNovaCity"
          @set-shop="setNovaShop"
          @autocomplete-city="autocompleteNewPost"
          @autocomplete="loadAddressList"
        />
      </div>

      <ShippingSave
        v-if="isDesktop"
        :is-valid="isDraftValid"
        :is-loading="loading.saveShipping"
        :is-new-post-overweight="isNewPostOverweight"
        :error="error"
        @save="saveShippingHandler"
      />
    </div>

    <div class="shp-area__map">
      <ShippingMap
        :config="config"
        :draft="getDraft"
        :loading-shipping-module="getShippingModuleLoading"
        :loading-delivery="loading.delivery"
        :loading-new-post="loading.newPost"
        :loading-user-locate="loading.userLocate"
        :locate-error="error.locateError"
        :initialized="!getShippingModuleLoading && initialized"
        :pickup-groups="pickupGroups"
        :np-center="getNpCenter"
        :np-shop-list="getNpShopList"
        @set-np-shop="setNpShopFromMap"
        @set-shop="setShopFromMap"
        @set-type="setTypeHandler"
        @move="deliveryMapMove"
        @delivery-coordinates="setDeliveryCoordinates"
        @request-geolocation="requestGeolocation"
        @clear-locate-error="clearErrors"
      />
    </div>

    <ShippingSave
      v-if="!isDesktop"
      :is-valid="isDraftValid"
      :is-loading="loading.saveShipping"
      :is-new-post-overweight="isNewPostOverweight"
      :error="error"
      @save="saveShippingHandler"
    />

    <ALoadingSpinner
      :is-absolute-position="true"
      v-show="getShippingModuleLoading"
    />
  </div>
</template>

<script>
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';
import MInputAutocomplete from 'theme/components/molecules/m-input-autocomplete.vue';
import ShippingModuleMixin from '$modules/shipping/mixins/shipping-module-mixin';
import ShippingAreaPickup from '$modules/shipping/components/shipping-area-pickup.vue';
import ShippingAreaDelivery from '$modules/shipping/components/shipping-area-delivery.vue';
import ShippingAreaNova from '$modules/shipping/components/shipping-area-nova.vue';
import ShippingMap from '$modules/shipping/components/shipping-map.vue';
import ShippingSave from '$modules/shipping/components/shipping-save.vue';
import { SfRadio } from '@storefront-ui/vue';
import DeviceType from 'theme/mixins/DeviceType';

export default {
  name: 'ShippingArea',
  components: {
    ShippingAreaDelivery,
    ShippingAreaPickup,
    ShippingAreaNova,
    ShippingSave,
    MInputAutocomplete,
    ALoadingSpinner,
    SfRadio,
    ShippingMap
  },
  mixins: [ShippingModuleMixin, DeviceType],
  mounted () {
    this.initShippingModule()
  },
  computed: {
    selectedCity () {
      if (!this.getDraft?.city) return null

      return {
        ...this.getDraft.city,
        deliveryName: `${this.getDraft.city.name} ${this.$t('and moving')}`
      }
    }
  },
  methods: {
    setShopHandler (shop) {
      this.setDraftShop({ shop })
    },
    setTypeHandler (type) {
      this.setDraftType({ type })
    },
    async saveShippingHandler () {
      try {
        this.loading.saveShipping = true

        const saveHandlers = {
          [this.config.codes.delivery]: this.saveDeliveryShipping,
          [this.config.codes.newPost]: this.saveDeliveryNova
        }

        const handler = saveHandlers[this.getDraft.method] || this.saveBaseShipping

        await handler()
      } catch (e) {} finally {
        this.loading.saveShipping = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import "~theme/css/px2rem";

.shp-area {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  &__methods-input {
    margin-bottom: var(--spacer-15);

    ::v-deep .sf-radio__container {
      --radio-container-padding: var(--spacer-sm) var(--spacer-2xs);

      .radio-switcher-square__label {
        white-space: nowrap;
      }
    }
  }

  @include for-desktop {
    flex-direction: row;
  }

  &__sections {
    padding: 20px 15px;

    @include for-desktop {
      box-sizing: border-box;
      padding: 40px;
      flex: 0 0 493px;
      overflow: scroll;
    }

    &::-webkit-scrollbar {
      width: 13px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-left: 3px solid var(--light-orange);
      background-clip: padding-box;

      &:hover {
        border-left-color: var(--orange);
      }
    }

    h1 {
      display: none;

      @include for-desktop {
        display: block;
        margin-top: 0;
        margin-bottom: 15px;
        @include header-title;
      }
    }
  }

  &__map {
    height: 100%;

    @include for-desktop {
      flex: 1
    }
  }

  &__sections, &__map {
    @include for-desktop {
      height: 100%;
    }
  }

  &__sections {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1001;

    @include for-desktop {
      z-index: initial;
    }
  }

  ::v-deep {
    .a-loading-spinner {
      z-index: 1002;
    }
  }
}
</style>
