<template>
  <div
    class="category"
    :class="[
      {
        'category--is-empty': isProductsNotFound
      }
    ]"
  >
    <div class="category__container category__container--green">
      <div
        class="category__block"
      >
        <MBreadcrumbs />
        <JsonLdBreadcrumb />
      </div>
    </div>
    <div class="category__container category__container--with-filters category__container--green">
      <div
        class="category__block"
      >
        <CategoriesFilter
          v-if="categoriesForFilter.length > 0"
          :categories="categoriesForFilter"
          @selectCategory="selectCategory"
          @togglePizzaIngredientsFilter="togglePizzaIngredientsFilter"
          :slug="slug"
          :query="query"
        />
      </div>
    </div>
    <div class="category__container category__container--green">
      <div
        class="category__block"
      >
        <div class="page__title-wrapper">
          <SfHeading
            :level="1"
            :title="getCurrentCategoryTitle"
            class="page__title"
          />
          <div class="page__title-counter">
            <span class="page__title-label">
              {{ categoryProductsTotal }}
            </span>
          </div>
          <!--          <SfButton-->
          <!--            class="button__clear-filters"-->
          <!--            v-if="hasSelectedFilters"-->
          <!--            @click="clearFilters"-->
          <!--          >-->
          <!--            {{ $t('Reset') }}-->
          <!--          </SfButton>-->
        </div>
        <div
          class="top-banner__wrapper"
          v-if="shouldShowTopBanner"
        >
          <MBannerCarousel
            :banners="topBanners"
            :is-responsive="true"
          />
        </div>
      </div>
    </div>
    <div class="category__container category__container--green">
      <div
        class="category__block"
      >
        <div v-if="isShownNotification" class="notification__wrapper">
          <MCategoryNP
            class="category__np"
            :title="$t('NP notice title')"
            :note="$t('NP notice description')"
          />
        </div>
      </div>
    </div>
<!--    <div class="category__container category__container&#45;&#45;green">-->
<!--      <div class="category__block">-->
<!--        <FiltersPanel-->
<!--          :available-filters="availableFilters"-->
<!--          :key="Object.keys(availableFilters).length"-->
<!--          :filter-labels="filterLabels"-->
<!--          :current-filters="selectedFilters"-->
<!--          :aggregations="getAggregations"-->
<!--          :sort-order="sortOrder"-->
<!--          :sort-options="sortOptions"-->
<!--          :promo-count="getPromoCount"-->
<!--          @promo-filter-changed="promoFilterChanged"-->
<!--          @change="changeFilter"-->
<!--          @submit-filter-price="submitFilterPrice"-->
<!--          @change-sort-order="changeSortOder"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
    <div class="category__container category__container--green">
      <div class="category__block">
        <div class="main section">
          <div class="products">
            <template v-if="!isLoading && isProductsNotFound">
              <SfHeading
                class="products__not-found"
                :title="isProductNone ? $t('No products found in the catalog') : $t('No products found!')"
                :subtitle="isProductNone ? $t('Try using a different combination of filters') : $t('Please select another city or another category')"
              />
              <SfButton
                class="products__change-city"
                v-if="!isProductNone"
                data-transaction-name="Category - Change City"
                @click="openShippingModal()"
              >
                {{ $t('Change city') }}
              </SfButton>
            </template>
            <template v-else>
              <MCategoryList
                :is-loading="productsIsLoading"
                :products="productsBeforeInoBanner"
                class-list="products__grid top-grid"
                badge-label="-50%"
                badge-color="color-primary"
                :placeholder-count="cardInRow"
                :category-id="getVarusCafeCategory.id"
                :category-name="getVarusCafeCategory.name"
              />
              <div
                class="banner-info-wrap banner-info-wrap--last"
                v-if="shouldShowMiddleBanner"
                :class="{'banner-missed': !hasMiddleBanner}"
              >
                <MBannerCarousel
                  :banners="middleBanners"
                  :is-responsive="true"
                />
              </div>
              <MCategoryList
                :is-loading="productsIsLoading"
                :products="productsAfterInoBanner"
                class-list="products__grid main-grid"
                badge-label="-50%"
                badge-color="color-primary"
                :last-row-class="hasMiddleBanner"
                :placeholder-count="productsAfterInoBannerCount"
                :category-id="getVarusCafeCategory.id"
                :category-name="getVarusCafeCategory.name"
              />
              <NoSSR>
                <div
                  v-if="!isMobile"
                  class="bottom-plp-block"
                >
                  <OPagination
                    v-if="products.length && totalPages > 1"
                    :current-page="currentPage"
                    :total="totalPages"
                    :scroll-top="true"
                    :per-page="perPage"
                    :is-load-more="isAdditionalLoading"
                    @nav-clicked="onPaginationClick"
                    @load-more="onLoadMore"
                    @page-changed="onPageChanged"
                  />
                </div>
              </NoSSR>
            </template>
          </div>
          <NoSSR>
            <div
              v-if="isMobile"
              class="bottom-plp-block"
            >
              <OPagination
                v-if="products.length && totalPages > 1"
                :current-page="currentPage"
                :total="totalPages"
                :scroll-top="true"
                :per-page="perPage"
                :is-load-more="isAdditionalLoading"
                @nav-clicked="onPaginationClick"
                @load-more="onLoadMore"
                @page-changed="onPageChanged"
              />
            </div>
          </NoSSR>
        </div>
      </div>
    </div>

    <div class="category__container">
      <ASmoothReflow
        class="category-footer__wrapper category__block"
        v-intersect.once="{ handler: onIntersect, cfg: { rootMargin: '0px' }}"
      >
        <div class="category-footer">
          <template v-if="hasIntersected">
            <MRecentlyViewedProducts :show-qty-permanently="true" />
            <MAdvantages />
            <OFAQ
              v-if="questions"
              :questions="questions"
            />
            <OSection
              v-if="false"
              :title-heading="$t('Cook with VARUS')"
              class="section"
            >
              <template #link>
                <SfLink link="#" target="_blank" class="sf-link--primary">
                  {{ $t("Read recipes") }}
                </SfLink>
              </template>
              <MRecipeCarousel class="flex" />
            </OSection>
          </template>
          <MSeoBlock
            v-if="!getSeoHtml"
            :seo-html="getSeoHtml"
          />
        </div>
      </ASmoothReflow>
    </div>

    <JsonLdCategory :products-total="getCategoryProductsTotal"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import castArray from 'lodash/castArray';
import MBreadcrumbs from '../components/molecules/m-breadcrumbs';
// import config from 'config';
import {
  isServer
} from '@vue-storefront/core/helpers';
import {
  prepareCategoryProduct
} from 'theme/helpers';
import {
  SfButton,
  SfLink,
  SfHeading
} from '@storefront-ui/vue';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { eSputnikEvent } from 'theme/helpers/es';
import MSeoBlock from 'theme/components/molecules/m-seo-block';
import MAdvantages from 'theme/components/molecules/m-advantages';
import MRecentlyViewedProducts from 'theme/components/molecules/m-recently-viewed-products';
import MRecipeCarousel from 'theme/components/molecules/m-recipe-carousel';
import OSection from 'theme/components/organisms/o-section';
import MBannerCarousel from 'theme/components/molecules/m-banner-carousel';
import DeviceType from 'theme/mixins/DeviceType';
import JsonLdCategory from 'theme/components/json-ld/json-ld-category';
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb';
import MCategoryList from 'theme/components/molecules/m-category-list';
import { metaCategory, getMetaContent } from 'theme/meta/varus-cafe';
import ASmoothReflow from '../components/atoms/a-smooth-reflow';
import intersect from '../directives/intersect';
import NoSSR from 'vue-no-ssr'
import GoogleTagManager from 'theme/mixins/gtm';
import Delivery from 'theme/mixins/Delivery';
import OFAQ from 'theme/components/organisms/o-faq.vue';
import CategoriesFilter from 'theme/components/varus-cafe/categories-filter.vue';
import MCategoryNP from 'theme/components/molecules/m-category-np.vue';
// import FiltersPanel from 'theme/components/organisms/category/filters-panel.vue';

let isAlreadyLoaded = false;

const THEME_PAGE_SIZE = 40;

const composeCategoryState = async (store, route) => {
  const filters = { slug: 'varuscafe' }

  const [category] = await Promise.all([
    store.dispatch('category-next/loadCategory', { filters }),
    store.dispatch('category/loadHumanFilterMeta', { fullPath: route.fullPath })
  ])

  return category
}

const loadProducts = async (store, route, VarusCafeCategory, page = 0) => {
  await store.dispatch('varus-cafe/loadCategoryProducts', {
    route: route,
    category: VarusCafeCategory,
    pageSize: THEME_PAGE_SIZE,
    start: page ? (page - 1) * THEME_PAGE_SIZE : page
  })
}

const loadBanners = async (store, ids) => {
  return store.dispatch('varus-cafe/loadBanners', { ids })
}

const prepareIdsForBanner = ({ store, VarusCafeCategory, slug }) => {
  let ids = [VarusCafeCategory.id];

  if (slug) {
    const selectedCategory = store.getters['category/getCategoryBySlug'](slug);
    if (selectedCategory) {
      ids.push(selectedCategory.id);
    }
  }

  return ids.filter(Boolean);
}

export default {
  name: 'VarusCafe',
  components: {
    CategoriesFilter,
    JsonLdBreadcrumb,
    JsonLdCategory,
    MSeoBlock,
    MBreadcrumbs,
    SfButton,
    SfLink,
    SfHeading,
    MRecentlyViewedProducts,
    MAdvantages,
    MRecipeCarousel,
    OSection,
    MBannerCarousel,
    MCategoryList,
    ASmoothReflow,
    NoSSR,
    OFAQ,
    OPagination: () => process.browser ? import('theme/components/organisms/o-pagination') : null,
    // FiltersPanel,
    MCategoryNP
  },
  mixins: [
    DeviceType,
    GoogleTagManager,
    Delivery
  ],
  directives: { intersect },
  data () {
    return {
      selectedFilters: {},
      hasIntersected: false,
      sortOrder: 'popularity:desc',
      sliderSettings: {
        type: 'slider',
        animationDuration: 400,
        animationTimingFunc: 'ease-in-out',
        dragThreshold: false,
        perView: 1,
        gap: 20,
        focusAt: 'center',
        breakpoints: {
          320: {
            type: 'carousel',
            perView: 1.2,
            focusAt: 'center',
            gap: 10
          },
          768: {
            type: 'carousel',
            perView: 1.2,
            focusAt: 'center',
            gap: 10
          }
        }
      }
    };
  },
  computed: {
    ...mapState({
      // getAggregations: state => state['category-extension'].aggregations,
      getAttributeListByCode: state => state['attribute'].list_by_code,
      getFiltersMap: state => state['category-extension'].filtersMap,
      getDefaultShop: state => state['shipping'].defaultShop,
      categoryLoader: state => state.ui.categoryLoader,
      questions: state => state.faq.questions,
      childCategories: state => state['varus-cafe'].childCategories,
      productsIsLoading: state => state['varus-cafe'].productsIsLoading,
      getProducts: state => state['varus-cafe'].products,
      humanFilterMeta: state => state['category'].humanFilterMeta.data,
      isAdditionalLoading: state => state['varus-cafe'].isAdditionalLoading,
      productCountsByCategory: state => state['varus-cafe'].productCountsByCategory,
      getTopBanners: state => state['varus-cafe'].topBanners,
      getMiddleBanners: state => state['varus-cafe'].middleBanners,
      availableProducts: state => state['varus-cafe'].availableProducts
    }),
    ...mapGetters({
      getCurrentSearchQuery: 'varus-cafe/getCurrentSearchQuery',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getCurrentFilters: 'varus-cafe/getCurrentFilters',
      getSystemFilterNames: 'category-next/getSystemFilterNames',
      isLoggedIn: 'user/isLoggedIn',
      shippingDetails: 'shipping/getShippingDetails',
      getCategoryBySlug: 'category/getCategoryBySlug',
      isNewPost: 'shipping-module/isCurrentNewPost',
      getShops: 'shipping-module/getShopList'
    }),
    isPizzaIngridientsActive () {
      return this.query.hasOwnProperty('pizzaIngredient');
    },
    isShownNotification () {
      const isLoading = this.productsIsLoading;
      const isDarkstoreOrNewPost = this.isDarkstore || this.isNewPost;
      const noAvailableProducts = !this.availableProducts;
      const hasProducts = this.getCategoryProductsTotal > 0;

      return !isLoading && (isDarkstoreOrNewPost || noAvailableProducts) && hasProducts;
    },
    isDarkstore () {
      const tmsId = this.shippingDetails?.shop?.tms_id
      const shop = this.getShops?.find((i) => i.tms_id === tmsId) || this.shippingDetails?.shop

      return Boolean(+shop?.is_darkstore)
    },
    isRootPage () {
      return this.slug.length === 0
    },
    getCurrentCategoryTitle () {
      if (this.isRootPage) {
        return this.getVarusCafeCategory.name
      } else {
        return this.getCategoryBySlug(this.slug)?.name
      }
    },
    currentSearchQuery () {
      return this.getCurrentSearchQuery(this.$route, this.getVarusCafeCategory)
    },
    currentFilters () {
      return this.getCurrentFilters(this.$route, this.getVarusCafeCategory)
    },
    getVarusCafeCategory () {
      return this.getCategoryBySlug('varuscafe')
    },
    categoriesForFilter () {
      const categories = this.getVarusCafeCategory?.children_data || []
      if (!this.childCategories.length) return []

      const translatedCategory = {
        id: 'all-dishes',
        name: this.$t('All dishes'),
        slug: '',
        pimcore_id: null
      }

      const updatedCategories = categories.map(category => {
        if (category?.id) {
          const childCategory = this.childCategories.find(child => child.id === category.id);
          return {
            ...category,
            pimcore_id: childCategory?.pimcore_id
          }
        }
      }).filter(Boolean).sort((a, b) => a.position - b.position);

      updatedCategories.unshift(translatedCategory);

      return updatedCategories;
    },
    isProductsNotFound () {
      return (this.productsBeforeInoBanner.length === 0) || this.isCategoryEmpty
    },
    isLoading () {
      return this.categoryLoader
    },
    slug () {
      return this.$route.params?.slug ? this.$route.params.slug : ''
    },
    query () {
      return this.$route.query
    },
    // getPromoCount () {
    //   if (!this.getAggregations.hasOwnProperty('agg_terms_has_promotion_in_stores')) {
    //     return 0
    //   }
    //
    //   const buckets = this.getAggregations['agg_terms_has_promotion_in_stores']?.buckets || []
    //
    //   const shopId = this.shippingDetails?.shopId || this.getDefaultShop?.id
    //
    //   const item = buckets.find(i => +i.key === shopId)
    //
    //   return item?.doc_count || 0
    // },
    isCategoryEmpty () {
      return this.getCategoryProductsTotal === 0;
    },
    areAllProductsOutOfStock () {
      return this.products.every(product => product.sqpp.in_stock === false)
    },
    isProductNone () {
      return !this.isLoading && this.products.length === 0;
    },
    products () {
      return this.getProducts.map(prepareCategoryProduct);
    },
    cardInRow () {
      return this.isTablet ? 6 : 10
    },
    productsBeforeInoBanner () {
      return this.products.slice(0, this.cardInRow);
    },
    productsAfterInoBanner () {
      return this.products.slice(this.cardInRow, this.products.length);
    },
    hasMiddleBanner () {
      return !!this.middleBanners.length
    },
    middleBanners () {
      const hasMiddleBanners = this.getMiddleBanners.length;

      if (!hasMiddleBanners) return [];
      const categoryIds = this.selectedCategories.map(cat => cat.id);

      return this.getMiddleBanners.filter(banner => {
        return banner.category_ids.some(id => categoryIds.includes(id))
      });
    },
    hasTopBanner () {
      return !!this.topBanners.length
    },
    topBanners () {
      const hasTopBanners = this.getTopBanners.length;

      if (!hasTopBanners) return [];
      const categoryIds = this.selectedCategories.map(cat => cat.id);

      return this.getTopBanners.filter(banner => {
        return banner.category_ids.some(id => categoryIds.includes(id))
      });
    },
    productsAfterInoBannerCount () {
      return THEME_PAGE_SIZE - this.cardInRow
    },
    totalPages () {
      return Math.ceil(this.getCategoryProductsTotal / THEME_PAGE_SIZE);
    },
    shouldShowTopBanner () {
      return this.productsBeforeInoBanner.length > 0 && !this.productsIsLoading && !this.isShownNotification && !this.isPizzaIngridientsActive
    },
    shouldShowMiddleBanner () {
      return this.productsAfterInoBanner.length > 0 && !this.productsIsLoading && !this.isShownNotification && !this.isPizzaIngridientsActive
    },
    // sortOptions () {
    //   return Object.entries(config.products.sortByAttributes).map(attribute => {
    //     const [label, id] = attribute;
    //     return { id, label };
    //   });
    // },
    // availableFilters () {
    //   const filtersArray = this.getFiltersMap[this.getVarusCafeCategory?.id] || {};
    //   const { getSystemFilterNames } = this;
    //
    //   return Object.entries(filtersArray)
    //     .filter(([filterType, filters]) => filters.length && !getSystemFilterNames.includes(filterType))
    //     .reduce((result, [filterType, filters]) => {
    //       result[filterType] = filters.filter(filter => filter.id !== '0')
    //       return result;
    //     }, {});
    // },
    // filterLabels () {
    //   const attrArr = Object.values(this.getAttributeListByCode)
    //   const labels = {}
    //   attrArr.forEach(item => { labels[`${item.attribute_code}`] = item.frontend_label === undefined ? '' : item.frontend_label })
    //
    //   return labels
    // },
    isFilterActive () {
      return filter =>
        castArray(this.currentFilters[filter.type]).find(
          variant => variant && variant.id === filter.id
        ) !== undefined;
    },
    getSeoHtml () {
      return this.getVarusCafeCategory.description
    },
    categoryProductsTotal () {
      return `${this.getCategoryProductsTotal} ${this.getDishDeclension(this.getCategoryProductsTotal)}`
    },
    categoryMetaContent () {
      return getMetaContent(this.getVarusCafeCategory, this.$route, this.slug, this.humanFilterMeta)
    },
    perPage () {
      return THEME_PAGE_SIZE;
    },
    currentPage () {
      return Number(this.$route.query.page) || 1;
    },
    selectedCategories () {
      const categories = ['varuscafe', this.slug].map(slug => this.getCategoryBySlug(slug))
      return categories.filter(Boolean)
    }
    // hasSelectedFilters () {
    //   return Object.keys(this.selectedFilters).length > 0;
    // }
  },
  watch: {
    // currentFilters: {
    //   handler (val, oldVal) {
    //     if (!Object.keys(oldVal).length && !Object.keys(this.selectedFilters).length) {
    //       this.updateSelectedFiltersFromStore(val);
    //       return
    //     }
    //
    //     if (JSON.stringify(val) === JSON.stringify(oldVal)) {
    //       return
    //     }
    //
    //     this.updateSelectedFiltersFromStore(val);
    //   },
    //   deep: true
    // },
    // 'currentSearchQuery.sort': {
    //   handler (val, oldVal) {
    //     if (!oldVal && !this.sortOrder) {
    //       this.updateSortOrderFromStore(val);
    //       return
    //     }
    //
    //     if (val === oldVal) {
    //       return
    //     }
    //
    //     this.updateSortOrderFromStore(val);
    //   }
    // },
    'shippingDetails.shopId': {
      handler: async function (newValue, oldValue) {
        if (!newValue || newValue === oldValue) return

        const page = this.$route.query.page || this.$route.params.page || 0

        await this.$store.dispatch('varus-cafe/loadCategoryProducts', {
          route: this.$route,
          category: this.getVarusCafeCategory,
          pageSize: THEME_PAGE_SIZE,
          start: page ? (page - 1) * THEME_PAGE_SIZE : page
        })
      }
    }
  },
  async asyncData ({ store, route }) {
    const { params } = route
    const { slug } = params
    const isRootPage = slug === undefined

    if (isServer) {
      Promise.all([
        store.dispatch('ui/loadCatalogMenu'),
        store.dispatch('config-varus/get', { path: ['header_logo_src'] }),
        store.dispatch('promoted/updatePreHeaderBanners')
      ])

      const VarusCafeCategory = await composeCategoryState(store, route)
      const currentCategoryName = store.getters['category/getCategoryBySlug'](slug)?.name
      const childCategories = await store.dispatch('varus-cafe/loadChildCategories', {
        parentCategoryId: VarusCafeCategory.id
      })
      await Promise.allSettled([
        store.dispatch('varus-cafe/loadCategoryBreadcrumbs', {
          category: VarusCafeCategory,
          isRootPage,
          currentCategoryName
        }),
        loadBanners(store, prepareIdsForBanner({ store, VarusCafeCategory, slug })),
        store.dispatch('varus-cafe/loadCategoriesCounts', { rootCategory: VarusCafeCategory }),
        store.dispatch('varus-cafe/setChildCategories', childCategories)
      ])
    }

    if (isServer) return
    isAlreadyLoaded = true;

    try {
      const VarusCafeCategory = await composeCategoryState(store, route)
      const currentCategoryName = store.getters['category/getCategoryBySlug'](slug)?.name
      const childCategories = await store.dispatch('varus-cafe/loadChildCategories', {
        parentCategoryId: VarusCafeCategory.id
      })

      await Promise.allSettled([
        store.dispatch('varus-cafe/loadCategoryBreadcrumbs', {
          category: VarusCafeCategory,
          isRootPage,
          currentCategoryName
        }),
        loadBanners(store, prepareIdsForBanner({ store, VarusCafeCategory, slug })),
        store.dispatch('varus-cafe/loadCategoriesCounts', { rootCategory: VarusCafeCategory }),
        store.dispatch('varus-cafe/setChildCategories', childCategories),
        store.dispatch('faq/loadFAQForCategory', { id: VarusCafeCategory.id }),
        loadProducts(store, route, VarusCafeCategory, route?.query?.page || 0)
      ])
    } finally {
      store.dispatch('ui/categoryLoader', false)
      store.dispatch('varus-cafe/setProductsLoading', false)
    }
  },
  async mounted () {
    const { params } = this.$route
    const { slug } = params
    const isRootPage = slug === undefined
    const currentCategoryName = this.$store.getters['category/getCategoryBySlug'](slug)?.name

    await this.$store.dispatch('varus-cafe/loadCategoryBreadcrumbs', {
      category: this.getVarusCafeCategory,
      isRootPage,
      currentCategoryName
    })

    // if (Object.keys(this.currentFilters).length) {
    //   this.updateSelectedFiltersFromStore(this.currentFilters);
    // }
    // if (this.currentSearchQuery.sort) {
    //   this.updateSortOrderFromStore(this.currentSearchQuery.sort);
    // }

    if (isAlreadyLoaded) return;

    try {
      await loadProducts(this.$store, this.$route, this.getVarusCafeCategory, this.$route?.query?.page || 0)
    } finally {
      this.$store.dispatch('ui/categoryLoader', false)
      this.$store.dispatch('varus-cafe/setProductsLoading', false)
    }

    // this.eSputnikActions()
  },
  methods: {
    ...mapActions({
      changeRouterFilterParameters: 'varus-cafe/changeRouterFilterParameters',
      setProductsLoading: 'varus-cafe/setProductsLoading',
      loadMore: 'varus-cafe/loadMore'
    }),
    getDishDeclension (count) {
      const { storeCode } = currentStoreView()

      const currentLocale = storeCode === 'ua' ? 'uk' : 'ru'

      if (currentLocale === 'uk') {
        if (count % 100 >= 11 && count % 100 <= 19) {
          return 'страв';
        }
        switch (count % 10) {
          case 1:
            return 'страва';
          case 2:
          case 3:
          case 4:
            return 'страви';
          default:
            return 'страв';
        }
      } else if (currentLocale === 'ru') {
        if (count % 100 >= 11 && count % 100 <= 19) {
          return 'блюд';
        }
        switch (count % 10) {
          case 1:
            return 'блюдо';
          case 2:
          case 3:
          case 4:
            return 'блюда';
          default:
            return 'блюд';
        }
      }
    },
    // changeSortOder (sortOrder) {
    //   if (this.currentSearchQuery.sort !== sortOrder) {
    //     this.loadMore(false);
    //     this.sortOrder = sortOrder;
    //     const queryFilter = sortOrder === 'popularity:desc' ? '' : sortOrder;
    //     this.updateAndRoute(queryFilter, 'sort');
    //   }
    // },
    // async changeFilter (filter) {
    //   this.updateSelectedFilters(filter)
    //   await this.loadMore(false);
    //   const { type, id } = filter;
    //   const updatedQuery = { ...this.$route.query };
    //
    //   let queryFilter = updatedQuery[type] || [];
    //
    //   if (Array.isArray(queryFilter)) {
    //     queryFilter = this.toggleFilter(queryFilter, id);
    //   } else {
    //     queryFilter = queryFilter === id ? '' : [queryFilter, id];
    //   }
    //
    //   if (updatedQuery.page) {
    //     delete updatedQuery.page;
    //   }
    //
    //   this.updateAndRoute(queryFilter, type, updatedQuery);
    // },
    toggleFilter (queryFilter, filterId) {
      return queryFilter.includes(filterId)
        ? queryFilter.filter(id => id !== filterId)
        : [...queryFilter, filterId];
    },
    updateAndRoute (queryFilter, type, updatedQuery = { ...this.$route.query }) {
      if (!queryFilter || queryFilter.length === 0) {
        delete updatedQuery[type];
      } else {
        updatedQuery[type] = queryFilter;
      }

      this.changeRouterFilterParameters(updatedQuery);
    },
    // async clearFilters () {
    //   this.selectedFilters = {};
    //   await this.loadMore(false);
    //   const query = this.$route.query?.cat ? { cat: this.$route.query.cat } : {};
    //   this.changeRouterFilterParameters(query);
    // },
    // async promoFilterChanged ({ type }) {
    //   const updatedQuery = { ...this.$route.query }
    //
    //   if (updatedQuery.hasOwnProperty(type)) {
    //     delete updatedQuery[type];
    //   } else {
    //     updatedQuery[type] = null;
    //   }
    //
    //   if (updatedQuery.page) {
    //     delete updatedQuery.page;
    //   }
    //
    //   await this.changeRouterFilterParameters(updatedQuery);
    // },
    // submitFilterPrice (minPrice, maxPrice) {
    //   this.updateAndRoute(`${minPrice}-${maxPrice}`, 'price')
    // },
    async onIntersect (entry) {
      if (!entry.isIntersecting) {
        return;
      }

      if (this.loading) {
        return;
      }

      if (this.loaded) {
        return;
      }

      await this.$store.dispatch('homepage/loadAdvantagesContent')
      await this.$store.dispatch('faq/loadFAQForCategory', { id: this.getVarusCafeCategory.id })

      this.hasIntersected = true;
    },
    async eSputnikActions () {
      eSputnikEvent('CategoryPage', this.getVarusCafeCategory.name)
    },
    selectCategory (slug) {
      this.loadMore(false);
      this.changeCategory(slug)
    },
    changeCategory (slug) {
      let newPath = '/varuscafe/';

      if (this.$route.params?.slug === slug) return;

      newPath += slug;

      this.$router.push({
        path: this.localizedRoute(newPath),
        query: {}
      });
    },
    togglePizzaIngredientsFilter () {
      const value = this.isPizzaIngridientsActive;
      const query = value ? {} : { pizzaIngredient: null };
      this.changeRouterFilterParameters(query)
    },
    // updateSelectedFilters (filter) {
    //   const { type, id } = filter;
    //
    //   if (!this.selectedFilters[type]) {
    //     this.$set(this.selectedFilters, type, [filter]);
    //     return;
    //   }
    //
    //   if (this.selectedFilters[type]) {
    //     const isFilterActive = this.selectedFilters[type].find(item => item.id === id);
    //     let newFilters = [];
    //
    //     if (isFilterActive) {
    //       newFilters = this.selectedFilters[type].filter(item => item.id !== id);
    //     } else {
    //       newFilters = [...this.selectedFilters[type], filter];
    //     }
    //
    //     if (newFilters.length === 0) {
    //       delete this.selectedFilters[type];
    //     } else {
    //       this.selectedFilters[type] = newFilters;
    //     }
    //   }
    // },
    // updateSelectedFiltersFromStore (filters = this.currentFilters) {
    //   this.selectedFilters = JSON.parse(JSON.stringify(filters));
    // },
    // updateSortOrderFromStore (sortOrder = this.currentSearchQuery.sort) {
    //   this.sortOrder = sortOrder || 'popularity:desc';
    // },
    onLoadMore () {
      this.loadMore(true);
    },
    onPageChanged () {
      this.loadMore(false);
    },
    onPaginationClick () {
      this.setProductsLoading(true)
    }
  },
  metaInfo: metaCategory
};
</script>

<style lang="scss" scoped>
@import "~theme/css/mixins";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.category {
  position: relative;
  z-index: 0;

  &__container {
    width: 100%;

    &--green {
      background-color: var(--green-light-grayish);
    }

    &--with-filters {
      position: sticky;
      z-index: 1;
      top: 52px;

      @media screen and (min-width: $desktop-min) {
        top: 60px;
      }
    }
  }

  &__block {
    max-width: var(--max-width);
    box-sizing: border-box;
    margin: 0 auto;
  }

  .sidebar-filters {
    position: relative;
    background-color: var(--white);
  }

  ::v-deep {
    .sf-breadcrumbs {
      @media (min-width: $desktop-min) {
        --breadcrumbs-padding: 10px;
      }
    }

    .sf-breadcrumbs__list-item {
      padding-left: px2rem(10);

      @include for-desktop {
        padding-left: 0;
      }

      @media (max-width: $tablet-min) {
        padding-left: px2rem(18);
      }
    }

    .products__grid.main-grid,
    .products__grid.top-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: px2rem(15);
      row-gap: px2rem(15);
      box-sizing: border-box;
      padding: 0 10px px2rem(15);

      @include for-desktop {
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: px2rem(370);
      }

      @include for-tablet {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: $tablet-min) {
        column-gap: px2rem(10);
        row-gap: px2rem(10);
        padding: 0 10px px2rem(10);
      }

      .sf-product-card--out-of-stock-container {
        height: auto;
      }

      .sf-product-card {
        &:hover {
          &:before {
            bottom: calc(-1 * var(--spacer-60));
          }
        }
      }

      .sf-product-card__wrapper {
        max-width: 100%;
        width: 100%;
        margin-bottom: 0;
        background-color: var(--white);

        .sf-product-card__title {
          margin-top: var(--spacer-10);
        }
      }
    }

    .banner-info-wrap {
      @include for-desktop {
        margin-top: px2rem(45);
        margin-bottom: 30px;
      }

      &--last {
        margin-bottom: var(--spacer-20);
        margin-top: var(--spacer-20);

        @media (min-width: $tablet-min) {
          margin-top: var(--spacer-40);
        }

        .sfo-carousel__bullets {
          padding-top: var(--spacer-10);
        }
      }

      &.banner-missed {
        margin: px2rem(15) 0 0;
        display: none;

        @include for-desktop {
          margin-top: px2rem(10);
          margin-bottom: 0;
        }
      }

      .sf-carousel-item {
        overflow: unset;
      }
    }

    .sidebar-filters.desktop-only {
      margin-top: 0;
    }

    &__sidebar {
      flex: auto;
      max-width: inherit;
      padding: var(--spacer-sm) 0;

      @include for-tablet {
        padding-left: var(--spacer-17);
        padding-right: 0;
      }

      @include for-desktop {
        flex: 0 0 13.75rem;
        padding-left: var(--spacer-10);
        padding-top: var(--spacer-sm);
        padding-right: var(--spacer-20);
        max-width: 13.75rem;
      }
    }

    .main {
      .products {
        @include for-desktop {
          padding-bottom: var(--spacer-60);
        }

        .m-category-list__item {
          padding: 0;
        }

        .sf-product-card--out-of-stock-container {
          &::before {
            display: none;
          }

          .sf-product-card__footer {
            text-align: center;
            background: var(--white);
            padding-bottom: 1rem;
          }
        }

        .products__grid {
          .sf-product-card__wrapper {
            height: 100%;

            .sf-product-card {
              &__price {
                margin-top: var(--spacer-15);
                margin-bottom: var(--spacer-15);
              }

              &:hover {
                &__hidden-basket {
                  display: block;
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .m-category-list__item {
        width: 100%;
        max-width: 100%;
      }

      .sf-product-card {
        .sf-link,
        .price-block {
          text-align: center;
        }

        &__title-wrapper {
          margin-bottom: 0;
          display: flex;
          justify-content: center;

          @include for-desktop {
            height: px2rem(59);
          }
        }

        .price-block .sf-price {
          justify-content: center;
        }

        &__title {
          font-size: var(--font-size-13);
          margin-top: var(--spacer-10);
          height: px2rem(38);
          overflow: hidden;
          line-height: var(--font-size-19);

          @include for-tablet {
            margin-top: var(--spacer-15);
          }

          @media (min-width: $tablet-min) {
            max-width: 200px;
            font-size: var(--font-size-16);
          }

          @include for-desktop {
            margin-top: var(--spacer-23);
          }
        }

        &__image-wrapper {
          padding: var(--spacer-10);

          .sf-image {
            overflow: inherit;

            img {
              left: 50%;
              transform: translate(-50%, 0);
              width: auto;
            }
          }
        }

        &__quantity {
          margin-top: var(--spacer-5);

          @media (min-width: $tablet-min) {
            margin-top: var(--spacer-10);
          }
        }

        .m-product-add-to-cart {
          padding-bottom: var(--spacer-20);

          .a-product-quantity__wrapper,
          .sf-button {
            margin: 0 auto;
          }
        }
      }
    }

    .m-sidebar-mobile-buttons {
      margin: 0 0 15px;

      &__button {
        position: relative;

        &:after {
          content: '';
          width: .3rem;
          height: .3rem;
          border-bottom: 2px solid var(--white);
          border-right: 2px solid var(--white);
          transform: rotate(45deg);
          right: var(--spacer-sm);
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          display: block;
        }
      }
    }

    .m-category-filters-selected {
      .filter__remove {
        &:active {
          --button-background: transparent;
        }
        .sf-icon {
          --icon-color: var(--c-primary);
        }
      }
      .clear-all {
        &:active {
          --button-background: transparent;
        }
      }
    }
  }
}

.notification {
  &__wrapper {
    padding: 0 var(--spacer-10) var(--spacer-20);
  }
}

.category-footer {
  padding: 0;

  &__wrapper {
    max-width: var(--max-width);
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
  }

  @include for-desktop {
    padding: 0 var(--spacer-10);
  }

  .m-seo-block {
    padding: 0 var(--spacer-10) var(--spacer-100);

    @include for-tablet {
      padding-left: var(--spacer-17);
      padding-right: var(--spacer-18);
    }
  }

  .m-recently-viewed-products {
    ::v-deep {

      @include for-tablet {
        padding-top: var(--spacer-20);
      }
      .o-section {
        padding: 0;

        &--center {
          padding: 0;
        }

        &__heading {
          @include for-mobile {
            padding: 0 var(--spacer-10) var(--spacer-15) var(--spacer-10);
          }

          @include for-tablet {
            padding: 0 var(--spacer-16) var(--spacer-15) 0;
          }
        }
      }

      @media (min-width: $tablet-min) {
        .sf-carousel__slides {
          padding-bottom: var(--spacer-22);
        }
      }

      .glide__slide .sf-product-card {
        padding: 0;
      }
    }

    @include for-tablet {
      margin-left: var(--spacer-16);
    }
  }

  .m-advantages {
    padding-top: var(--spacer-55);
    padding-bottom: var(--spacer-50);

    @include for-tablet {
      padding-top: var(--spacer-60);
      padding-bottom: var(--spacer-60);
      line-height: var(--spacer-34);
    }

    @include for-desktop {
      padding-top: var(--spacer-30);
      padding-bottom: var(--spacer-2xl);
      line-height: var(--spacer-34);
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacer-50);
  padding: 0 0 var(--spacer-50);

  @media (min-width: $desktop-min) {
    margin-bottom: var(--spacer-90);
  }
}

.sidebar {
  @include for-desktop {
    padding: 0;
    border: none;
  }
}

.sidebar-filters {
  --sidebar-title-display: none;
  --sidebar-top-padding: 0;
  background: var(--light-gray);
  border-radius: 10px;
  padding: var(--spacer-sm) var(--spacer-20);
  margin-top: var(--spacer-10);

  @include for-desktop {
    --sidebar-content-padding: 0 var(--spacer-xl);
    --sidebar-bottom-padding: 0 var(--spacer-xl);
    padding: 0 var(--spacer-20);
  }
}

.list {
  --menu-item-font-size: var(--font-sm);

  &__item {
    &:not(:last-of-type) {
      --list-item-margin: 0 0 var(--spacer-sm) 0;
    }
  }
}

.products {
  box-sizing: border-box;
  flex: 1;
  margin: 0;
  overflow: hidden;

  &__navbar-container {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    background: var(--white, #FFF);
    padding: 16px;
    margin: 0 0 15px;
  }

  ::v-deep &__grid {
    display: flex;
    flex-wrap: wrap;

    @include for-desktop {
      width: 100%;

      ::v-deep .sf-product-card {
        &.sf-product-card--out-of-stock-container:hover:before {
          bottom: calc(-1 * var(--spacer-5));
        }
        &:hover {
          &:before {
            bottom: calc(-1 * var(--spacer-35));
          }
          .sf-product-card__hidden-basket {
            position: absolute;
          }
        }
        .sf-product-card__hidden-basket {
          position: absolute;
          left:5px;
          bottom: -15px;
        }
      }
    }
  }

  &__slide-enter {
    opacity: 0;
    transform: scale(0.5);
  }

  &__slide-enter-active {
    transition: all 0.2s ease;
    transition-delay: calc(0.1s * var(--index));
  }

  &__load-more {
    background-color: transparent;
    font-weight: 400;
    color: var(--orange);
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--orange-hover);
    }
  }

  @include for-desktop {
    margin: 0;

    &__pagination {
      display: flex;
      justify-content: center;
      margin: 0;
      background-color: var(--light-gray);
      padding: var(--spacer-20);
    }

    &__product-card {
      flex: 1 1 25%;
    }

    &__list {
      margin: 0 0 0 var(--spacer-sm);
    }
  }

  &__not-found {
    margin-top: var(--spacer-30);
    --heading-text-align: left;
    padding: 0 var(--spacer-10);

    ::v-deep .sf-heading__title {
      color: var(--dark-gray);
      margin-bottom: var(--spacer-10);
      font-size: var(--font-size-16);
      line-height: var(--font-size-19);

      @include for-desktop {
        margin-bottom: var(--spacer-18);
        font-size: var(--font-size-24);
        line-height: var(--font-size-25);
      }
    }

    ::v-deep .sf-heading__subtitle {
      color: var(--black);
      font-size: var(--font-size-14);
      line-height: var(--font-size-17);

      @include for-desktop {
        font-size: var(--font-size-16);
        line-height: var(--font-size-19);
      }
    }
  }

  &__change-city {
    margin-top: var(--spacer-20);
    width: px2rem(137);
    height: var(--spacer-40);

    @include for-desktop {
      font-size: var(--font-size-18);
      margin-top: var(--spacer-30);
      width: px2rem(228);
      height: var(--spacer-56);
    }

    @media (max-width: $mobile-max) {
      padding: var(--spacer-11) var(--spacer-sm);
      font-size: var(--font-size-14);
      line-height: var(--font-size-24);
    }
  }
}

.bottom-plp-block {
  padding: 30px 0 0;

  @media (min-width: $tablet-min) {
    width: 100%;
    padding: 30px 10px 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.center {
  padding: 0 var(--spacer-sm);
  box-sizing: border-box;

  @include for-desktop {
    max-width: var(--max-width);
    margin: 0 auto;
  }
}

.m-category-advantages {
  padding-top: 0;
}

.banner-info-wrap {
  margin: px2rem(10) 0 px2rem(20);
  position: relative;
  transform: none;

  @media (min-width: $desktop-min) {
    padding: 0 10px;
  }

  &.banner-missed {
    .slider-carousel {
      display: none;
    }
  }

  &--last {
    margin-bottom: px2rem(50);

    &.banner-missed {
      margin: calc(-1 * var(--spacer-30)) 0 var(--spacer-30);
      border-top: 1px solid var(--color-zircon);
      position: relative;
      z-index: -1;
    }

    ::v-deep {
      .glide__track {
        padding-top: 0;
      }
    }
  }

  @include for-tablet {
    transform: none;
  }

  @include for-desktop {
    margin-top: 0;
  }

  &:empty {
    margin: 0;
  }
}

.page__title-wrapper {
  display: flex;
  align-items: baseline;
  padding: 0 10px 10px;

  .page__title {
    padding-bottom: 0;

    ::v-deep .sf-heading__title {
      @include header-title;
      color: var(--black);
    }
  }

  .page__title-counter {
    display: inline;
    font-family: var(--font-family-secondary), serif;
    margin: 0 0 0 20px;
  }

  .page__title-label {
    font-family: var(--font-family-secondary), serif;
    font-weight: var(--font-normal);
    color: var(--dark-gray);
    margin: 0 var(--spacer-2xs) 0 0;
    font-size: var(--font-size-13);
    white-space: nowrap;

    transition: opacity 200ms;
    opacity: 1;

    &--hide {
      opacity: 0;
    }
  }
}

.button__clear-filters {
  --button-padding: 5px 10px;
  --button-font-size: 12px;
  --button-border-style: solid;
  --button-border-width: 1px;
  --button-border-radius: 5px;
  --button-background: transparent;
  --button-border-color: var(--orange);
  --button-color: var(--orange);
}

.top-banner__wrapper {
  padding: 0 0 10px;

  @media (min-width: $desktop-min) {
    padding: 0 10px 15px;
  }
}

::v-deep {
  .sf-banner-plp {
    background-position: right;

    @media (max-width: $mobile-max) {
      min-height: px2rem(115);
      padding: 0 var(--spacer-25) var(--spacer-20) ;
    }

    @media (min-width: $tablet-min) {
      min-height: px2rem(115);
      padding: 0 var(--spacer-30) var(--spacer-25) ;
    }

    @include for-desktop {
      min-height: px2rem(150);
      padding: 0 var(--spacer-56) var(--spacer-25) ;
    }

    &__description {
      font-size: var(--font-size-14);
      line-height: var(--font-size-17);

      @media (min-width: $tablet-min) {
        font-size: var(--font-size-24);
        line-height: var(--font-size-25)
      }
    }
  }

  .sf-banner__container {
    padding-right: var(--spacer-50);
    flex: 1 1 var(--banner-container-width, 100%);
  }
}
</style>
