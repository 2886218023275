<template>
  <ASmoothReflow class="m-price-summary">
    <SfProperty
      v-if="nonGiftItemsQuantityWithQTY"
      :name="$tc('{count} items worth', getValue)"
      :value="prices.subtotal | price"
      class="sf-property--full-width property property--total-items"
      :class="{'sf-property--large': isLarge}"
    />

    <MDiscountTotals
      :discounts="baseDiscounts"
      :is-large="isLarge"
      :promo-modal-enable="true"
    />

    <SfProperty
      v-if="totalWeight"
      :name="$t('Order weight')"
      :value="totalWeightWithTranslate"
      class="sf-property--full-width property property--weight"
      :class="{
        'sf-property--large': isLarge,
        'property--invalid': maxWeightInvalid
      }"
    >
      <template #value="{ value }">
        <span class="sf-property__value">{{ value }} <span v-if="maxWeight" class="sf-property__value--sub">/ {{ maxWeight }}</span></span>
      </template>
    </SfProperty>

    <SfProperty
      v-if="prices.collecting"
      :name="$t('Collecting')"
      :value="prices.collecting | price"
      class="sf-property--full-width property property--total-items"
      :class="{'sf-property--large': isLarge}"

    >
      <template #name="{ name }">
        <span class="sf-property__name">
          {{ name }}
          <AFloatingTooltip class="aft--full-width">
            <span class="m-price-summary__icon"></span>

            <template #tooltip>
              {{ $t('Collecting Tooltip') }}
            </template>
          </AFloatingTooltip>
        </span>
      </template>
    </SfProperty>

    <SfProperty
      :name="$t('Total')"
      :value="getCartTotal | price"
      class="sf-property--full-width property property--grand-total"
      :class="{'sf-property--large': isLarge}"
    />
  </ASmoothReflow>
</template>

<script>
import { mapGetters } from 'vuex';
import { SfProperty } from '@storefront-ui/vue';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow';
import MDiscountTotals from 'theme/components/molecules/m-discount-totals.vue';
import { weightGToKg } from 'theme/filters';
import AFloatingTooltip from 'theme/components/atoms/a-floating-tooltip.vue';

export default {
  name: 'MPriceSummary',
  components: {
    AFloatingTooltip,
    MDiscountTotals,
    SfProperty,
    ASmoothReflow
  },
  props: {
    isLarge: {
      type: Boolean,
      default: false
    },
    totalWeight: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      showDiscount: false
    }
  },
  computed: {
    ...mapGetters({
      totals: 'cart/getTotals',
      discounts: 'cart/getDiscounts',
      getCartTotal: 'cart/getCartTotal',
      shippingDetails: 'shipping/getShippingDetails',
      cartStates: 'cart/getCartStates',
      itemsQuantityWithQTY: 'cart/getItemsQuantityWithQTY',
      nonGiftItemsQuantityWithQTY: 'cart/getNonGiftItemsQuantityWithQTY'
    }),
    maxWeightInvalid () {
      return !this.cartStates.maxWeight.valid
    },
    maxWeight () {
      const maxWeightInGrams = this.shippingDetails.deliveryMethod?.max_weight || 0;
      const maxWeightInKg = weightGToKg(maxWeightInGrams);

      return maxWeightInKg ? `${maxWeightInKg} ${this.$t('kg')}` : null
    },
    totalWeightWithTranslate () {
      return `${this.totalWeight} ${this.$t('kg')}`
    },
    prices () {
      return this.totals.reduce((result, price) => {
        result[price.code] = price.value;
        return result;
      }, {});
    },
    getValue () {
      return this.nonGiftItemsQuantityWithQTY.toFixed()
    },
    baseDiscounts () {
      return this.discounts.filter(i => i.code !== 'coupon')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import '~theme/css/fonts';

.m-price-summary {
  &__icon {
    color: var(--orange);
    cursor: pointer;
    @include font-icon(var(--icon-info-outline));
  }

  ::v-deep {
    .property {
      margin: 0 0 var(--spacer-16);
      --property-name-content: none;
      --property-name-font-size: var(--font-size-16);
      --property-value-font-size: var(--font-size-16);
      --property-name-color: var(--black);
      --property-value-color: var(--black);
      --property-name-font-weight: var(--font-normal);
      --property-value-font-weight: var(--font-normal);
      --property-name-font-line-height: 1.2;

      &--invalid {
        .sf-property__name, .sf-property__value {
          color: var(--red-main);
          font-weight: 600;

          span {
            font-weight: 400;
          }
        }
      }

      &--total-items {
        margin: 0 0 var(--spacer-16);
      }

      &--grand-total {
        margin-bottom: 0;

        span {
          @include header-title(22, 22);
        }
      }

      @include for-desktop {
        &__total {
          padding: var(--spacer-base) 0 0 0;
        }
      }
    }
    .divider {
      --divider-border-color: var(--c-white);
      --divider-width: 100%;
      --divider-margin: 0 0 var(--spacer-5);
      --divider-border-width: 0;
    }
  }

  .sf-property__value--sub {
    color: var(--dark-gray);
    font-size: var(--font-size-12);
    font-weight: 400;
  }
}
</style>
