<template>
  <div
    id="category"
    class="category"
    :class="[
      {
        'category--is-empty': isProductsNotFound
      }
    ]"
  >
    <m-breadcrumbs />
    <json-ld-breadcrumb />
    <div
      class="navbar section"
    >
      <div class="navbar__main">
        <SfHeading
          :level="1"
          :title="categoryMetaContent.h1"
          class="navbar__title"
        />
        <div class="navbar__counter">
          <span
            class="navbar__label"
            :class="{ 'navbar__label--hide': isLoading }"
          >
            {{ categoryProductsTotal }}
          </span>
        </div>
      </div>
    </div>
    <ASmoothReflow>
      <MCategoryNP
        v-if="!availableProducts && !categoryLoader"
        class="category__np"
      />
    </ASmoothReflow>
    <div class="main">
      <div class="sidebar">
        <MSidebarMobileButtons
          :filters-count="activeFiltersCount"
          :categories-are-shown="categoriesAreShown"
          @open="isFilterSidebarOpen = true"
          @toggle-categories="toggleCategoriesFilters()"
        />
        <CategoriesFilter
          v-if="categoriesForFilter.length > 0"
          v-show="isDesktop || categoriesAreShown"
          :categories="categoriesForFilter"
          :categories-map="getCategoriesMap"
          :is-loading="categoriesIsLoading || productsIsLoading"
          :show="isDesktop || categoriesAreShown"
          :show-all-goods="true"
          :product-counts-by-category="productCountsByCategory"
          :current-category="currentCategory"
          @select="setCurrentCategory"
        />
        <NoSSR>
          <div
            class="sidebar-filters desktop-only"
            v-if="Object.keys(availableFilters).length > 0 && Object.keys(filterLabels).length > 0"
          >
            <SpecialOffersSidebarFilter
              :available-filters="availableFilters"
              :key="Object.keys(availableFilters).length"
              :filter-labels="filterLabels"
              :current-filters="selectedFilters"
              :aggregations="getAggregations"
              @change="changeFilter"
              @submit-filter-price="submitFilterPrice"
              @clear-price="clearPrice"
            />
            <ALoadingSpinner
              v-show="productsIsLoading"
              :size="24"
              :weight="3"
              :is-absolute-position="true"
            />
          </div>
        </NoSSR>
      </div>
      <template>
        <div class="products">
          <div class="products__navbar-container desktop-only">
            <div class="navbar__filter">
              <MCategoryFilterStock
                @stock-filter-changed="stockFilterChanged"
              />
<!--              <MCategoryFilterNP-->
<!--                @stock-filter-changed="forNpFilterChanged"-->
<!--              />-->
              <MCategoryFilterPromo
                :promo-count="categoryProductsForPromotionCount"
                :key="categoryProductsForPromotionCount"
                @promo-filter-changed="promoFilterChanged"
              />
              <MCategoryFilterMarkdown
                v-if="showMarkdownFilter"
                :markdown-count="getMarkdownCount"
                :key="getMarkdownCount"
                @markdown-filter-changed="markdownFilterChanged"
              />
            </div>
            <div class="navbar__sort">
              <SfSelect
                class="navbar__select sf-select--white sort-by"
                ref="SortBy"
                :selected="sortOrder"
                @change="changeSortOder"
              >
                <SfSelectOption
                  v-for="option in sortOptions"
                  :key="option.id"
                  :value="option.id"
                  class="sort-by__option"
                >
                  {{ $t(option.label) }}
                </SfSelectOption>
                <template #icon>
                  <span class="dropdown-chevron" />
                </template>
              </SfSelect>
            </div>
          </div>
          <NoSSR>
            <MSelectedFilters
              v-if="activeFiltersCount !== 0"
              class="desktop-only"
              :key="Object.keys(selectedFilters).length"
              :filter-categories="selectedFilters"
              :filter-labels="filterLabels"
              @clear="changeFilter"
              @clear-all="clearFilters"
              @clear-price="clearPrice"
              :available-filters="availableFilters"
            />
          </NoSSR>
          <template v-if="!isLoading && isProductsNotFound">
            <SfHeading
              class="products__not-found"
              :title="isProductNone ? $t('No products found in the catalog') : $t('No products found!')"
              :subtitle="isProductNone ? $t('Try using a different combination of filters') : $t('Please select another city or another category')"
            />
            <SfButton
              class="products__change-city"
              v-if="!isProductNone"
              data-transaction-name="Category - Change City"
              @click="openShippingModal()"
            >
              {{ $t('Change city') }}
            </SfButton>
          </template>
          <template v-else>
            <div
              class="banner-info-wrap"
              v-if="productsBeforeInoBanner.length > 0 && !isLoading"
              :class="{'banner-missed': !hasBannerTop}"
            >
              <MBannerCarousel
                :key="currentCategory.id"
                :banners="bannerTop"
                :is-responsive="true"
              />
            </div>
            <MCategoryList
              :is-loading="productsIsLoading"
              :products="productsBeforeInoBanner"
              class-list="products__grid top-grid"
              badge-label="-50%"
              badge-color="color-primary"
              :placeholder-count="cardInRow"
              :alt-name="currentCategory.name"
              :category-id="currentCategory.id"
              :category-name="currentCategory.name"
            />
            <div
              class="banner-info-wrap banner-info-wrap--last"
              v-if="productsAfterInoBanner.length > 0 && !isLoading"
              :class="{'banner-missed': !hasBannerMiddle}"
            >
              <MBannerCarousel
                :key="currentCategory.id"
                :banners="getBannersMiddle"
                :is-responsive="true"
              />
            </div>
            <MCategoryList
              :is-loading="productsIsLoading"
              :products="productsAfterInoBanner"
              class-list="products__grid main-grid"
              badge-label="-50%"
              badge-color="color-primary"
              :last-row-class="hasBannerMiddle"
              :placeholder-count="productsAfterInoBannerCount"
              :alt-name="currentCategory.name"
              :is-all-lazy="true"
              :category-id="currentCategory.id"
              :category-name="currentCategory.name"
            />
            <NoSSR>
              <div
                  v-if="!isMobile"
                  class="bottom-plp-block"
              >
                <OPagination
                    v-if="products.length && totalPages > 1"
                    :current-page="currentPage"
                    :total="totalPages"
                    :scroll-top="true"
                    :per-page="perPage"
                    :is-load-more="isAdditionalLoading"
                    @nav-clicked="onPaginationClick"
                    @load-more="onLoadMore"
                    @page-changed="onPageChanged"
                />
              </div>
            </NoSSR>
            <!--          <MPopularCategories v-if="false" />-->
            <MRelatedCategories
              v-if="getRelatedCategories"
              :items="getRelatedCategories"
            />
          </template>
        </div>
        <NoSSR>
          <div
              v-if="isMobile"
              class="bottom-plp-block"
          >
            <OPagination
                v-if="products.length && totalPages > 1"
                :current-page="currentPage"
                :total="totalPages"
                :scroll-top="true"
                :per-page="perPage"
                :is-load-more="isAdditionalLoading"
                @nav-clicked="onPaginationClick"
                @load-more="onLoadMore"
                @page-changed="onPageChanged"
            />
          </div>
        </NoSSR>
      </template>
    </div>
    <ASmoothReflow
      class="category-footer__wrapper"
      v-intersect.once="{ handler: onIntersect, cfg: { rootMargin: '0px' }}"
    >
      <div class="category-footer">
        <template v-if="hasIntersected">
          <MRecentlyViewedProducts :show-qty-permanently="true" />
          <MAdvantages />
          <OFAQ
            v-if="questions"
            :questions="questions"
          />
          <OSection
            v-if="false"
            :title-heading="$t('Cook with VARUS')"
            class="section"
          >
            <template #link>
              <SfLink link="#" target="_blank" class="sf-link--primary">
                {{ $t("Read recipes") }}
              </SfLink>
            </template>
            <MRecipeCarousel class="flex" />
          </OSection>
        </template>
        <MSeoBlock
          v-if="getSeoHtml"
          :seo-html="getSeoHtml"
        />
      </div>
    </ASmoothReflow>

    <NoSSR>
      <SidebarMobileFilters
          v-if="Object.keys(availableFilters).length > 0 && Object.keys(filterLabels).length > 0"
          :key="activeFiltersCount"
          :is-open="!!isFilterSidebarOpen"
          @close="isFilterSidebarOpen = false"
          @open="filterSidebarInner"
          @clear="clearFilters"
          :sort-label="sortLabel"
          :available-filters="availableFilters"
          :filters-count="activeFiltersCount"
          :filter-labels="filterLabels"
          :filter-actived="selectedFilters"
          :products-is-loading="productsIsLoading"
          :promo-count="categoryProductsForPromotionCount"
          :markdown-count="getMarkdownCount"
          :is-promo-filter-active="true"
          :is-markdown-filter-active="showMarkdownFilter"
          @stock-filter-changed="stockFilterChanged"
          @for-np-filter-changed="forNpFilterChanged"
          @promo-filter-changed="promoFilterChanged"
          @markdown-filter-changed="markdownFilterChanged"
      />
    </NoSSR>

    <NoSSR>
      <OSidebarFiltersInner
          v-if="Object.keys(availableFilters).length > 0 && Object.keys(filterLabels).length > 0"
          :is-open="!!filterSidebarInnerOpen"
          :filter-name="filterSidebarInnerOpen"
          :filter-labels="filterLabels"
          @change="changeFilter"
          @change-sort-order="changeSortOder"
          @close="filterSidebarInnerOpen = ''"
          :sort-options="sortOptions"
          :current-filters="selectedFilters"
          :sort-order="sortOrder"
          :available-filters="availableFilters"
          :filters-count="activeFiltersCount"
          :aggregations="getAggregations"
          :products-is-loading="productsIsLoading"
          @submit-filter-price="submitFilterPrice"
      />
    </NoSSR>

    <JsonLdCategory :products-total="getCategoryProductsTotal"/>

    <JsonLdCategoryList
        :category="currentCategory"
        :products="products"
    />
    <JsonLdFilters
        :current-category-id="currentCategory.id"
        :available-filters="availableFilters"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import castArray from 'lodash/castArray';
import MBreadcrumbs from '../components/molecules/m-breadcrumbs';
import config from 'config';
import {
  isServer
} from '@vue-storefront/core/helpers';
import { htmlDecode } from '@vue-storefront/core/filters';
import { getSearchOptionsFromRouteParams } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers';
import { catalogHooksExecutors } from '@vue-storefront/core/modules/catalog-next/hooks';
import {
  prepareCategoryProduct
} from 'theme/helpers';
import {
  SfButton,
  SfLink,
  SfSelect,
  SfHeading
} from '@storefront-ui/vue';
import { eSputnikEvent } from 'theme/helpers/es';
import MCategoryFilterPromo from 'theme/components/molecules/m-category-filter-promo';
import MSeoBlock from 'theme/components/molecules/m-seo-block';
import MAdvantages from 'theme/components/molecules/m-advantages';
import MRecentlyViewedProducts from 'theme/components/molecules/m-recently-viewed-products';
import MRecipeCarousel from 'theme/components/molecules/m-recipe-carousel';
import OSection from 'theme/components/organisms/o-section';
// import MPopularCategories from 'theme/components/molecules/m-popular-categories';
import MSidebarMobileButtons from 'theme/components/molecules/m-sidebar-mobile-buttons';
import MBannerCarousel from 'theme/components/molecules/m-banner-carousel';
import DeviceType from 'theme/mixins/DeviceType';
import JsonLdCategory from 'theme/components/json-ld/json-ld-category';
import JsonLdCategoryList from 'theme/components/json-ld/json-ld-category-list';
import JsonLdBreadcrumb from 'theme/components/json-ld/json-ld-breadcrumb';
import MCategoryList from 'theme/components/molecules/m-category-list';
import { metaCategory, getMetaContent } from '../meta/category';
import ASmoothReflow from '../components/atoms/a-smooth-reflow';
import intersect from '../directives/intersect';
import MRelatedCategories from 'theme/components/molecules/m-related-categories.vue';
import NoSSR from 'vue-no-ssr'
import GoogleTagManager from 'theme/mixins/gtm';
import Delivery from 'theme/mixins/Delivery';
import MCategoryNP from 'theme/components/molecules/m-category-np.vue';
import OFAQ from 'theme/components/organisms/o-faq.vue';
// import MCategoryFilterNP from 'theme/components/molecules/m-category-filter-np.vue';
import MCategoryFilterStock from 'theme/components/molecules/m-category-filter-stock';
import CategoriesFilter from 'theme/components/organisms/category/categories-filter.vue';
import MCategoryFilterMarkdown from 'theme/components/molecules/m-category-filter-markdown.vue';
import { HumanFilter } from 'theme/helpers/humanFilter';
import JsonLdFilters from 'theme/components/json-ld/json-ld-filters';

let isAlreadyLoaded = false;

const THEME_PAGE_SIZE = 40;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const composeCategoryState = async (store, route) => {
  const filters = getSearchOptionsFromRouteParams(route.params);

  // const cachedCategory = store.getters['category-next/getCategoryFrom'](
  //   route.path
  // );
  // console.log('cachedCategory', cachedCategory)
  // console.log(!forceLoad)
  //
  // if (cachedCategory && !forceLoad) {
  //   return cachedCategory
  // }

  const [category] = await Promise.all([
    store.dispatch('category-next/loadCategory', { filters }),
    store.dispatch('category/loadHumanFilterMeta', { fullPath: route.fullPath })
  ])

  return category
}

const loadProducts = async (store, route, currentCategory, page = 0) => {
  // const currentCategoryId = currentCategory?.id
  const toPromise = [
    store.dispatch('category/loadCategoryProducts', {
      route: route,
      category: currentCategory,
      pageSize: THEME_PAGE_SIZE,
      start: page ? (page - 1) * THEME_PAGE_SIZE : page
    })
  ]

  catalogHooksExecutors.categoryPageVisited(currentCategory);

  const [ sampleSpecialOffers ] = await Promise.all(toPromise)

  if (currentCategory.parent_id === config.entities.category.categoriesRootCategoryId) {
    store.dispatch('category/setSampleSpecialOffers', sampleSpecialOffers)
  }

  store.dispatch('ui/categoryLoader', false)
  store.dispatch('category/setProductsLoading', false)
}

export default {
  name: 'CategoryPage',
  components: {
    // MCategoryFilterNP,
    MCategoryFilterMarkdown,
    JsonLdBreadcrumb,
    JsonLdCategory,
    JsonLdCategoryList,
    MSeoBlock,
    MBreadcrumbs,
    SfButton,
    SfLink,
    SfSelect,
    SfHeading,
    MCategoryNP,
    MCategoryFilterPromo,
    MRecentlyViewedProducts,
    MAdvantages,
    MRecipeCarousel,
    OSection,
    // MPopularCategories,
    SpecialOffersSidebarFilter: () => process.browser ? import('theme/components/special-offers/sidebar-filter.vue') : null,
    MSidebarMobileButtons,
    MBannerCarousel,
    MCategoryList,
    ASmoothReflow,
    MRelatedCategories,
    NoSSR,
    OFAQ,
    MCategoryFilterStock,
    ALoadingSpinner: () => process.browser ? import('theme/components/atoms/a-loading-spinner.vue') : null,
    OPagination: () => process.browser ? import('theme/components/organisms/o-pagination') : null,
    MSelectedFilters: () => process.browser ? import('theme/components/molecules/m-selected-filters') : null,
    SidebarMobileFilters: () => process.browser ? import('theme/components/molecules/m-sidebar-mobile-filters') : null,
    OSidebarFiltersInner: () => process.browser ? import('theme/components/organisms/o-sidebar-filters-inner') : null,
    CategoriesFilter,
    JsonLdFilters
  },
  mixins: [
    DeviceType,
    GoogleTagManager,
    Delivery
  ],
  directives: { intersect },
  data () {
    return {
      browserWidth: 0,
      isFilterSidebarOpen: false,
      filterSidebarInnerOpen: '',
      categoriesAreShown: false,
      isShowCategoryFilter: false,
      openNotice: true,
      is18plus: false,
      selectedFilters: {},
      updateKey: 0,
      hydrated: false,
      hasIntersected: false,
      sortOrder: 'popularity:desc',
      loadedShippingDetails: false
    };
  },
  computed: {
    ...mapState({
      getAggregations: state => state['category-extension'].aggregations,
      getCategories: state => state['category'].list,
      humanFilterMeta: state => state['category'].humanFilterMeta.data,
      getBreadcrumbsRoutes: state => state['breadcrumbs'].routes,
      getBreadcrumbsCurrent: state => state['breadcrumbs'].current,
      productsInCart: state => state['cart'].cartItems,
      getAttributeListByCode: state => state['attribute'].list_by_code,
      getFiltersMap: state => state['category-extension'].filtersMap,
      getFilterPrice: state => state['category-extension'].filterPrice,
      // getDefaultShop: state => state['shipping'].defaultShop,
      categoryLoader: state => state.ui.categoryLoader,
      questions: state => state.faq.questions,
      productsIsLoading: state => state['category'].productsIsLoading,
      isAdditionalLoading: state => state['category'].isAdditionalLoading,
      categoriesIsLoading: state => state['category'].categoriesIsLoading,
      getProducts: state => state['category'].products,
      productCountsByCategory: state => state['category'].productCountsByCategory,
      currentCategoryDescription: state => state['category'].categoryDescription,
      availableProducts: state => state['category'].availableProducts,
      categoryIsNoIndexFollow: state => state['category'].categoryIsNoIndexFollow,
      categoryProductsForPromotionCount: state => state['category'].categoryProductsForPromotionCount
    }),
    ...mapGetters({
      getCurrentSearchQuery: 'category-extension/getCurrentSearchQuery',
      getCurrentCategory: 'category/getCurrentCategory',
      getCategoryProductsTotal: 'category-next/getCategoryProductsTotal',
      getAvailableFilters: 'category-extension/getAvailableFilters',
      getCurrentFiltersHF: 'category-extension/getCurrentFiltersHF',
      getSystemFilterNames: 'category-next/getSystemFilterNames',
      isNewPost: 'shipping-module/isCurrentNewPost',
      isLoggedIn: 'user/isLoggedIn',
      getBannersMiddle: 'promoted/getBannersMiddle',
      getBannersTop: 'promoted/getBannersTop',
      getProductPromotionBanners: 'promoted/getProductPromotionBanners',
      shippingDetails: 'shipping/getShippingDetails',
      getCategoriesMap: 'category/getCategoriesMap',
      getDefaultShop: 'shipping/getDefaultShop',
      isLoadedShippingDetails: 'shipping/isLoadedShippingDetails'
    }),
    getHumanFilters () {
      return new HumanFilter(this.$route.fullPath)
    },
    getCurrentFilters () {
      const humanFilter = this.getHumanFilters.getUri()

      return this.getCurrentFiltersHF(humanFilter)?.filters
    },
    currentCategory () {
      const route = this.$route;
      const params = route.params;
      return this.getCurrentCategory(params)
    },
    categoriesForFilter () {
      if (!this.getCategoriesMap || Object.keys(this.getCategoriesMap).length === 0) return []
      return this.getCategoriesMap[this.currentRootCategory?.id]?.children_data || []
    },
    isProductsNotFound () {
      return (this.productsBeforeInoBanner.length === 0) || this.isCategoryEmpty
    },
    isLoading () {
      return this.categoryLoader || this.productsIsLoading
    },
    isCategoryRootPage () {
      return this.isCurrentCategoryRoot && !this.currentCategory['is_not_product_category']
    },
    isOverlay () {
      return this.currentCategory.is_18_plus && this.openNotice && !this.is18plus
    },
    getRelatedCategories () {
      return this.currentCategory?.related_category
    },
    getMarkdownCount () {
      if (!this.getAggregations.hasOwnProperty('agg_terms_markdown_id')) {
        return 0
      }

      const buckets = this.getAggregations['agg_terms_markdown_id']?.buckets || []
      const length = buckets.length

      return length || 0
    },
    isCategoryEmpty () {
      return this.getCategoryProductsTotal === 0;
    },
    isProductNone () {
      return !this.isLoading && this.products.length === 0;
    },
    breadcrumbs () {
      return this.getBreadcrumbsRoutes
        .map(route => ({
          text: htmlDecode(route.name),
          route: {
            link: route.route_link
          }
        }))
        .concat({
          text: htmlDecode(this.getBreadcrumbsCurrent)
        });
    },
    products () {
      return this.getProducts.map(prepareCategoryProduct);
    },
    cardInRow () {
      return this.isTablet ? 6 : 8
    },
    productsBeforeInoBanner () {
      return this.products.slice(0, this.cardInRow);
    },
    productsAfterInoBanner () {
      return this.products.slice(this.cardInRow, this.products.length);
    },
    hasBannerTop () {
      if (this.isCurrentCategoryRoot) {
        return !![...this.getBannersTop, ...this.getProductPromotionBanners].length
      }

      return !!this.getBannersTop.length
    },
    bannerTop () {
      if (this.isCurrentCategoryRoot) {
        return [...this.getBannersTop, ...this.getProductPromotionBanners]
      }

      return this.getBannersTop
    },
    hasBannerMiddle () {
      return !!this.getBannersMiddle.length
    },
    productsAfterInoBannerCount () {
      return THEME_PAGE_SIZE - this.cardInRow
    },
    totalPages () {
      return Math.ceil(this.getCategoryProductsTotal / THEME_PAGE_SIZE);
    },
    sortOptions () {
      return Object.entries(config.products.sortByAttributes).map(attribute => {
        const [label, id] = attribute;
        return { id, label };
      });
    },
    sortLabel () {
      const selectedSortOrder =
        this.sortOptions.find(sortOption => sortOption.id === this.sortOrder) ||
        {};
      return selectedSortOrder.label || '';
    },
    availableFilters () {
      const filtersArray = this.getFiltersMap[this.currentCategory?.id] || {};
      const { getSystemFilterNames } = this;

      return Object.entries(filtersArray)
        .filter(([filterType, filters]) => filters.length && !getSystemFilterNames.includes(filterType))
        .reduce((result, [filterType, filters]) => {
          result[filterType] = filters
            .filter(filter => filter.id !== '0')
          return result;
        }, { price: this.getPrice() });
    },
    filterLabels () {
      const attrArr = Object.values(this.getAttributeListByCode)
      const labels = {}
      attrArr.forEach(item => { labels[`${item.attribute_code}`] = item?.frontend_label === undefined ? '' : item.frontend_label })

      return labels
    },
    activeFiltersCount () {
      let counter = 0;

      if (this.getCurrentSearchQuery.sort !== undefined && this.getCurrentSearchQuery.sort !== 'popularity:desc') {
        counter = 1;
      } else {
        counter = 0;
      }

      const keys = Object.keys(this.$route.params).filter(i => (
        ['stock_shop', 'has_promotion_in_stores'].includes(i)
      ))

      counter += keys.length

      Object.keys(this.getCurrentFilters).forEach(key => {
        counter += this.getCurrentFilters[key].length;
      });

      return counter;
    },
    isFilterActive () {
      return filter =>
        castArray(this.getCurrentFilters[filter.type]).find(
          variant => variant && variant.id === filter.id
        ) !== undefined;
    },
    isCurrentCategoryRoot () {
      return (
        this.currentCategory.parent_id ===
        config.entities.category.categoriesRootCategoryId
      );
    },
    getSeoHtml () {
      return this.currentCategoryDescription || ''
    },
    categoryProductsTotal () {
      return this.$tc('{count} items', this.getCategoryProductsTotal)
    },
    categoryMetaContent () {
      return getMetaContent(this.currentCategory, this.$route, this.humanFilterMeta)
    },
    currentCategoryId () {
      return this.currentCategory.id
    },
    perPage () {
      return THEME_PAGE_SIZE;
    },
    currentRootCategory () {
      if (this.getCategories.length === 0) return null
      if (Object.values(this.getCategoriesMap).length === 0) return null

      const currentCategorySlug = this.$route.params.slug
      const currentCategory = Object.values(this.getCategoriesMap).find(category => category.slug === currentCategorySlug)
      const rootCategoryId = Number(currentCategory?.path.split('/')[2])
      const rootCategory = this.getCategories.find(category => category.id === rootCategoryId)
      return rootCategory
    },
    currentPage () {
      return Number(this.$route.query.page) || 1;
    },
    isDarkstore () {
      const shop = this.getShop();
      return Boolean(+shop?.is_darkstore);
    },
    showMarkdownFilter () {
      return this.loadedShippingDetails && this.isDarkstore
    },
    eventList () {
      return {
        item_list_id: this.currentCategory.id,
        item_list_name: this.currentCategory.name
      }
    }
  },
  watch: {
    currentCategory: {
      handler: async function (newCategory, oldCategory) {
        if (newCategory.id === oldCategory.id) return

        this.$store.dispatch(
          'category/loadCategoryBreadcrumbs',
          {
            category: newCategory,
            currentRouteName: newCategory.name,
            omitCurrent: true
          }
        )
        this.$store.dispatch('faq/loadFAQForCategory', { id: this.currentCategoryId })
      },
      deep: true
    },
    currentCategoryId: {
      handler: function (newId, oldId) {
        if (newId === oldId) return

        this.$store.dispatch('promoted/updateBannerInform', { categoryId: newId });
        this.eSputnikActions()
        this.admitadRetagCategoryPage(newId)
        if (isServer) return

        this.callGtmActions()
      },
      immediate: true
    },
    'shippingDetails.shopId': {
      handler: async function (newValue, oldValue) {
        if (!newValue || newValue === oldValue) return

        const page = this.$route.query.page || this.$route.params.page || 0
        const query = this.$route.query

        if (query.markdown && oldValue !== undefined) {
          this.resetMarkdownFilter()
          return
        }

        await this.$store.dispatch('category/loadCategoryProducts', {
          route: this.$route,
          category: this.currentCategory,
          pageSize: THEME_PAGE_SIZE,
          start: page ? (page - 1) * THEME_PAGE_SIZE : page
        })

        if (this.currentCategory.parent_id === config.entities.category.categoriesRootCategoryId) {
          await this.$store.dispatch('category-extension/loadChildCategories', {
            category: this.currentCategory
          });
        }
      }
    },
    getCurrentFilters: {
      handler (val, oldVal) {
        if (!Object.keys(oldVal).length && !Object.keys(this.selectedFilters).length) {
          this.updateSelectedFiltersFromStore(val);
          return
        }

        if (JSON.stringify(val) === JSON.stringify(oldVal)) {
          return
        }

        this.updateSelectedFiltersFromStore(val);
      },
      deep: true
    },
    'getCurrentSearchQuery.sort': {
      handler (val, oldVal) {
        if (!oldVal && !this.sortOrder) {
          this.updateSortOrderFromStore(val);
          return
        }

        if (val === oldVal) {
          return
        }

        this.updateSortOrderFromStore(val);
      }
    },
    isLoadedShippingDetails: {
      handler (val) {
        if (val) {
          this.loadedShippingDetails = true
        }
      }
    }
  },
  async asyncData ({ store, route, context }) {
    if (isServer) {
      await Promise.all([
        store.dispatch('ui/loadCatalogMenu'),
        store.dispatch('promoted/updatePreHeaderBanners'),
        store.dispatch('config-varus/get', { path: ['header_logo_src'] })
      ])
      const currentCategory = await composeCategoryState(store, route)
      await store.dispatch('category/loadCategoryBreadcrumbs', {
        category: currentCategory,
        currentRouteName: currentCategory.name,
        omitCurrent: true
      })
      store.dispatch('category/setCategoryDescription', currentCategory?.description)
      store.dispatch('category/setCategoryIsNoindexFollow', currentCategory?.is_index_nofollow)
    }

    const result = await store.dispatch('category/checkHumanFilterAttributes', { fullPath: route?.fullPath })

    if (isServer && result?.to && context.server) {
      context.server.response.redirect(301, result.to)
    }

    if (isServer) return
    isAlreadyLoaded = true;

    try {
      const currentCategory = await composeCategoryState(store, route);

      if (currentCategory.id === config.entities.category.categoriesRootCategoryId) {
        return
      }
      const rootCategory = store.getters['category/getCurrentRootCategory'](route.params)
      store.dispatch('category/loadCategoriesCounts', { rootCategory })
      await loadProducts(store, route, currentCategory, route?.query?.page || 0)
      store.dispatch('category/setCategoryDescription', currentCategory?.description)
      store.dispatch('category/setCategoryIsNoindexFollow', currentCategory?.is_index_nofollow)
      store.dispatch('category/loadCategoryProductsForPromotionCount', {
        route: route,
        category: currentCategory,
        start: 0
      })
    } catch (e) {
      store.dispatch('ui/categoryLoader', false)
    } finally {
      if (result?.to && !isServer) {
        window.location.replace(result.to)
      }
    }
  },
  beforeMount () {
    if (localStorage.getItem('is18plus')) {
      this.is18plus = localStorage.getItem('is18plus');
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getBrowserWidth);
  },
  async mounted () {
    if (Object.keys(this.getCurrentFilters).length) {
      this.updateSelectedFiltersFromStore(this.getCurrentFilters);
    }
    if (this.getCurrentSearchQuery.sort) {
      this.updateSortOrderFromStore(this.getCurrentSearchQuery.sort);
    }

    this.eSputnikActions()
    await this.$store.dispatch('category/loadCategoryBreadcrumbs', {
      category: this.currentCategory,
      currentRouteName: this.currentCategory.name,
      omitCurrent: true
    })

    this.loadedShippingDetails = this.isLoadedShippingDetails

    if (isAlreadyLoaded) return;

    try {
      const currentCategory = await composeCategoryState(this.$store, this.$route)
      this.$store.dispatch('category/loadCategoriesCounts', { currentCategory })
      this.$store.dispatch('category/setCategoryDescription', currentCategory?.description)
      this.$store.dispatch('category/setCategoryIsNoindexFollow', currentCategory?.is_index_nofollow)
      await loadProducts(this.$store, this.$route, currentCategory, this.$route?.query?.page || 0)
      this.$store.dispatch('category/loadCategoryProductsForPromotionCount', {
        route: this.$route,
        category: currentCategory,
        start: 0
      })
    } catch (e) {
      this.$store.dispatch('ui/categoryLoader', false)
    }
  },
  async beforeRouteLeave (to, from, next) {
    this.categoriesAreShown = false
    await this.loadMore(false)
    // await this.setProductsLoading(true)
    next();
  },
  methods: {
    ...mapActions({
      loadMore: 'category/loadMore',
      changeRouterFilterParametersHF: 'category/changeRouterFilterParametersHF',
      setProductsLoading: 'category/setProductsLoading'
    }),
    resetMarkdownFilter () {
      const hf = new HumanFilter(this.$route.fullPath)
        .clearQuery(['markdown'])

      this.changeRouterFilterParametersHF(hf.toString())
    },
    async setCurrentCategory (category) {
      this.toggleCategoriesFilters();
      const link = category ? category.link : (
        this.currentRootCategory.link || this.getBreadcrumbsRoutes?.[0]?.route_link
      );
      await this.$router.push(this.localizedRoute(link))
    },
    getBrowserWidth () {
      return (this.browserWidth = window.innerWidth);
    },
    forNpFilterChanged ({ type }) {
      const hf = new HumanFilter(this.$route.fullPath)
        .toggleFilter({ id: 'for_np', type })
        .clearQuery(['page'])

      return this.updateAndRouteNew(hf.toString())
    },
    changeSortOder (sortOrder) {
      if (this.getCurrentSearchQuery.sort !== sortOrder) {
        this.loadMore(false);
        this.sortOrder = sortOrder;

        const hf = new HumanFilter(this.$route.fullPath)

        if (sortOrder === 'popularity:desc') {
          hf.clearQuery(['sort'])

          this.updateAndRouteNew(hf.toString())

          return
        }

        hf.setQuery('sort', [sortOrder])

        this.updateAndRouteNew(hf.toString())
      }
    },
    stockFilterChanged ({ type }) {
      const hf = new HumanFilter(this.$route.fullPath)
        .toggleFilter({ id: 'in_stock', type })
        .clearQuery(['page'])

      return this.updateAndRouteNew(hf.toString())
    },
    markdownFilterChanged ({ type }) {
      const hf = new HumanFilter(this.$route.fullPath)
        .toggleFilter({ id: 'only_markdown', type })
        .clearQuery(['page'])

      return this.updateAndRouteNew(hf.toString())
    },
    async changeFilter (filter) {
      const route = new HumanFilter(this.$route.fullPath)
        .toggleFilter(filter)
        .clearQuery(['page'])

      this.updateSelectedFilters(filter)
      await this.loadMore(false);

      this.updateAndRouteNew(route.toString())
    },
    toggleFilter (queryFilter, filterId) {
      return queryFilter.includes(filterId)
        ? queryFilter.filter(id => id !== filterId)
        : [...queryFilter, filterId];
    },
    async updateAndRouteNew (row) {
      await this.setProductsLoading(true);
      this.changeRouterFilterParametersHF(row);
    },
    async clearFilters () {
      const slug = new HumanFilter(this.$route.fullPath).getSlug(true)

      this.selectedFilters = {};
      await this.loadMore(false);

      const cat = this.$route.query?.cat ? `?cat=${this.$route.query.cat}` : '';
      this.changeRouterFilterParametersHF(`${slug}${cat}`);
    },
    clearPrice () {
      const hf = new HumanFilter(this.$route.fullPath)

      hf.clearQuery(['price'])

      this.updateAndRouteNew(hf.toString());
    },
    filterSidebarInner (filter) {
      this.filterSidebarInnerOpen = filter
    },
    toggleCategoriesFilters () {
      this.categoriesAreShown = !this.categoriesAreShown
    },
    async promoFilterChanged ({ type }) {
      const hf = new HumanFilter(this.$route.fullPath)
        .toggleFilter({ type })
        .clearQuery(['page'])

      return this.updateAndRouteNew(hf.toString())
    },
    getPrice () {
      const { agg_price } = this.getAggregations;
      const price = {
        type: 'price',
        from: 0,
        to: 0
      };

      if (agg_price && Object.keys(agg_price).length > 0) {
        const { filter } = agg_price;
        price.from = filter['price_max']?.value ? Math.trunc(filter['price_max'].value) : 0;
        price.to = filter['price_min']?.value ? Math.trunc(filter['price_min'].value) : 0;
      }

      return price;
    },
    submitFilterPrice (minPrice, maxPrice) {
      const hf = new HumanFilter(this.$route.fullPath)

      hf.setQuery('price', [`${minPrice}-${maxPrice}`])

      this.updateAndRouteNew(hf.toString())
    },
    async onIntersect (entry) {
      if (!entry.isIntersecting) {
        return;
      }

      if (this.loading) {
        return;
      }

      if (this.loaded) {
        return;
      }

      await this.$store.dispatch('homepage/loadAdvantagesContent')
      await this.$store.dispatch('faq/loadFAQForCategory', { id: this.currentCategoryId })

      this.hasIntersected = true;
    },
    async eSputnikActions () {
      eSputnikEvent('CategoryPage', this.currentCategory.name)
    },
    callGtmActions () {
      this.gtmViewItemListHandler(this.products, 'view_item_list', this.eventList)
      this.gtmProductsHandlerAds(this.products, 'view_item_list_ads')
    },
    updateSelectedFilters (filter) {
      const { type, id } = filter;

      if (!this.selectedFilters[type]) {
        this.$set(this.selectedFilters, type, [filter]);
        return;
      }

      if (this.selectedFilters[type]) {
        const isFilterActive = this.selectedFilters[type].find(item => item.id === id);
        let newFilters = [];

        if (isFilterActive) {
          newFilters = this.selectedFilters[type].filter(item => item.id !== id);
        } else {
          newFilters = [...this.selectedFilters[type], filter];
        }

        if (newFilters.length === 0) {
          delete this.selectedFilters[type];
        } else {
          this.selectedFilters[type] = newFilters;
        }
      }
    },
    updateSelectedFiltersFromStore (filters = this.getCurrentFilters) {
      this.selectedFilters = JSON.parse(JSON.stringify(filters));
    },
    updateSortOrderFromStore (sortOrder = this.getCurrentSearchQuery.sort) {
      this.sortOrder = sortOrder || 'popularity:desc';
    },
    onLoadMore () {
      this.loadMore(true);
    },
    onPageChanged () {
      this.loadMore(false);
    },
    onPaginationClick () {
      this.setProductsLoading(true)
    },
    getShop () {
      const tmsId = this.shippingDetails?.shop?.tms_id;
      return this.getShops?.find((i) => i.tms_id === tmsId) || this.shippingDetails?.shop;
    }
  },
  metaInfo: metaCategory
};
</script>

<style lang="scss" scoped>
@import "~theme/css/mixins";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.category {
  &__np {
    margin: 0 var(--spacer-10) var(--spacer-20)
  }

  max-width: var(--max-width);
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;

  ::v-deep {
    .m-sidebar-mobile-filters .o-filter-stock, .m-sidebar-mobile-filters .o-filter-promo {
      display: flex;
      margin-right: var(--spacer-sm);

      @include for-mobile {
        position: relative;
        margin: 0 0 20px;
      }
    }
  }
}

.category-footer {
  padding: 0;

  &__wrapper {
    position: relative;
  }

  @include for-desktop {
    padding: 0 var(--spacer-10);
  }

  .m-seo-block {
    padding: 0 var(--spacer-10) var(--spacer-100);

    @include for-tablet {
      padding-left: var(--spacer-17);
      padding-right: var(--spacer-18);
    }
  }

  .m-recently-viewed-products {
    ::v-deep {

      @include for-tablet {
        padding-top: var(--spacer-20);
      }
      .o-section {
        padding: 0;

        &--center {
          padding: 0;
        }

        &__heading {
          @include for-mobile {
            padding: 0 var(--spacer-10) var(--spacer-15) var(--spacer-10);
          }

          @include for-tablet {
            padding: 0 var(--spacer-16) var(--spacer-15) 0;
          }
        }
      }

      @media (min-width: $tablet-min) {
        .sf-carousel__slides {
          padding-bottom: var(--spacer-22);
        }
      }

      .glide__slide .sf-product-card {
        padding: 0;
      }
    }

    @include for-tablet {
      margin-left: var(--spacer-16);
    }
  }

  .m-advantages {
    padding-top: var(--spacer-55);
    padding-bottom: var(--spacer-50);

    @include for-tablet {
      padding-top: var(--spacer-60);
      padding-bottom: var(--spacer-60);
      line-height: var(--spacer-34);
    }

    @include for-desktop {
      padding-top: var(--spacer-30);
      padding-bottom: var(--spacer-2xl);
      line-height: var(--spacer-34);
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacer-10) var(--spacer-50);

  @include for-tablet {
    padding-left: var(--spacer-17);
    padding-right: var(--spacer-18);
  }

  @include for-desktop {
    flex-direction: row;
  }

  &.is18plus-overlay {
    position: relative;
    filter: blur(var(--spacer-5));
    cursor: auto;
    pointer-events: none;

    .m-related-categories {
      ::v-deep {
        ul {
          filter: blur(var(--spacer-3));
        }
      }
    }
  }
}
.breadcrumbs {
  padding: var(--spacer-base) var(--spacer-base) var(--spacer-base) var(--spacer-sm);
}

.navbar {
  position: relative;
  display: flex;

  ::v-deep .sf-heading__title {
    @include for-mobile {
      --heading-title-color: --black;
      --heading-title-font-size: var(--font-size-20);
      line-height: 1.2;
      text-align: left;
    }

    @include for-tablet {
      --heading-title-font-size: var(--font-size-32);
    }
  }

  &.section {
    padding: 0 var(--spacer-10);
    margin-bottom: var(--spacer-10);
    min-height: px2rem(24.5);

    @media (min-width: $tablet-min) {
      padding-left: var(--spacer-16);
      padding-right: var(--spacer-16);
      margin-bottom: var(--spacer-sm);
      min-height: px2rem(38.5);
    }
  }

  &__aside {
    align-items: center;
    display: flex;
    flex: 0 0 15%;
    padding: var(--spacer-sm) var(--spacer-sm);
    border: solid var(--c-light);
    border-width: 0 1px 0 0;
  }

  &__main {
    .sf-heading {
      --heading-padding: 0 var(--spacer-20) 0 0;
    }
  }

  &__filters-button {
    display: flex;
    align-items: center;
    font-size: 1rem;
    grid-column: 1;
    justify-self: start;

    &:hover {
      .sf-icon {
        fill: var(--c-primary);
      }
    }

    @include for-mobile {
      --button-text-transform: uppercase;
      font-size: var(--font-xs);

      &.sort-by__button {
        order: 1;
      }
    }
  }

  &__filter {
    display: flex;
    grid-area: filter;
  }

  &__filters-icon {
    margin: 0 var(--spacer-sm) 0 0;
  }

  &__label {
    font-family: var(--font-family-secondary);
    font-weight: var(--font-normal);
    color: var(--dark-gray);
    margin: 0 var(--spacer-2xs) 0 0;
    font-size: var(--font-size-13);
    white-space: nowrap;

    transition: opacity 200ms;
    opacity: 1;

    &--hide {
      opacity: 0;
    }
  }

  &__select {
    --select-padding: 0 var(--spacer-lg) 0 var(--spacer-2xs);
    --select-margin: 0;
    --select-selected-padding: 0 var(--spacer-xl) 0 0;

    .dropdown-chevron {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-self: end;
    grid-area: sort;

    .sf-select-option--is-active {
      color: var(--c-primary);
      pointer-events: none;
    }

    @include for-desktop {
      justify-self: end;
    }
  }

  &__counter {
    display: inline;
    font-family: var(--font-family-secondary);
  }

  &__view {
    display: flex;
    align-items: center;
    margin: 0 var(--spacer-xl);

    @include for-desktop {
      margin: 0 0 0 var(--spacer-2xl);
    }

    &-icon {
      cursor: pointer;
    }

    &-label {
      margin: 0 var(--spacer-sm) 0 0;
      font: var(--font-medium) var(--font-xs) / 1.6 var(--font-family-secondary);
      text-decoration: underline;
    }
  }

  &__title {
    display: inline;

    ::v-deep .sf-heading__title {
      padding: 0;
      display: inline;
      @include header-title;
    }
  }
}

.sort-by {
  --select-dropdown-z-index: 3;
  flex: unset;
  padding: 0;

  ::v-deep {
    .sf-select__dropdown {
      left: initial;
      min-width: max-content;
      right: 0;
    }

    .sf-select-option {
      cursor: pointer;
    }
  }

  // This is needed to hide SfSelect main element on mobile view.
  // This code can be removed when SfSelect supports better customization.
  @include for-mobile {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    --select-dropdown-z-index: 2;

    ::v-deep .sf-select__cancel {
      margin: 16px;
      box-shadow: 4px 12px 24px rgba(119, 121, 122, 0.25);
      --button-width: calc(100% - 32px);
    }
  }
}

.sidebar {
  flex: 100%;

  @include for-desktop {
    padding: 0;
    flex: 0 0 13.75rem;
    border: none;
    margin-right: var(--spacer-20);
    max-width: 13.75rem;
  }

  &-filters {
    background: var(--light-gray);
    border-radius: 10px;
    padding: var(--spacer-sm) var(--spacer-20);
    margin-top: var(--spacer-10);
    --sidebar-title-display: none;
    --sidebar-top-padding: 0;

    @include for-desktop {
      --sidebar-content-padding: 0 var(--spacer-xl);
      --sidebar-bottom-padding: 0 var(--spacer-xl);
    }
  }

  &-hide {
    display: none!important;

    &--mobile {
      display: none;
    }
  }
}

.sidebar-filters {
  position: relative;
  --sidebar-title-display: none;
  --sidebar-top-padding: 0;

  @include for-desktop {
    --sidebar-content-padding: 0 var(--spacer-xl);
    --sidebar-bottom-padding: 0 var(--spacer-xl);
    padding: 0 var(--spacer-20);
    max-width: px2rem(220);
  }
}

.list {
  --menu-item-font-size: var(--font-sm);

  &__item {
    &:not(:last-of-type) {
      --list-item-margin: 0 0 var(--spacer-sm) 0;
    }
  }
}

.products {
  box-sizing: border-box;
  flex: 1;
  margin: 0;
  overflow: hidden;

  &__navbar-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-zircon);
    padding-bottom: var(--spacer-10);
    margin-bottom: var(--spacer-10);
  }

  ::v-deep &__grid {
    display: flex;
    flex-wrap: wrap;

    @include for-desktop {
      width: 100%;

      ::v-deep .sf-product-card {
        &.sf-product-card--out-of-stock-container:hover:before {
          bottom: calc(-1 * var(--spacer-5));
        }
        &:hover {
          &:before {
            bottom: calc(-1 * var(--spacer-35));
          }
          .sf-product-card__hidden-basket {
            position: absolute;
          }
        }
        .sf-product-card__hidden-basket {
          position: absolute;
          left:5px;
          bottom: -15px;
        }
      }
    }

    &.top-grid {
      padding-top: var(--spacer-10);
    }
  }

  &__slide-enter {
    opacity: 0;
    transform: scale(0.5);
  }

  &__slide-enter-active {
    transition: all 0.2s ease;
    transition-delay: calc(0.1s * var(--index));
  }

  &__load-more {
    background-color: transparent;
    font-weight: 400;
    color: var(--orange);
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--orange-hover);
    }
  }

  @include for-desktop {
    margin: 0;

    &__pagination {
      display: flex;
      justify-content: center;
      margin: 0;
      background-color: var(--light-gray);
      padding: var(--spacer-20);
    }

    &__product-card {
      flex: 1 1 25%;
    }

    &__list {
      margin: 0 0 0 var(--spacer-sm);
    }
  }

  &__not-found {
    margin-top: var(--spacer-30);
    --heading-text-align: left;

    ::v-deep .sf-heading__title {
      color: var(--dark-gray);
      margin-bottom: var(--spacer-10);
      font-size: var(--font-size-16);
      line-height: var(--font-size-19);

      @include for-desktop {
        margin-bottom: var(--spacer-18);
        font-size: var(--font-size-24);
        line-height: var(--font-size-25);
      }
    }

    ::v-deep .sf-heading__subtitle {
      color: var(--black);
      font-size: var(--font-size-14);
      line-height: var(--font-size-17);

      @include for-desktop {
        font-size: var(--font-size-16);
        line-height: var(--font-size-19);
      }
    }
  }

  &__change-city {
    margin-top: var(--spacer-20);
    width: px2rem(137);
    height: var(--spacer-40);

    @include for-desktop {
      font-size: var(--font-size-18);
      margin-top: var(--spacer-30);
      width: px2rem(228);
      height: var(--spacer-56);
    }

    @media (max-width: $mobile-max) {
      padding: var(--spacer-11) var(--spacer-sm);
      font-size: var(--font-size-14);
      line-height: var(--font-size-24);
    }
  }
}

.bottom-plp-block {
  margin-left: calc(-1 * var(--spacer-10));
  margin-right: calc(-1 * var(--spacer-10));
  width: calc(100% + calc(2 * var(--spacer-10)));

  @media (min-width: $tablet-min) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.filters {
  &__title {
    --heading-title-font-size: var(--font-xl);
    margin: var(--spacer-xl) 0 var(--spacer-base) 0;

    &:first-child {
      margin: calc(var(--spacer-xl) + var(--spacer-base)) 0 var(--spacer-xs) 0;
    }
  }

  &__color {
    margin: var(--spacer-xs) var(--spacer-xs) var(--spacer-xs) 0;
    border: 1px solid var(--c-light);
  }

  &__item {
    --filter-label-color: var(--c-secondary-variant);
    --filter-count-color: var(--c-secondary-variant);
    --checkbox-padding: 0 var(--spacer-sm) 0 var(--spacer-xl);
    padding: var(--spacer-sm) 0;
    border-bottom: 1px solid var(--c-light);

    &:last-child {
      border-bottom: 0;
    }

    @include for-desktop {
      --checkbox-padding: 0;
      margin: var(--spacer-sm) 0;
      border: 0;
      padding: 0;
    }
  }

  &__accordion-item {
    --accordion-item-content-padding: 0;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
  }

  &__buttons {
    margin: var(--spacer-sm) 0;
  }

  &__button-clear {
    --button-background: var(--c-light);
    --button-color: var(--c-dark-variant);
    margin: var(--spacer-xs) 0 0 0;
  }
}

.center {
  padding: 0 var(--spacer-sm);
  box-sizing: border-box;

  @include for-desktop {
    max-width: var(--max-width);
    margin: 0 auto;
  }
}

.m-category-advantages {
  padding-top: 0;
}

.banner-info-wrap {
  margin: px2rem(10) 0 px2rem(20);
  position: relative;
  transform: none;

  &.banner-missed {
    .slider-carousel {
      display: none;
    }
  }

  &--last {
    margin-bottom: px2rem(50);

    &.banner-missed {
      margin: calc(-1 * var(--spacer-30)) 0 var(--spacer-30);
      border-top: 1px solid var(--color-zircon);
      position: relative;
      z-index: -1;
    }

    ::v-deep {
      .glide__track {
        padding-top: 0;
      }
    }
  }

  ::v-deep {
    .sf-banner-plp {
      background-position: right;

      @media (max-width: $mobile-max) {
        min-height: px2rem(115);
        padding: 0 var(--spacer-25) var(--spacer-20) ;
      }

      @media (min-width: $tablet-min) {
        min-height: px2rem(115);
        padding: 0 var(--spacer-30) var(--spacer-25) ;
      }

      @include for-desktop {
        min-height: px2rem(150);
        padding: 0 var(--spacer-56) var(--spacer-25) ;
      }

      &__description {
        font-size: var(--font-size-14);
        line-height: var(--font-size-17);

        @media (min-width: $tablet-min) {
          font-size: var(--font-size-24);
          line-height: var(--font-size-25)
        }
      }
    }

    .sf-banner__container {
      padding-right: var(--spacer-50);
      flex: 1 1 var(--banner-container-width, 100%);
    }
  }

  @include for-tablet {
    transform: none;
  }

  @include for-desktop {
    margin-top: 0;
  }

  &:empty {
    margin: 0;
  }
}
</style>
