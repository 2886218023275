<template>
  <div class="m-recently-viewed-products">
    <OSection
      :title-heading="$t('Recently viewed products')"
      class="carousel o-section--slider"
      :class="displayedItemsLength > 6 ? '' : 'carousel__without-arrows'"
      v-show="displayedItems.length"
      :is-centered="true"
    >
      <template #link>
        <SfLink
          :link="localizedRoute('/my-account/viewed')"
          class="sf-link--primary all-products-link"
        >
          {{ $t('All goods') }}
        </SfLink>
      </template>
      <MProductCarousel
        list-id="recently_viewed"
        :list-name="$t('Recently viewed products')"
        :products="displayedItems"
        :key="carouselUpdateKey"
        arrow-color="gray"
        :show-qty-permanently="showQtyPermanently"
        :lazy="true"
      />
    </OSection>
  </div>
</template>

<script>
import { BREAKPOINT_SM, BREAKPOINT_MD } from 'theme/helpers/breakpoints';
import OSection from 'theme/components/organisms/o-section';
import MProductCarousel from 'theme/components/molecules/m-product-carousel';
import RecentlyViewed from '@vue-storefront/core/modules/recently-viewed/components/RecentlyViewed';
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { mapActions, mapGetters } from 'vuex';
import { SfLink } from '@storefront-ui/vue';

export default {
  name: 'MRecentlyViewedProducts',
  components: {
    OSection,
    SfLink,
    MProductCarousel
  },
  mixins: [
    RecentlyViewed
  ],
  props: {
    titleHeading: {
      type: String,
      default: 'Recently viewed products',
      required: false
    },
    isCentered: {
      type: Boolean,
      default: false
    },
    amount: {
      type: Number,
      default: 25,
      required: false
    },
    showQtyPermanently: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sliderSettings: {
        type: 'carousel',
        perView: 6,
        gap: 10,
        animationDuration: 400,
        animationTimingFunc: 'ease-in-out',
        breakpoints: {
          [BREAKPOINT_MD - 1]: {
            perView: 4
          },
          [BREAKPOINT_SM - 1]: {
            perView: 2
          }
        }
      },
      carouselUpdateKey: 0,
      displayedItems: []
    }
  },
  computed: {
    ...mapGetters({
      shippingDetails: 'shipping/getShippingDetails'
    }),
    itemsSku () {
      return this.items.map(e => e.sku) || []
    },
    displayedItemsLength () {
      return this.displayedItems?.length || 0
    }
  },
  watch: {
    'shippingDetails.shopId': {
      handler: async function (newValue, oldValue) {
        if (!newValue || newValue === oldValue) return
        await this.updateRecentlyViewedProducts()
        this.displayedItems = this.getDisplayedItems() || []
        this.updateCarouselComponent()
      }
    },
    itemsSku (newValue, oldValue) {
      const valueChanged = JSON.stringify(newValue) !== JSON.stringify(oldValue)
      if (!valueChanged) return
      this.displayedItems = this.getDisplayedItems() || []
      this.updateCarouselComponent()
    }
  },
  updated () {
    this.$bus.$emit('seo/check-duplicate-attr-alt')
  },
  async created () {
    this.displayedItems = this.getDisplayedItems() || []
    this.updateCarouselComponent()
  },
  methods: {
    ...mapActions({
      updateRecentlyViewedProducts: 'recently-viewed/updateRecentlyViewedProducts'
    }),
    updateCarouselComponent () {
      if (this.carouselUpdateKey > 10) this.carouselUpdateKey = 0
      this.carouselUpdateKey++
    },
    getDisplayedItems () {
      let itemsRuStore = this.items.filter(elem => elem.store === 'ru')
      let itemsUaStore = this.items.filter(elem => elem.store === 'ua')

      if (currentStoreView().storeCode === 'ua') {
        return itemsUaStore.slice(0, this.amount)
      } else {
        return itemsRuStore.slice(0, this.amount)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.m-recently-viewed-products {
  .carousel__without-arrows {
    ::v-deep {
      .o-section__heading {
        @include for-desktop {
          padding-right: var(--spacer-20);
        }
      }
    }
  }
  ::v-deep {
    .o-section__heading {
      .sf-heading__title--h3 {
        @media (max-width: $mobile-max) {
          font-size: var(--font-size-20);
        }
      }
    }

    .o-section__heading {
      @include for-desktop {
        padding-bottom: var(--spacer-15);
      }
    }

    .sf-carousel__wrapper {
      margin-top: var(--spacer-10);

      .sf-product-card {
        max-width: px2rem(150);

        @include for-tablet {
          max-width: px2rem(170);
        }

        @include for-desktop {
          max-width: 100%;
        }

        .sf-product-card__image {
          padding: var(--spacer-10);

          @include for-tablet {
            padding: 0;
          }

          img {
            padding: 0;
            max-width: px2rem(130);
            height: auto;
            position: static;

            @media (min-width: $tablet-min) {
              max-width: 100%;
              min-width: 170px;
            }
          }
        }

        &__image-wrapper {
          padding: 0;
          text-align: center;
        }

        &__title {
          font-size: var(--font-size-14);
          line-height: var(--font-size-17);
          @media (min-width: $tablet-min) {
            font-size: var(--font-size-15);
            line-height: var(--font-size-18);
          }
        }
      }
    }
  }
}
</style>
