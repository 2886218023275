<template>
  <div class="m-product-add-to-cart">
    <SfAlert
      v-if="alert"
      class="alert"
      :message="alert.message"
      :type="alert.type"
    />
    <AAddToCart
      v-show="showAddToCartButton"
      @addedToCart="addedToCart"
      class="sf-add-to-cart__button"
      :qty="Number(qty)"
      :product="product"
      :is-loading="isLoadingAddToCart"
      :disabled="isAddToCartDisabled"
      :is-icon-button="isIconButton"
      @addItemToCart="addItemToCart"
    />
    <AAddedProduct
      v-if="productInCart && !isQuantityAvailable"
    />
    <no-ssr>
      <AProductQuantity
        v-if="(isShowProductQuantity || productInCart) && isQuantityAvailable"
        v-model="qty"
        class="sf-add-to-cart__select-quantity"
        :show-loader="addingItemToCartFlag || isRemoveLoading"
        :min-quantity-disable="minQtyDisable"
        :max-quantity="maxQty"
        :loading="stock.isLoading"
        :unlimit-quantity="!isSimpleOrConfigurable"
        :is-qty-decimal="isQtyDecimal"
        :measure-kg="MeasureKg"
        :product="product"
        @input="updateQtyInput($event)"
        @error="handleQuantityValidationError"
        @remove="handleRemove"
      />
    </no-ssr>
  </div>
</template>
<script>
import { onlineHelper } from '@vue-storefront/core/helpers';
import { SfAlert } from '@storefront-ui/vue';
import AProductQuantity from 'theme/components/atoms/a-product-quantity';
import AAddToCart from 'theme/components/atoms/a-add-to-cart';
import AAddedProduct from 'theme/components/atoms/a-added-product';
import { mapState, mapActions, mapGetters } from 'vuex';
import config from 'config';
import { eSputnikEvent } from 'theme/helpers/es';
import NoSSR from 'vue-no-ssr';
import { getProductType } from 'theme/helpers/product';
import { isSkuEqual } from '@vue-storefront/core/modules/cart/helpers/productsEquals';

const MIN_QTY = 1;

export default {
  name: 'MProductAddToCart',
  components: {
    SfAlert,
    AProductQuantity,
    AAddToCart,
    AAddedProduct,
    'no-ssr': NoSSR
  },
  data () {
    let qty = MIN_QTY;

    if (this.product.packingtype) {
      const minSalableQty = this.getAttributeValue(this.product, 'minSalableQty')
      const qtyUnitStep = this.getAttributeValue(this.product, 'qtyUnitStep')

      qty = minSalableQty || qtyUnitStep || this.unitStep || MIN_QTY;
    }

    return {
      qty,
      qtyValidationError: '',
      showQuantity: false,
      isRemoveLoading: false,
      addingItemToCartFlag: false
    };
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({})
    },
    stock: {
      type: Object,
      default: () => ({})
    },
    /** to make add to card button as icon */
    isIconButton: {
      type: Boolean,
      default: false
    },
    isQuantityAvailable: {
      type: Boolean,
      default: true
    }
  },
  provide () {
    const provided = {};

    Object.defineProperties(provided, {
      unitStep: {
        get: () => this.unitStep
      },
      isQtyDecimal: {
        get: () => this.isQtyDecimal
      },
      minQty: {
        get: () => this.minQty
      }
    })

    return {
      provided
    };
  },
  mounted () {
    this.$emit('showQtyFieldAfterItemAdded', this.isShowProductQuantity || this.productInCart)
  },
  computed: {
    ...mapState({
      isCartSync: state => state.cart.isSyncing
    }),
    ...mapGetters({
      productsInCart: 'cart/getCartItems',
      productAttributeLabel: 'product/getProductAttributeLabel',
      currentCartHash: 'cart/getCurrentCartHash'
    }),
    productInCart () {
      return this.productsInCart.find(p => isSkuEqual(p, this.product))
    },
    isShowProductQuantity () {
      return this.isQuantityAvailable && this.showQuantity && this.product.type_id !== 'grouped' && this.product.type_id !== 'bundle'
    },
    isOnline () {
      return onlineHelper.isOnline;
    },
    isLoadingAddToCart () {
      return this.stock.isLoading || (this.isCartSync && !this.productsInCart.length) || this.isRemoveLoading
    },
    showAddToCartButton () {
      return (!this.showQuantity && !this.productInCart) || !this.isAvailable
    },
    isAddToCartDisabled () {
      return !!this.qtyValidationError || this.isLoadingAddToCart
    },
    isAvailable () {
      return !this.isOnline ||
        !!this.maxQty ||
        !this.stock.manageQuantity ||
        !this.isSimpleOrConfigurable ||
        !this.stock.is_in_stock
    },
    isSimpleOrConfigurable () {
      return ['simple', 'configurable'].includes(
        this.product.type_id
      );
    },
    isLastProductInCart () {
      return this.productsInCart.length === 1;
    },
    alert () {
      if (this.qtyValidationError) {
        return {
          type: 'danger',
          message: this.qtyValidationError
        }
      }
      if (!this.isAvailable) {
        return {
          type: 'danger',
          message: this.$t('Selected variant is out of stock')
        }
      }
      return false
    },
    maxQty () {
      return Number(this.stock.max)
        ? Number(this.stock.max)
        : (Number(this.stock.qty) || this.minQty);
    },
    minQty () {
      if (!this.isQtyDecimal) return MIN_QTY;

      if (this.isQtyDecimal) {
        return this.MinSalableQuantity || config.products.minQuantityStep;
      }

      return this.product.qtyUnitStep || this.product.stock?.min_sale_qty || this.unitStep || MIN_QTY;
    },
    minQtyDisable () {
      return this.product?.gift?.isPromotion ? this.product?.gift?.limit : null
    },
    isQtyDecimal () {
      return Boolean(this.product.packingtype);
    },
    unitStep () {
      const quantityStep = this.QuantityStepRecommended || this.QuantityUnitStep;

      if (!quantityStep && this.isQtyDecimal) return config.products.minQuantityStep;

      return quantityStep;
    },
    QuantityUnitStep () {
      return this.getAttributeValue(this.product, 'qtyUnitStep');
    },
    QuantityStepRecommended () {
      return this.getAttributeValue(this.product, 'qtyStepRecommend');
    },
    MinSalableQuantity () {
      return this.getAttributeValue(this.product, 'minSalableQty');
    },
    MeasureKg () {
      const { type, packing } = getProductType(this.product)

      return type === 'kg' && packing
    },
    productQty () {
      return this.product.qty
    }
  },
  watch: {
    productQty: {
      immediate: true,
      handler (newValue, oldValue) {
        if (oldValue && oldValue !== newValue) {
          this.qty = newValue
        }
      }
    },
    'productInCart.qty': {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue === oldValue || this.qty === newValue || this.qty === this.productInCart?.client_qty) return

        this.showQuantity = Boolean(newValue);

        if (newValue) {
          this.checkProductInCartQty()
        } else {
          this.qty = this.minQty
        }
      }
    }
  },
  methods: {
    ...mapActions({
      checkCartStateProductStockAndProductPrices: 'cart/checkCartStateProductStockAndProductPrices',
      removeCartItem: 'cart/removeItem',
      cartClear: 'cart/clear',
      sync: 'cart/sync'
    }),
    getAttributeValue (product, code) {
      return product[code] ||
          product[config.attributesCodes[code]]
    },
    addedToCart () {
      this.showQuantity = true
      this.$emit('addedToCart')
      this.$emit('showQtyFieldAfterItemAdded', true)
    },
    handleQuantityValidationError (error) {
      this.qtyValidationError = error
    },
    addItemToCart (flag) {
      this.addingItemToCartFlag = flag;
    },
    updateQtyInput (qty) {
      this.qty = Number(qty)

      this.$emit('input', Number(qty))
    },
    async handleRemove () {
      this.isRemoveLoading = true

      let clearCart = this.isLastProductInCart

      await this.removeCartItem({ product: this.product });
      this.checkCartStateProductStockAndProductPrices();
      this.qty = this.minQty
      this.$emit('remove')
      this.showQuantity = false
      this.$bus.$emit('product-quantity-state-changed', { id: this.product.id, qty: 0 })

      if (clearCart) {
        await this.cartClear({ disconnect: false })
      } else {
        await this.sync({ forceClientState: true, forceSync: true, skipPull: true })
      }

      this.isRemoveLoading = false
      this.esputnikCartEvent()
    },
    resetQuantity () {
      this.qty = this.minQty
      this.showQuantity = false
      this.$emit('resetQtyFieldAfterItemRemoved')
    },
    checkProductInCartQty () {
      if (this.productInCart.qty > this.maxQty) {
        this.qty = this.maxQty
      } else if (this.qty !== this.productInCart?.client_qty) {
        this.qty = this.productInCart.qty
      }
    },
    checkProductQty () {
      if (this.productInCart) {
        this.checkProductInCartQty()
      }
    },
    setProductQty (product) {
      if (product.id !== this.product.id) {
        return
      }

      if (product.qty) {
        this.checkProductQty()
      } else {
        this.resetQuantity()
      }
    },
    esputnikCartEvent () {
      eSputnikEvent('StatusCart', { productsInCart: this.productsInCart, currentCartHash: this.currentCartHash })
    }
  },
  beforeMount () {
    this.$bus.$on('cart-after-delete-products', this.resetQuantity)
    this.$bus.$on('user-after-logout', this.resetQuantity)
    this.$bus.$on('shipping-details-changed', this.checkProductQty)
    this.$bus.$on('microcart-panel-state-changed', this.checkProductQty)
    this.$bus.$on('product-quantity-state-changed', this.setProductQty)
  },
  beforeDestroy () {
    this.$bus.$off('cart-after-delete-products', this.resetQuantity)
    this.$bus.$off('user-after-logout', this.resetQuantity)
    this.$bus.$off('shipping-details-changed', this.checkProductQty)
    this.$bus.$off('microcart-panel-state-changed', this.checkProductQty)
    this.$bus.$off('product-quantity-state-changed', this.setProductQty)
  }
};
</script>

<style lang="scss" scoped>
.alert {
  margin-bottom: var(--spacer-base)
}

.m-product-add-to-cart {
  &--sm {
    ::v-deep {
      .a-product-quantity {
        margin-top: 4px;
        max-width: 124px;
      }

      .sf-quantity-selector__button {
        width: 32px;
        height: 32px;
      }
    }
  }
}
</style>
