<template>
  <ASmoothReflow v-if="offersMainProducts.length" v-intersect.once="{ handler: onIntersect, cfg: { rootMargin: '0px' }}">
    <OSection
        :title-heading="$t('Special offers VARUS')"
        key="special-offers"
        is-centered
        is-not-mobile-padding
        class="home--special-offers-products o-section--slider"
    >
      <template #link>
        <SfLink
            :link="localizedRoute('/rasprodazha')"
            class="sf-link--primary all-products-link"
        >
          {{ $t('All goods') }}
        </SfLink>
      </template>
      <MProductCarousel
          v-if="offersMainProducts.length"
          list-id="home_special_offers"
          :list-name="$t('Special offers VARUS')"
          :products="offersMainProducts"
          :key="offersMainProducts.length"
          :es-url-param="esUrlParamProductOffersMainWidget"
          arrow-color="white"
          :show-qty-permanently="true"
          :lazy="true"
      />
    </OSection>
  </ASmoothReflow>
</template>

<script>
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow';
import intersect from 'theme/directives/intersect';
import MProductCarousel from 'theme/components/molecules/m-product-carousel.vue';
import { SfLink } from '@storefront-ui/vue';
import OSection from 'theme/components/organisms/o-section.vue';
import { mapActions, mapGetters } from 'vuex';
import { getAndStoreRelatedProducts, getEsputnikUrl } from 'theme/helpers';
import config from 'config';
export default {
  name: 'OHomeSpecialOffers',
  components: {
    OSection,
    SfLink,
    MProductCarousel,
    ASmoothReflow
  },
  data () {
    return {
      hasIntersected: false,
      esUrlParamProductOffersMainWidget: ''
    }
  },
  props: {
    offersMainProducts: {
      type: Array,
      default: () => []
    }
  },
  directives: { intersect },
  computed: {
    ...mapGetters({
      shippingDetails: 'shipping/getShippingDetails'
    }),
    productOffersMainWidgetId () {
      return config.esputnik?.widgetIds?.productOffersMain;
    }
  },
  methods: {
    ...mapActions({
      loadSampleProducts: 'homepage/loadSampleProducts'
    }),
    async onIntersect (entry) {
      if (!entry.isIntersecting || this.hasIntersected === true) return

      this.hasIntersected = true
      await this.loadSampleProducts('specialOffers')
      this.getProductOffersMain()
    },
    getProductOffersMain () {
      getAndStoreRelatedProducts(
        this.productOffersMainWidgetId,
        'productOffersMain',
        config?.entities?.product?.carouselSize,
        true
      )
      this.esUrlParamProductOffersMainWidget = getEsputnikUrl('#' + this.productOffersMainWidgetId + ' .recommend-product-id')
    }
  },
  watch: {
    'shippingDetails.shopId': {
      handler: async function (newValue, oldVal) {
        if (!newValue || newValue === oldVal) return

        await this.loadSampleProducts('specialOffers')
        this.getProductOffersMain()
      }
    }
  },
  mounted () {
    window.addEventListener('productOffersMainLoaded', this.getProductOffersMain)
  },
  beforeDestroy () {
    window.removeEventListener('productOffersMainLoaded', this.getProductOffersMain)
  }
};
</script>
