<template>
  <div class="sf-product-card__wrapper">
    <div
      :class="[
        'sf-product-card',
         {'is-row-type': type === 'row'},
         { 'sf-product-card--out-of-stock-container': isOutOfStock },
         { 'sf-product-card--np': isOutOfStockNP }
       ]
      ">
      <div class="sf-product-card__image-wrapper">
        <SfLink class="sf-product-card__link" :link="link">
          <slot name="image" v-bind="{ image, title }">
            <SfOImage
              class="sf-product-card__image"
              data-transaction-name="Card - Go To Product - Image"
              @click="linkClicked"
              :src="product.sku | productImagePath(Number(imageSize.width), Number(imageSize.height))"
              :alt="`${altName || title}`"
              :lazy="lazy"
              :hide-loader="true"
            />
          </slot>
        </SfLink>
        <AAddToWishlist class="sf-product-card--on-wishlist" :product="product" />
        <slot name="badge" v-bind="{ badgeLabel, badgeColor }">
          <div
            v-if="!isOutOfStock"
            class="sf-product-badges"
          >
            <div
              v-for="(item, index) in tagLabels"
              :key="'label_' + index"
              :class="'sf-product-card__badge-position-' + item.position"
              class="sf-product-card__badge"
              :style="getLabelStyle(item)"
            >
                <span
                  class="sf-product-card__badge_left"
                  :style="{ borderRightColor: item.color }"
                />
              <span
                class="sf-product-card__badge_right"
                :style="{ borderLeftColor: item.color }"
              />
              <span
                v-if="item.type === 'discount'"
                class="sf-product-card__badge_text"
              >
                  -{{ getDiscountPrice(product) }}%
                  <span v-if="showDiscountDate">
                    {{ $t('to') }} {{ getDiscountDay(product) }}.{{ getDiscountMonth(product) }}
                  </span>
                </span>
              <span
                v-if="item.type === 'promotion'"
                class="sf-product-card__badge_text"
              >
                  {{ getPromotionText(item.text) }}
                  <span v-if="getIsShowPromotionDate(item)">
                    {{ getPromotionDateText(item) }}
                  </span>
                </span>
              <span
                v-else
                class="sf-product-card__badge_text"
              >
                  {{ item.text }}
                </span>
            </div>
          </div>
        </slot>
        <div v-if="svgLabels.length && type !== 'row'">
          <SfLink
            v-for="(label, index) in svgLabels"
            class="sf-product-card__svg-label"
            :class="getSvgClass(index)"
            :link="link"
            :key="'label_' + index + '_' + product.id"
            data-transaction-name="Card - Go To Product - SVG"
          >
            <SfOImage
              :src="label"
              :lazy="lazy"
            />
          </SfLink>
        </div>
        <template v-if="showAddToCartButton">
          <slot
            name="add-to-cart"
            v-bind="{
                isAddedToCart,
                showAddedToCartBadge,
                isAddingToCart,
                title
              }"
          >
            <SfCircleIcon
              class="sf-product-card__add-button"
              :aria-label="`Add to Cart ${title}`"
              :has-badge="showAddedToCartBadge"
              :disabled="addToCartDisabled"
              data-transaction-name="Card - Add To Cart"
              @click="onAddToCart"
            >
              <div class="sf-product-card__add-button--icons">
                <transition
                  name="sf-product-card__add-button--icons"
                  mode="out-in"
                >
                  <slot v-if="!isAddingToCart" name="add-to-cart-icon">
                    <SfIcon
                      key="add_to_cart"
                      icon="add_to_cart"
                      size="20px"
                      color="white"
                    />
                  </slot>
                  <slot v-else name="adding-to-cart-icon">
                    <SfIcon
                      key="added_to_cart"
                      icon="added_to_cart"
                      size="20px"
                      color="white"
                    />
                  </slot>
                </transition>
              </div>
            </SfCircleIcon>
          </slot>
        </template>
      </div>
      <div class="sf-product-card__block">
        <SfLink
          data-transaction-name="Card - Go To Product - Link"
          class="sf-product-card__link sf-product-card__title-wrapper" :link="link"
        >
          <slot name="title" v-bind="{ title }">
            <h2
              class="sf-product-card__title"
              @click="linkClicked"
              style="-webkit-box-orient: vertical;"
              v-html="title"
            />
          </slot>
        </SfLink>
        <div class="price-block">
          <slot
            name="product-quantity"
            v-bind="{ productQuantity }"
            :class="{ 'product-quantity__out-of-stock': isOutOfStock }"
          >
            <p class="sf-product-card__quantity">
              {{ productQuantity }}
            </p>
          </slot>
          <slot name="price" v-bind="{ specialPrice, regularPrice }">
            <SfPrice
              v-if="regularPrice && !isOutOfStock"
              class="sf-product-card__price"
              :regular="currentPrice"
              :special="specialPrice"
            />
          </slot>
        </div>
        <div v-if="product['markdown_description']" class="markdown-reason">
          {{ product['markdown_description'] }}
        </div>
        <ABonusBadge :varus-perfect="product.varusPerfect" />
        <slot name="reviews" v-bind="{ maxRating, scoreRating }">
          <div
            v-if="typeof scoreRating === 'number'"
            class="sf-product-card__reviews"
          >
            <SfRating
              class="sf-product-card__rating"
              :max="maxRating"
              :score="scoreRating"
            />
            <SfButton
              v-if="reviewsCount"
              :aria-label="`Read ${reviewsCount} reviews about ${title}`"
              class="sf-button--pure sf-product-card__reviews-count"
              data-transaction-name="Card - Show Reviews"
              @click="$emit('click:reviews')"
            >
              ({{ reviewsCount }})
            </SfButton>
          </div>
        </slot>

        <footer class="sf-product-card__footer">
          <div
            v-if="!isOutOfStock"
            class="sf-product-card__hidden-basket"
            :class="{'sf-product-card__visible-qty-field': showQtyFieldFlag && showQtyPermanently}"
          >
            <NoSSR>
              <slot name="add-to-cart-block">
                <SfButton
                  v-if="isReplacement"
                  class="sf-button--primary replace__button"
                  data-transaction-name="Card - Replace"
                  @click.native="replaceProduct(product)"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                    <path fill="#fff" d="M21.769 4.847v4.5a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1 0-1.5h2.69l-1.903-1.903a7.519 7.519 0 0 0-10.612 0 .74.74 0 0 1-1.06 0 .75.75 0 0 1 0-1.06 9.019 9.019 0 0 1 12.732 0l1.903 1.903v-2.69a.75.75 0 1 1 1.5 0Zm-4.463 12.46a7.518 7.518 0 0 1-10.612 0L4.79 15.402h2.69a.75.75 0 1 0 0-1.5h-4.5a.75.75 0 0 0-.75.75v4.5a.75.75 0 1 0 1.5 0v-2.69l1.903 1.903a9.01 9.01 0 0 0 12.732 0 .75.75 0 0 0-1.06-1.06Z" />
                  </svg>
                </SfButton>
                <MProductAddToCart
                  v-else
                  :product="product"
                  :stock="product.stock"
                  is-icon-button
                  @input="changeQuantity(product, $event)"
                  @showQtyFieldAfterItemAdded="showQtyField"
                  @resetQtyFieldAfterItemRemoved="resetQtyField"
                />
              </slot>
            </NoSSR>
          </div>
          <div
            v-else
            class="sf-product-card__out-of-stock"
          >
            <div class="sf-product-card__out-of-stock--label">
              {{ $t(outOfStockLabel) }}
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import { focus } from '@storefront-ui/vue/src/utilities/directives';
import { formatCurrencyWithSpace } from 'theme/helpers'
import {
  SfIcon,
  SfPrice,
  SfRating,
  SfCircleIcon,
  SfLink,
  SfButton
} from '@storefront-ui/vue';
import SfOImage from 'theme/components/storefront-override/SfOImage';
import config from 'config';
import ABonusBadge from 'theme/components/atoms/a-bonus-badge';
import ProductLabels from 'theme/mixins/ProductLabels';
import DeviceType from 'theme/mixins/DeviceType';
import GoogleTagManager from 'theme/mixins/gtm';
import { mapActions } from 'vuex'
import AAddToWishlist from 'theme/components/atoms/a-add-to-wishlist';
import NoSSR from 'vue-no-ssr';

export default {
  name: 'SfOProductCard',
  mixins: [ ProductLabels, DeviceType, GoogleTagManager ],
  components: {
    AAddToWishlist,
    SfPrice,
    SfRating,
    SfIcon,
    SfOImage,
    SfLink,
    SfCircleIcon,
    SfButton,
    ABonusBadge,
    MProductAddToCart: () => process.browser ? import('theme/components/molecules/m-product-add-to-cart') : null,
    NoSSR
  },
  directives: { focus },
  inject: {
    isProductPage: {
      default: false
    }
  },
  props: {
    eventList: {
      type: Object,
      default: () => ({})
    },
    altName: {
      type: String,
      default: ''
    },
    /**
     * Product image
     * It should be an url of the product
     */
    image: {
      type: [Array, Object, String],
      default: ''
    },
    /**
     * Product image width, without unit
     */
    imageWidth: {
      type: [String, Number],
      default: 150
    },
    /**
     * Product image height, without unit
     */
    imageHeight: {
      type: [String, Number],
      default: 150
    },
    /**
     * Badge label
     */
    badgeLabel: {
      type: String,
      default: ''
    },
    /**
     * Badge color
     * It can be according to our standard colors, or legitimate CSS color such as `#fff`, `rgb(255,255,255)`), and `lightgray` or nothing.
     * Standard colors: `primary`, `secondary`, `white`, `black`, `accent`, `green-primary`, `green-secondary`, `gray-primary`, `gray-secondary`, `light-primary`, `light-secondary`, `pink-primary`, `pink-secondary`, `yellow-primary`, `yellow-secondary`, `blue-primary`, `blue-secondary`.
     */
    badgeColor: {
      type: String,
      default: ''
    },
    /**
     * Product title
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Link to product page
     */
    link: {
      type: [String, Object],
      default: ''
    },
    /**
     * Link element tag
     * By default it'll be 'router-link' if Vue Router
     * is available on instance, or 'a' otherwise.
     * @deprecated will be removed in 1.0.0 use SfLink instead
     */
    linkTag: {
      type: String,
      default: undefined
    },
    /**
     * Product rating
     */
    scoreRating: {
      type: [Number, Boolean],
      default: false
    },
    /**
     * Product reviews count
     */
    reviewsCount: {
      type: [Number, Boolean],
      default: false
    },
    /**
     * Maximum product rating
     */
    maxRating: {
      type: [Number, String],
      default: 5
    },
    /**
     * Product regular price
     */
    regularPrice: {
      type: [Number, String],
      default: null
    },
    /**
     * Product special price
     */
    specialPrice: {
      type: [Number, String],
      default: null
    },
    /**
     * Status of showing add to cart button
     */
    showAddToCartButton: {
      type: Boolean,
      default: false
    },
    isNewPost: {
      type: Boolean,
      default: false
    },
    /**
     * Status of showing add to cart block with inputs
     */
    showAddToCartBlock: {
      type: Boolean,
      default: false
    },
    /**
     * isAddedToCart status of whether button is showed, product is added or not
     */
    isAddedToCart: {
      type: Boolean,
      default: false
    },
    /**
     * addToCartDisabled status of whether button is disabled when out of stock
     */
    addToCartDisabled: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: () => ({})
    },
    productStock: {
      type: Object,
      default: () => ({})
    },
    /**
     * to indicate product weight, quantity
     */
    productQuantity: {
      type: [String, Array],
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    showQtyPermanently: {
      type: Boolean,
      default: false
    },
    isReplacement: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: true
    },
    itemListId: {
      type: String,
      default: () => ('')
    },
    itemListName: {
      type: String,
      default: () => ('')
    }
  },
  data () {
    return {
      isAddingToCart: false,
      showQtyFieldFlag: false
    };
  },
  computed: {
    currentPrice () {
      return (this.regularPrice && this.specialPrice)
        ? formatCurrencyWithSpace(this.regularPrice)
        : this.regularPrice
    },
    showAddedToCartBadge () {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    imageSize () {
      let width = config.products.rootcatplp.width;
      let height = config.products.rootcatplp.height;

      if (this.isTablet) {
        width = height = 340;
      } else if (this.isMobile) {
        width = height = 300;
      }
      return { width, height }
    },
    isOutOfStockState () {
      return !this.product.stock || !this.product.stock.is_in_stock || this.product.stock.qty <= 0
    },
    isOutOfStockNP () {
      if (this.isOutOfStockState) return false

      if (!this.isNewPost) return false

      return !this.product?.forNewPost
    },
    isOutOfStock () {
      if (this.isOutOfStockState) return true

      if (!this.isNewPost) return false

      return this.isOutOfStockNP
    },
    outOfStockLabel () {
      return this.isOutOfStockNP ? 'Out of stock NP' : 'The product is out of stock'
    }
  },
  methods: {
    ...mapActions({
      updateQuantity: 'cart/updateQuantity'
    }),
    linkClicked () {
      if (this.isProductPage) window.scrollTo(0, 0);

      this.$emit('linkClicked');

      const payload = {}

      if (this.itemListId && this.itemListName) {
        payload.item_list_id = this.itemListId
        payload.item_list_name = this.itemListName
      }

      this.gtmSelectItemHandler(
        this.product,
        'select_item',
        payload
      )
    },
    onAddToCart (event) {
      event.preventDefault();
      this.isAddingToCart = true;
      setTimeout(() => {
        this.isAddingToCart = false;
      }, 1000);
      this.$emit('click:add-to-cart');
    },
    async changeQuantity (product, newQuantity) {
      const diffLog = await this.updateQuantity({
        product: product,
        qty: newQuantity
      })

      if (diffLog.clientNotifications.length) {
        diffLog.clientNotifications.forEach(notificationData => {
          this.$store.dispatch(
            'notification/spawnNotification',
            notificationData,
            { root: true }
          )
        })
      }
    },
    showQtyField (flag) {
      this.showQtyFieldFlag = flag
    },
    resetQtyField () {
      this.showQtyFieldFlag = false
    },
    replaceProduct (product) {
      this.$emit('replaceProduct', product);
    },
    getSvgClass (index) {
      return 'svg-label__position-' + (index + 1)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";

.sf-product-card {
  $classRoot: &;
  position: relative;
  background: transparent;
  --product-card-padding: 0 var(--spacer-5) var(--spacer-20);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  box-sizing: border-box;

  &--out-of-stock-container {
    padding-bottom: var(--spacer-20);

    .sf-product-card__link {
      flex: 0 0 auto;
    }
  }

  &:hover {
    z-index: var(--product-card-z-index);
    @include for-mobile {
      --product-card-box-shadow: none;
      box-shadow: none;
    }
  }

  .glide__slide & {
    --product-card-padding: 0;
    padding: 0;
  }

  ::v-deep {
    .sf-product-card__title {
      font-size: var(--product-card-title-font-size);
    }

    .sf-add-to-cart__button,.replace__button {
      width: 3.625rem;
      height: 2.5rem;
      padding: 0;
    }
  }

  @media (max-width: $tablet-max) {
    --product-card-box-shadow: none;
  }

  @include for-desktop {
    &:after {
      content: none;
    }

    &:before {
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      user-select: none;
      pointer-events: none;
    }

    &:hover {
      &:before {
        left: calc(-1 * var(--spacer-15));
        right: calc(-1 * var(--spacer-15));
        top: calc(-1 * var(--spacer-20));
        bottom: calc(-1 * var(--spacer-20));
        background: var(--white);
        box-shadow: var(--cart-box-shadow);
        z-index: var(--content-index);
      }

      #{$classRoot}__hidden-basket {
        visibility: visible;
        opacity: 1;
        transition: all .3s;
        transform: translate3d(0, 0, 0);
      }

      & > div,
      footer,
      a {
        position: relative;
        z-index: 2;
      }

      .sf-product-card--on-wishlist {
        opacity: 1;
      }
    }

    .sf-carousel & {
      &:hover {
        &:before {
          left: calc(-1 * var(--spacer-20));
          right: calc(-1 * var(--spacer-20));
          top: calc(-1 * var(--spacer-20));
          bottom: calc(-1 * var(--spacer-20));
        }
      }
    }
  }

  &__quantity {
    font-size: var(--font-size-12);
    color: var(--neutral-gray);
    margin: 5px 0 0;
    position: relative;
  }

  &__hidden-basket {
    @include for-desktop {
      visibility: hidden;
      opacity: 0;
      transition: all .3s;
      transform: translate3d(0, 20%, 0);
    }

    .a-product-quantity {
      display: none;
    }
  }

  &__visible-qty-field {
    @include for-desktop {
      visibility: visible;
      opacity: 1;
      transition: all .3s;
      transform: translate3d(0, 20%, 0);
    }

    .a-product-quantity {
      display: block;
    }
  }

  &--on-wishlist {
    position: absolute;
    top: 9px;
    right: 7px;
    z-index: var(--wishlist-icon-index);
    opacity: 1;

    @include for-desktop {
      transition: all .3s;
      opacity: 0;
    }
  }

  &__title {
    --product-card-title-margin: var(--spacer-xs) 0;
    --product-card-title-font-size: var(--font-size-13);
    --product-card-title-font-line-height: 1.125rem;
    color: var(--black);
    display: block;
    position: relative;
    margin: var(--spacer-xs) 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    -webkit-line-clamp: 2;
    display: -webkit-box;

    @media (min-width: $mobile-max) {
      --product-card-title-font-size: var(--font-size-15);
    }
  }

  &__title-wrapper {
    margin: 0 0 var(--spacer-5);
  }

  &__price {
    color: var(--black);
    position: relative;
  }

  &__image {
    position: relative;
    width: 100%;
    padding-bottom: 100%;

    //&:not([data-loaded]) {
    //  &::before {
    //    content: ' ';
    //    position: absolute;
    //    z-index: 1;
    //    top: 50%;
    //    left: 50%;
    //    border: .2rem solid var(--orange, #EB6747);
    //    border-radius: 50%;
    //    border-left-color: transparent;
    //    border-right-color: transparent;
    //    animation: preloader 575ms infinite linear;
    //    margin-top: -1.5rem;
    //    margin-left: -1.5rem;
    //    height: 3rem;
    //    width: 3rem;
    //    will-change: transform;
    //  }
    //
    //  &::after {
    //    content: ' ';
    //    position: absolute;
    //    left: 0;
    //    top: 0;
    //    width: 100%;
    //    height: 100%;
    //    background-color: var(--white, #fff);
    //  }
    //}
    //
    //@keyframes preloader {
    //  100% {
    //    transform: rotate(360deg);
    //  }
    //}

    ::v-deep img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-wrapper {
      background-color: var(--white);
    }
  }

  &__link {
    display: block;
    width: 100%;
    z-index: 0;
    --product-card-title-font-size: var(--font-size-13);
    font-size: var(--font-size-13);
    flex: 1 1 100%;
    @include for-desktop {
      --product-card-title-font-size: var(--font-size-15);
      font-size: var(--font-size-15);
    }
  }

  &__badge {
    color: var(--black);
    font-size: var(--font-size-12);
    line-height: var(--spacer-20);
    font-weight: var(--font-medium);
    max-width: calc(100% - var(--spacer-20));
    height: var(--spacer-20);
    padding: 0 var(--spacer-8);
    position: absolute;
    left: var(--spaser-10);
    z-index: var(--badge-index);

    &_text {
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      max-width: 100%;
    }

    &_left {
      border-right-width: var(--spacer-4);
      border-right-style: solid;
      position: absolute;
      left: - px2rem(3.5);
      display: block;
      border-top: var(--spacer-10) solid transparent;
      border-bottom: var(--spacer-10) solid transparent;
    }

    &_right {
      border-left-width: var(--spacer-4);
      border-left-style: solid;
      position: absolute;
      right: - px2rem(3.5);
      border-top: var(--spacer-10) solid transparent;
      border-bottom: var(--spacer-10) solid transparent;
    }

    &-position-1 {
      top: var(--spacer-5);
    }

    &-position-2 {
      top: var(--spacer-30);
    }

    &-position-3 {
      top: var(--spacer-55);
    }
  }

  &__svg-label {
    position: absolute!important;
    bottom: var(--spacer-5);
    z-index: 2;
    width: var(--spacer-50);
    height: var(--spacer-50);

    ::v-deep {
      .sf-image {
        display: block;
      }
    }
  }

  .svg-label__position-1 {
    right: var(--spacer-5);
  }

  .svg-label__position-2 {
    right: var(--spacer-60);
  }

  &__image-wrapper {
    position: relative;
    padding: var(--spacer-10);
    ::v-deep .sf-link {
      line-height: 0;
    }
  }

  &--out-of-stock-container {
    &.sf-product-card:not(.sf-product-card--np) {
      ::v-deep img {
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }
    }

    .sf-product-card__quantity {
      display: none;
    }

    .sf-product-card__out-of-stock {
      background-color: var(--light-gray);
      border-radius: var(--spacer-5);
      padding: var(--spacer-4) var(--spacer-8);
      position: relative;
      display: inline-block;
      max-width: 10rem;
      margin-top: var(--spacer-5);

      &--label {
        text-align: center;
        font-weight: var(--font-normal);
        font-size: var(--font-size-15);
        line-height: var(--font-size-18);
        color: var(--dark-gray);
      }
    }
  }

  ::v-deep {
    .sf-product-card__price {
      margin: 5px 0 10px;
    }

    .sf-price__regular {
      --price-regular-font-size: var(--font-size-18);
      line-height: var(--spacer-28);
      color: var(--black);
      .buy-with-product & {
        --price-regular-font-size: var(--font-size-16);
        color: #333333;
        @include for-desktop {
          --price-regular-font-size: var(--font-size-18);
        }
      }
    }
    .sf-image--has-size {
      img:not(.noscript) {
        margin: auto;
        right: 0;
      }
    }

    .sf-quantity-selector__button {
      width: var(--spacer-40);
      height: var(--spacer-40);
    }

    .a-product-quantity {
      max-width: 8.375rem;
    }
  }
}

.is-row-type {
  display: flex;
  align-items: center;
  min-width: 100%;
  flex-direction: row;
  gap: var(--spacer-10);

  .sf-product-card {
    &__image {
      width: 117px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__block {
      flex: 1;
    }
  }
}

@media (max-width: 350px) {
  .is-row-type {
    .sf-product-card {
      &__image {
        width: 80px;
      }
    }
  }
}

@media (min-width: 650px) {
  .is-row-type {
    ::v-deep {
      .sf-product-badges {
        //display: none;
      }

      .sf-product-card__badge_text {
        font-size: var(--font-size-8);
      }

      .sf-product-card__badge {
        height: var(--spacer-16);
        line-height: var(--spacer-16);
        padding: 0;
      }

      .sf-product-card__badge_left,
      .sf-product-card__badge_right {
        //border-width: var(--spacer-2);
        border-top-width: var(--spacer-8);
        border-bottom-width: var(--spacer-8);
      }

      .price-block {
        white-space: nowrap;
        flex: 0 0 155px;
      }
    }

    ::v-deep {
      .sf-add-to-cart__button {
        margin-top: 0;
      }
    }

    .sf-product-card__block {
      display: flex;
      gap: var(--spacer-10);
      align-items: center;
    }

    .sf-product-card__hidden-basket {
      box-sizing: border-box;
    }

    .sf-product-card {
      &__hidden-basket {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
        position: static;
      }

      &__image {
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__block {
        flex: 1;
      }

      &__title {
        min-height: auto;
        -webkit-line-clamp: 1;
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        flex: 0 0 134px;
      }
    }
  }
}

.price-block {

  ::v-deep .sf-price {
    &__old {
      color: var(--dark-gray);
      font-weight: var(--font-normal);
      white-space: nowrap;
      margin-right: 0;
      font-size: 15px;

      @include for-tablet {
        margin-right: var(--spacer-xs);
      }

      @include for-desktop {
        margin-right: var(--spacer-xs);
        font-size: var(--font-sm);
      }
    }

    &__special {
      color: var(--orange);
      white-space: nowrap;
      font-size: 16px;
      margin-left: 10px;
      @include for-desktop {
        font-size: 18px;
        margin-left: 0;
      }
    }
  }
}

.sf-product-card {
  &:hover {
    --product-card-z-index: 3;
  }
}

.markdown-reason {
  color: var(--red-main);
  font-family: var(--font-family-primary),serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

</style>
