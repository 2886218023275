<template>
  <SfButton
    class="a-add-to-cart sf-button--primary"
    data-transaction-name="Add To Cart"
    :disabled="isProductDisabled"
    :class="className"
    @click.native="checkAddToCart"
  >
    <template v-if="isLoading">
      <SfLoader
        :loading="true"
        :size="24"
        :weight="3"
        background="transparent"
        color="#fff"
      />
    </template>
    <template v-else>
      <span v-if="!isIconButton">
        {{ $t('Add to cart') }}
      </span>
      <span v-else class="icon-cart" />
    </template>
  </SfButton>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import { formatProductMessages } from '@vue-storefront/core/filters/product-messages';
import { mapGetters, mapState } from 'vuex';
import SfLoader from 'src/themes/varus/components/storefront-override/SfLoader';
import GoogleTagManager from 'theme/mixins/gtm';
import Delivery from 'theme/mixins/Delivery';
import { eSputnikEvent } from 'theme/helpers/es';

export default {
  name: 'AAddToCart',
  components: {
    SfButton,
    SfLoader
  },
  mixins: [
    GoogleTagManager,
    Delivery
  ],
  props: {
    product: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    qty: {
      type: Number,
      default: 1
    },
    /** to make add to card button as icon */
    isIconButton: {
      type: Boolean,
      default: false
    },
    itemListId: {
      type: String,
      default: '123'
    },
    itemListName: {
      type: String,
      default: '123'
    }
  },
  computed: {
    ...mapState({
      productsInCart: state => state.cart.cartItems
    }),
    ...mapGetters({
      isFilledShippingInfo: 'shipping/isFilledShippingInfo',
      getShippingDetails: 'shipping/getShippingDetails',
      getCartToken: 'cart/getCartToken',
      getCurrentCartHash: 'cart/getCurrentCartHash'
    }),
    isProductDisabled () {
      return (
        this.disabled ||
        formatProductMessages(this.product.errors) !== ''
      );
    },
    className () {
      return {
        'a-add-to-cart-icon': this.isIconButton,
        'sf-button--full-width': !this.isIconButton
      }
    }
  },
  inject: {
    listInfo: {
      default: () => ({
        listId: '',
        listName: ''
      })
    }
  },
  methods: {
    async addToCart () {
      const productToAdd = {
        ...this.product,
        qty: this.qty,
        extension_attributes: {
          ...this.product.extension_attributes,
          list_id: this.listInfo.listId,
          list_name: this.listInfo.listName
        }
      }

      const adultConfirmation = await this.$store.dispatch('ui/adultConfirmation', { product: productToAdd })

      if (adultConfirmation) return

      this.$emit('addedToCart')
      try {
        this.$emit('addItemToCart', true)

        await this.$store.dispatch('cart/addProductToCart', productToAdd, { root: true })
      } catch (e) {
      } finally {
        this.$emit('addItemToCart', false)
        this.esputnikCartEvent()
      }
    },
    async checkAddToCart () {
      const productForGtm = {
        ...this.product,
        extension_attributes: {
          ...this.product.extension_attributes,
          list_id: this.listInfo.listId,
          list_name: this.listInfo.listName
        }
      }
      this.gtmProductsHandler(productForGtm, 'add_to_cart', {}, {
        value: true,
        calculate: true
      })
      this.gtmProductsHandlerAds(this.product, 'add_to_cart_ads', true)

      const productToAdd = { ...this.product, qty: this.qty };

      if (this.isFilledShippingInfo) {
        this.addToCart()
        return
      }

      this.openShippingModal({
        product: productToAdd
      })
    },
    async esputnikCartEvent () {
      eSputnikEvent('StatusCart', { productsInCart: this.productsInCart, currentCartHash: this.getCurrentCartHash })
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.icon-cart {
  @include font-icon(var(--icon-cart));

  &:before {
    font-size: var(--font-size-24);
  }
}

.a-add-to-cart-icon {
  padding: var(--spacer-xs) var(--spacer-sm);
  width: auto;
}

.a-add-to-cart {
  --button-width: 11.6875rem;
  --button-height: 3.5rem;

  @media (min-width: $tablet-min) {
    --button-width: 12.1875rem;
  }
}
</style>
